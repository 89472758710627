<script setup lang="ts">
import { usePackageStore } from '@/stores/package';
const store = usePackageStore();
</script>
<template>
    <div>
        <div class="text-xl underline underline-offset-2 mb-4">Packages Summary, please check the information before submiting</div>
        <ul class="space-y-4 list-disc list-inside text-gray-500 dark:text-gray-400">
            <li>
                <b>General Info</b>
                <ol class="pl-5 mt-2 space-y-1 list-decimal list-inside">
                    <li>
                        <svg v-show="store.package.generalInfo.hotelNameInPackageRuleName" class="flex-shrink-0 w-6 h-6 text-green-500 dark:text-green-400 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={} d="M5 13l4 4L19 7" /></svg>
                        <svg v-show="!store.package.generalInfo.hotelNameInPackageRuleName" class="flex-shrink-0 w-6 h-6 text-red-500 dark:text-red-400 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
                        Hotel Name In Package Name 
                    </li>
                    <li>
                        <svg v-show="store.package.generalInfo.nightsInPackageRuleName" class="flex-shrink-0 w-6 h-6 text-green-500 dark:text-green-400 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={} d="M5 13l4 4L19 7" /></svg>
                        <svg v-show="!store.package.generalInfo.nightsInPackageRuleName" class="flex-shrink-0 w-6 h-6 text-red-500 dark:text-red-400 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
                        Number of Nights in Package Name
                    </li>
                    <li>
                        <svg v-show="store.package.generalInfo.transportTypeInPackageRuleName" class="flex-shrink-0 w-6 h-6 text-green-500 dark:text-green-400 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={} d="M5 13l4 4L19 7" /></svg>
                        <svg v-show="!store.package.generalInfo.transportTypeInPackageRuleName" class="flex-shrink-0 w-6 h-6 text-red-500 dark:text-red-400 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
                        Transport Type in Package Name
                    </li>
                    <li>
                        <svg v-show="store.package.generalInfo.hotelPromotionInPackageRuleName" class="flex-shrink-0 w-6 h-6 text-green-500 dark:text-green-400 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={} d="M5 13l4 4L19 7" /></svg>
                        <svg v-show="!store.package.generalInfo.hotelPromotionInPackageRuleName" class="flex-shrink-0 w-6 h-6 text-red-500 dark:text-red-400 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
                        Hotel Promotion In Package Name
                    </li>
                    <li class="capitalize">
                        <svg v-show="store.package.generalInfo.packageStatus === 'active'" class="flex-shrink-0 w-6 h-6 text-green-500 dark:text-green-400 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={} d="M5 13l4 4L19 7" /></svg>
                        <svg v-show="store.package.generalInfo.packageStatus === 'disabled'" class="flex-shrink-0 w-6 h-6 text-red-500 dark:text-red-400 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
                        Package Status : {{store.package.generalInfo.packageStatus}}
                    </li>
                    <li>
                        <svg v-show="store.package.generalInfo.stopBuy" class="flex-shrink-0 w-6 h-6 text-green-500 dark:text-green-400 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={} d="M5 13l4 4L19 7" /></svg>
                        <svg v-show="!store.package.generalInfo.stopBuy" class="flex-shrink-0 w-6 h-6 text-red-500 dark:text-red-400 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
                        Testing Mode
                    </li>
                    <li>
                        Package Interval: From {{store.package.generalInfo.period.join(' to ')}}
                    </li>
                    <li>
                        Package Outbound Offset: {{store.package.generalInfo.minOffset}}
                    </li>
                    <li>
                        Package Inbound Offset: {{store.package.generalInfo.maxOffset}}
                    </li>
                    <li>
                        Minimum Packages Duration: {{store.package.generalInfo.minRotation}}
                    </li>
                    <li>
                        Maximum Packages Duration: {{store.package.generalInfo.maxRotation}}
                    </li>
                </ol>
            </li>
            <li>
                <b>Selected Transport</b>
                <ol class="pl-5 mt-2 space-y-1 list-decimal list-inside">
                    <li>
                        Transport Name: {{store.package.transport.transportName}}
                    </li>
                    <li class="capitalize">
                        Transport Type: {{store.package.transport.transportType}}
                    </li>
                    <li class="capitalize">
                       Transport Dates: <br>
                       <div class="pl-8" v-for="(dates, index) in store.package.transport.dates" :key="index">
                            Departure: {{dates.departure}} paired with Returns: {{dates.returns.join(', ')}}
                       </div>
                    </li>
                </ol>
            </li>
            <li>
                <b>Hotels</b>
                <ol class="pl-5 mt-2 space-y-1 list-decimal list-inside">
                    <li>
                        Selected Providers: {{store.package.hotels.providers.length}}    
                    </li>
                    <li>
                        Selected Hotels: {{store.package.hotels.selectedHotels.length}}    
                    </li>
                    <li>
                        Recomended Hotels: {{store.package.hotels.recommendedHotels.length}}    
                    </li>
                    <li>
                        Hotels Located in: 
                        <div class="pl-8" v-for="(value, key, index) in store.package.hotels.hotelsWithLocations" :key="index">
                            {{value.locationName}}
                        </div> 
                    </li>
                </ol>
            </li>
            <li>
                <b>Services</b>
                <ol class="pl-5 mt-2 space-y-1 list-decimal list-inside">
                    <li>
                        <svg v-show="store.package.services.transfer.withTransfer" class="flex-shrink-0 w-6 h-6 text-green-500 dark:text-green-400 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={} d="M5 13l4 4L19 7" /></svg>
                        <svg v-show="!store.package.services.transfer.withTransfer" class="flex-shrink-0 w-6 h-6 text-red-500 dark:text-red-400 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
                        With Transfer
                    </li>
                    <li v-show="store.package.services.transfer.withTransfer">
                        Transfer Name: {{store.package.services.transfer.transferDetails.transferName}}    
                    </li>
                    <li>
                        <svg v-show="store.package.services.extra.withExtra" class="flex-shrink-0 w-6 h-6 text-green-500 dark:text-green-400 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={} d="M5 13l4 4L19 7" /></svg>
                        <svg v-show="!store.package.services.extra.withExtra" class="flex-shrink-0 w-6 h-6 text-red-500 dark:text-red-400 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
                        With Extras
                    </li>
                    <li v-show="store.package.services.extra.withExtra">
                        Extras Nr.: {{store.package.services.extra.extraServices.length}}    
                    </li>
                </ol>
            </li>
            <li>
                <b>Commissions</b>
                <ol class="pl-5 mt-2 space-y-1 list-decimal list-inside">
                    <li>
                        Hotel Commissions: 
                        <div class="pl-8" v-for="(value, key, index) in store.package.commissions.hotelCalculationLogic" :key="index">
                            Departure {{ value.departureDate }}:  {{ value.rule[0] }}
                        </div> 
                    </li>
                    <li>
                        Transport Commissions: 
                        <div v-if="store.package.commissions.transportationCalculationLogic.length != 0">
                            <div class="pl-8" v-for="(value, key, index) in store.package.commissions.transportationCalculationLogic" :key="index">
                                Departure {{ value.departureDate }}:  {{ value.rule[0] }}
                            </div> 
                        </div>
                        <div class="inline" v-else>
                            No Rules declared
                        </div>
                    </li>
                </ol>
            </li>
            <li>
                <b>Cancellation Rules</b>
                <ol class="pl-5 mt-2 space-y-1 list-decimal list-inside">
                    <li class="capitalize">
                        Cancellation Type: {{store.package.cxlRules.cxlType}}
                    </li>
                </ol>
            </li>
        </ul>
    </div>
</template>
