<script setup lang="ts">
import { useUserStore } from '@/stores/user';
import DarkModeToggle from '@/components/wireframe/DarkModeToggle.vue';
import { onMounted } from 'vue';
const userStore = useUserStore();

onMounted(() => {
    // set the dropdown menu element
    const targetEl = document.getElementById('dropdownAvatarName');

    // set the element that trigger the dropdown menu on click
    const triggerEl = document.getElementById('dropdownAvatarNameButton');
    // @ts-ignore: Unreachable code error
    const dropdown = new Dropdown(targetEl, triggerEl);
});
</script>
<template>
    <div
        class="mainHeader flex justify-between border-b-2 py-4 px-12 dark:bg-gray-700 dark:text-gray-400"
    >
        <div class="flex items-end text-4xl dark:text-white">
            {{ $route.name }}
        </div>
        <div class="flex">
            <DarkModeToggle />
            <!-- Dropdown menu -->
            <button
                id="dropdownAvatarNameButton"
                data-dropdown-toggle="dropdownAvatarName"
                class="flex items-center text-sm font-medium text-gray-900 rounded-full hover:text-blue-600 dark:hover:text-blue-500 md:mr-0 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-white"
                type="button"
            >
                <span class="sr-only">Open user menu</span>
                <div class="relative">
                    <img
                        id="avatar"
                        type="button"
                        data-dropdown-placement="bottom-start"
                        class="w-12 h-12 rounded-full cursor-pointer"
                        :src="`https://robohash.org/${userStore.userName}`"
                        alt="User dropdown"
                    />
                    <span
                        class="absolute top-3 left-8 transform -translate-y-1/2 w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"
                    ></span>
                </div>
                <div>
                    {{ userStore.userName }}
                </div>
                <svg
                    class="w-4 h-4 mx-1.5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                    ></path>
                </svg>
            </button>
        </div>

        <!-- Dropdown menu -->
        <div
            id="dropdownAvatarName"
            class="hidden z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600"
        >
            <div class="py-3 px-4 text-sm text-gray-900 dark:text-white">
                <div class="font-medium">Admin User</div>
                <div class="truncate">{{ userStore.userEmail }}</div>
            </div>
            <ul
                class="py-1 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownInformdropdownAvatarNameButtonationButton"
            >
                <li>
                    <a
                        href="/"
                        class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >Dashboard</a
                    >
                </li>
                <li>
                    <a
                        href="#"
                        class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >Settings</a
                    >
                </li>
            </ul>
            <div class="py-1">
                <a
                    href="/logout"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                    >Sign out</a
                >
            </div>
        </div>
    </div>
</template>
