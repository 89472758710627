<script setup lang="ts">
import { onMounted, reactive, ref, toRaw, type Ref } from 'vue';
import Spinner from '@/components/animations/TableSpinner.vue';
import axiosApiInstance from '@/helpers/api.axios.headers';
import FullscreenLoader from '@/components/animations/FullscreenLoader.vue';


const items: Ref<Array<any>> = ref([]);
const loader: Ref<boolean> = ref(true);
const filteredItems: Ref<Array<any>> = ref([]);
const fullscreenLoader = ref(false);

const initialFiltersState: any = {
    name: '',
    status: '',
};

const filters = reactive({
    ...initialFiltersState,
});

onMounted(() => {
    getAllExtra();
});

const getAllExtra = () => {
    axiosApiInstance
        .get('/extra_services/get', {

        })
        .then(function (response) {
            loader.value = false;
            if (!response.data.status) {
                alert(response.data.message);
            } else {
                filteredItems.value = items.value = response.data.extra_services;
            }
        })
        .catch(function (error) {
            alert(error);
        });
}

const filterResults = (reset: boolean) => {

    fullscreenLoader.value = true;

    if (reset) {
        filters.name = '';
        filters.status = '';
    }

    axiosApiInstance
        .get(`/extra_services/get?name=${filters.name}&status=${filters.status}`, {

        })
        .then(function (response) {
            loader.value = false;
            if (!response.data.status) {
                alert(response.data.message);
            } else {
                filteredItems.value = items.value = response.data.extra_services;
            }
            fullscreenLoader.value = false;
        })
        .catch(function (error) {
            alert(error);
            fullscreenLoader.value = false;
        });


};

const deleteExtra = (id: number) => {

    fullscreenLoader.value = true;

    axiosApiInstance
        .get(`/extra_services/delete/${id}`, {

        })
        .then(function (response) {

            alert(response.data.message);
            fullscreenLoader.value = false;
            getAllExtra();
        })
        .catch(function (error) {
            alert(error);
            fullscreenLoader.value = false;
        });

}

</script>
<template>
    <FullscreenLoader v-show="fullscreenLoader" />
    <div>
        <div class="border-2 border-gray-400 rounded p-5">
            <div class="flex justify-between">
                <div class="text-xl">{{ items.length }} Results</div>
            </div>
            <hr class="border-gray-400 my-2" />
            <div class="flex justify-between">
                <div class="flex">
                    <div class="col-span-6 sm:col-span-3 mr-2">
                        <label for="name" class="block text-gray-700">Name</label>
                        <input type="text" name="name" id="name" autocomplete="off"
                            v-model="filters.name" placeholder="Search by name.." class="form-control mt-1" />
                    </div>
                    <div class="col-span-6 sm:col-span-3 mr-2">
                        <label for="status" class="block text-gray-700">Status</label>
                        <select class="form-select w-64 mt-1" name="status" id=""  v-model="filters.status">
                            <option value="">All</option>
                            <option value="onRequest">On Request</option>
                            <option value="available">Available</option>
                        </select>
                    </div>
                </div>
                <div class="flex items-end">
                    <router-link
                            class="btn-primary mr-2"
                            style="color: white !important;"
                            to="/extra"
                        >
                        Add
                    </router-link>
                    <button class="btn-success mr-2" @click="filterResults(false)">
                        Filter
                    </button>
                    <button class="btn-warning mr-2" @click="filterResults(true)">Reset</button>
                </div>
            </div>
        </div>
    </div>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg pt-0">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 mb-0">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3">Nr.</th>
                    <th scope="col" class="px-6 py-3">Name</th>
                    <th scope="col" class="px-6 py-3">Status</th>
                    <th scope="col" class="px-6 py-3">Created by</th>
                    <th scope="col" class="px-6 py-3">Edited by</th>
                    <th scope="col" class="px-6 py-3">Created at</th>
                    <th scope="col" class="px-6 py-3">Edited at</th>
                    <th scope="col" class="px-6 py-3">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-show="loader">
                        <td
                            colspan="9"
                            class="bg-white border-b dark:bg-gray-900 dark:border-gray-700 p-2"
                        >
                            <div class="flex items-center justify-center">
                                <Spinner />
                                <div class="text-lg pl-2">Loading...</div>
                            </div>
                        </td>
                    </tr>
                <tr v-if="filteredItems.length === 0 && !loader">
                    <td colspan="99" class="text-center">
                        <div class="font-bold dark:text-white text-xl uppercase my-2">
                            No Extras found.
                        </div>
                    </td>
                </tr>
                <tr v-for="(item, index) in filteredItems" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">

                    <td class="px-6 py-4">
                        {{ index + 1 }}
                    </td>
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        {{ item.name }}
                    </th>
                    <td class="px-6 py-4">
                        {{ item.status }}
                    </td>
                    <td class="px-6 py-4">
                        {{ item.created_by }}
                    </td>
                    <td class="px-6 py-4">
                        {{ item.edited_by }}
                    </td>
                    <td class="px-6 py-4">
                        {{ item.created_at }}
                    </td>
                    <td class="px-6 py-4">
                        {{ item.updated_at }}
                    </td>
                    <td class="px-6 py-4">
                        <router-link
                            class="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
                            :to="{
                                name: 'Extra Add',
                                params: {
                                    id: item.id
                                },
                            }"
                        >
                            Edit
                        </router-link>
                        |
                        <a type="button" href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline" @click="deleteExtra(item.id)">Delete</a>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>
</template>