import axiosApiInstance from '@/helpers/api.axios.headers';

export async function getHotels(
    locationId: number,
    providers: number[],
    routeId: string,
    service: string
) {
    try {
        if (locationId == null || locationId == -1) {
            alert('Please select a location');
            return false;
        }
        if (providers.length === 0) {
            alert('Please select atleast one provider');
            return false;
        }

        const res = await axiosApiInstance.post('/getHotelsBasedOnLocation', {
            locationId,
            providers,
            routeId,
            service,
        });
        if (!res.data.status) {
            alert(res.data.message);
            return false;
        }

        if (res.data.hotels.length === 0) {
            alert('No hotels !');
            return false;
        }

        return res.data;
    } catch (err) {
        console.error(err);
    }
}
