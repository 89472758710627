<script setup lang="ts">
import { useUCMHotelStore } from '@/stores/ucmHotel';
import { onMounted, ref, type Ref } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
const store = useUCMHotelStore();

let modal: any;
const type: Ref<string> = ref('');

onMounted(() => {
    const $targetEl = document.getElementById('modalParameters');
    const options = {
        backdrop: 'dynamic',
        backdropClasses:
            'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
        closable: true,
       
    };
    // @ts-ignore: Unreachable code error
    modal = new Modal($targetEl, options);
})

const chooseParameter = () => {
    modal.show();
}
const closeModal = () => {
    modal.hide();
}

const newParameter = () => {

    if(type.value === '') return false;

    switch (type.value) {
        case 'distances':
            store.hotel.parameters.distances.push({
                name: '',
                distance: '',
                units: 'm',
            })
            break;
        case 'attributes':
            store.hotel.parameters.attributes.push({
                name: '',
                status: 'always-active',
                dateStart: '',
                dateEnd: ''
            })
            break;
    
        default:
            break;
    }

    

    modal.hide();
}

</script>
<template>
    <div>
        <p>
            In this menu, users have the option to choose a specific parameter type that aligns with their preferences or requirements. The menu offers two main choices: 
            <br>
            <br>
            &#8722;"Distance from hotel," allowing users to specify a preferred distance range to a Point of Interest (POI). 
            <br>
            &#8722;"Attributes" Facilitate the choice of preferred features, services, characteristics, or amenities in a hotel, whether they are available for a specific time or remain constant.;
            <br>
            <br>
            By selecting a parameter type, users can tailor their search or interaction within the application to better suit their needs, whether it's finding hotels based on proximity, room features, or specific property attributes.
        </p>
        <br>
        <br>
        <button class="btn-primary btn-sm" type="button" @click="chooseParameter()">
            Add new Parameter
        </button>
    </div>
    <div>
        <div class="mt-4">
            Distances from hotel
        </div>
        <hr class="my-2">
        <div class="grid gap-6 mb-6 md:grid-cols-4" v-for="(distance, index) in store.hotel.parameters.distances" :key="index">
            <div>
                <label for="distanceName" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name </label>
                <input type="text" id="distanceName" v-model="distance.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="distance to...">
            </div>
            <div>
                <label for="distance" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Distance </label>
                <input type="number" id="distance" v-model="distance.distance" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="500">
            </div>
            <div>
                <label for="units" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Units </label>
                <select id="units" v-model="distance.units"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option selected value="m">meters</option>
                    <option value="km">kilometers</option>
                </select>
            </div>
            <div>
                <br>
                <button
                    class="btn-danger"
                    type="button"
                    @click="
                            store.hotel.parameters.distances.splice(
                                index,
                                1
                            )
                    "
                >
                    Delete
                </button>
            </div>
        </div>
    </div>
    <div>
        <div class="mt-4">
            Hotel attributes
        </div>
        <hr class="my-2">
        <div class="grid gap-6 mb-6 md:grid-cols-5" v-for="(attribute, index) in store.hotel.parameters.attributes" :key="index">
            <div>
                <label for="attributeName" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Amenity, benefits or characteristic </label>
                <input type="text" id="attributeName" v-model="attribute.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="
                ">
            </div>
            <div>
                <label for="status" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Status </label>
                <select id="status" v-model="attribute.status"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option selected value="always-active">Always active</option>
                    <option value="timed">Timed</option>
                </select>
            </div>
            <div>
                <label for="dateStart" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Date start </label>
                <Datepicker
                    v-model="attribute.dateStart"
                    :enableTimePicker="false"
                    id="dateStart"
                    />
            </div>
            <div>
                <label for="dateEnd" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Date end </label>
                <Datepicker
                    v-model="attribute.dateEnd"
                    :enableTimePicker="false"
                    id="dateEnd"
                    />
            </div>
            <div>
                <br>
                <button
                    class="btn-danger"
                    type="button"
                    @click="
                            store.hotel.parameters.attributes.splice(
                                index,
                                1
                            )
                    "
                >
                    Delete
                </button>
            </div>
        </div>
    </div>
    <div id="modalParameters" tabindex="-1" aria-hidden="true"
        class="fixed left-0 right-0 top-0 z-50 hidden h-[calc(100%-1rem)] max-h-full w-full overflow-y-auto overflow-x-hidden p-4 md:inset-0">
        <div class="relative max-h-full w-full max-w-2xl">
            <!-- Modal content -->
            <div class="relative rounded-lg bg-white shadow dark:bg-gray-700">
                <!-- Modal header -->
                <div class="flex items-start justify-between rounded-t border-b p-5 dark:border-gray-600">
                    <h3 class="text-xl font-semibold text-gray-900 dark:text-white lg:text-2xl">
                        Description language
                    </h3>
                    <button type="button" @click="closeModal()"
                        class="ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white">
                        <svg class="h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <!-- Modal body -->
                <div class="space-y-6 p-6">
                    <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        Choose parameter type
                    </p>
                    
                    <div>
                        <div class="flex mb-3">
                            <div class="flex items-center h-5">
                                <input id="distance-radio" v-model="type" aria-describedby="distance-radio-text" type="radio" value="distances" name="type" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            </div>
                            <div class="mx-2 text-sm">
                                <label for="distance-radio" class="font-medium text-gray-900 dark:text-gray-300">Distance from Hotel</label>
                                <p id="helper-radio-text" class="text-xs font-normal text-gray-500 dark:text-gray-300">Set POI's near Hotel. Ex: Distance to beach, Distance to airport etc</p>
                            </div>
                        </div>
                        <div class="flex mb-3">
                            <div class="flex items-center h-5">
                                <input id="properties-radio" v-model="type" aria-describedby="properties-radio-text" type="radio" value="attributes" name="type" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            </div>
                            <div class="mx-2 text-sm">
                                <label for="properties-radio" class="font-medium text-gray-900 dark:text-gray-300">Hotel Properties</label>
                                <p id="helper-radio-text" class="text-xs font-normal text-gray-500 dark:text-gray-300">Set Hotel properties. Ex: Spa, Swimming Pool, etc</p>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- Modal footer -->
                <div
                    class="flex items-center space-x-2 rtl:space-x-reverse rounded-b border-t border-gray-200 p-6 dark:border-gray-600">
                    <button type="button" @click="newParameter()"
                        class="rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Select
                    </button>
                    <button type="button" @click="closeModal()"
                        class="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>