<script setup lang="ts">
import { onMounted, ref, type Ref } from 'vue';
import Spinner from '@/components/animations/TableSpinner.vue';
import axiosApiInstance from '@/helpers/api.axios.headers';

const hotelName: Ref<string> = ref('');
const hotelId: Ref<string> = ref('');
const externalHotelId: Ref<string> = ref('');
const country: Ref<string> = ref('');
const region: Ref<string> = ref('');
const location: Ref<string> = ref('');

const loader: Ref<boolean> = ref(false);
const items: Ref<Array<any>> = ref([]);

onMounted(() => {
    hotelsList();
});
const hotelsList = () => {
    loader.value = true;
    axiosApiInstance
        .post('/getExtranetHotelByRoot', {
            filters: {
               
            },
        })
        .then(function (response) {
            loader.value = false;
            console.log(response);
            
            if (!response.data.status) {
                alert(response.data.message);
            } else {
                items.value = response.data.extranetHotels;
            }
        })
        .catch(function (error) {
            alert(error);
        });
}

</script>
<template>
    <div>
        <div>
            <div class="border-2 border-gray-400 rounded p-5">
                <div>
                    <div class="text-xl dark:text-gray-300">{{ items.length }} Hotels, 2 in progress, 1 completed</div>
                </div>
                <hr class="border-gray-400 my-2">
                <div class="flex justify-between">
                    <div class="w-5/6">
                        <div class="flex mb-2">
                            <div class="mr-4">
                                <label for="hotelName" class="block text-gray-700 dark:text-gray-300">Hotel Name</label>
                                <input type="text" name="hotelName" id="hotelName" v-model="hotelName" autocomplete="off"
                                    placeholder="" class="form-control w-64 mt-1" />
                            </div>
                            <div class="mr-4">
                                <label for="hotelId" class="block text-gray-700 dark:text-gray-300">Hotel ID</label>
                                <input type="text" name="hotelId" id="hotelId" v-model="hotelId" autocomplete="off"
                                    placeholder="" class="form-control w-64 mt-1" />
                            </div>
                            <div class="mr-4">
                                <label for="externalHotelId" class="block text-gray-700 dark:text-gray-300">External Hotel
                                    ID</label>
                                <input type="text" name="externalHotelId" id="externalHotelId" v-model="externalHotelId"
                                    autocomplete="off" placeholder="" class="form-control w-64 mt-1" />
                            </div>
                            <div class="mr-4">
                                <label for="country" class="block text-gray-700 dark:text-gray-300">Country</label>
                                <select id="country" name="country" class="form-select w-64 mt-1" v-model="country">
                                    <option value="">Select country</option>
                                </select>
                            </div>
                            <div class="mr-4">
                                <label for="region" class="block text-gray-700 dark:text-gray-300">Region</label>
                                <select id="region" name="region" class="form-select w-64 mt-1" v-model="region">
                                    <option value="">Select region</option>
                                </select>
                            </div>
                            <div class="mr-4">
                                <label for="location" class="block text-gray-700 dark:text-gray-300">Location</label>
                                <select id="location" name="location" class="form-select w-64 mt-1" v-model="location">
                                    <option value="">Select location</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-end items-end w-1/6">
                        <div>
                            <button class="btn-success mr-2">Filter</button>
                            <button class="btn-warning mr-2">Reset</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg pt-0">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-blue-200 dark:bg-gray-600 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="px-3 py-2">Nr.</th>
                        <th scope="col" class="px-3 py-2">Name</th>
                        <th scope="col" class="px-3 py-2">Country</th>
                        <th scope="col" class="px-3 py-2">City</th>
                        <th scope="col" class="px-3 py-2">Created/Edited by</th>
                        <th scope="col" class="px-3 py-2">Created/Edited at</th>
                        <th scope="col" class="px-3 py-2">Status</th>
                        <th scope="col" class="px-3 py-2">Published</th>
                        <th scope="col" class="px-3 py-2">Action</th>
                    </tr>
                    <tr v-show="loader">
                        <td colspan="9" class="bg-white border-b dark:bg-gray-900 dark:border-gray-700 p-2">
                            <div class="flex items-center justify-center">
                                <Spinner />
                                <div class="text-lg pl-2">Loading...</div>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="items.length === 0 && !loader"
                        class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                        <td colspan="99" class="text-center">
                            <div class="font-bold dark:text-white text-xl uppercase my-2">
                                No Hotels found.
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in items" :key="index" class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                        <td class="p-2 text-center">
                            {{ index + 1 }}
                        </td>
                        <td class="p-2">
                            {{ item.hotel_name }}
                            <span v-for="star in item.hotel_stars" :key="star" class="text-orange-500">
                                *
                            </span>
                        </td>
                        <td class="p-2">
                            {{ item.location_id }}
                        </td>
                        <td class="p-2">
                            {{ item.location_id }}
                        </td>
                        <td class="p-2">
                            {{ item.location_id }}
                        </td>
                        <td class="p-2">
                            {{ item.date_added }}/{{ item.hotel_date_modified }}
                        </td>
                        <td class="p-2">
                            <span class="fw-bold text-orange-700">Requires attention</span>
                        </td>
                        <td class="p-2">
                            <label class="inline-flex relative items-center cursor-pointer">
                                <input type="checkbox" value="" class="sr-only peer" />
                                <div
                                    class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
                                </div>
                            </label>
                        </td>
                        <td class="p-2">
                            <a href="/ucm/hotel" class="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer">
                                Edit
                            </a>
                            |
                            <a type="button"
                                class="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer">Delete</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>