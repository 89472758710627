<script setup lang="ts">
import axiosApiInstance from '@/helpers/api.axios.headers';
import { ref, type Ref, onMounted } from 'vue';
import IconTrashBin from '@/components/icons/IconTrashBin.vue'
import { useAllocation } from '@/stores/allocationExtra';
import type { Location } from '@/types/autosuggest.interfaces';

const props = defineProps({
    type: String,
    editData: String,
});

const allocationStore = useAllocation();
const locationResults: Ref<Array<Location>> = ref([]);
const locationInput: Ref<string> = ref('');
const showList: Ref<boolean> = ref(false);
const listResults: Ref<string[]> = ref([]);
const alertSuggestMessage: Ref<string> = ref('');

onMounted(() => {
    if(props.editData != '' && props.editData != undefined){

        listResults.value = props.editData.split(",");
    }
});

let controller = new AbortController();
const requestTerm = (event: Event) => {
    showList.value = false;
    if ((event.target as HTMLInputElement).value.length < 2) return false;


    controller.abort();
    controller = new AbortController();
    axiosApiInstance
        .post(
            '/suggest_iata_locations',
            {
                terms: (event.target as HTMLInputElement).value,
            },
            {
                signal: controller.signal,
            }
        )
        .then(function (response) {
            if (!response.data.status) {
                alertSuggestMessage.value = response.data.message;
                return false;
            } else {
                alertSuggestMessage.value = '';
                locationResults.value = response.data.result;
                showList.value = true;
            }
        })
        .catch(function (error) {
            console.info(error.name);
        });
};

const setInput = (value: string, name: string) => {

    listResults.value.push(value)
    locationInput.value = '';
    locationResults.value = [];
    alertSuggestMessage.value = '';

    if(props.type === 'outbound'){

        allocationStore.setOubtound(listResults.value);

    }else if(props.type === 'inbound'){

        allocationStore.setInbound(listResults.value);
    }
    
};

const deleteIata = (index: number) => {
    
    listResults.value.splice(index, 1);
    if(props.type === 'outbound'){

        allocationStore.setOubtound(listResults.value);

    }else if(props.type === 'inbound'){

        allocationStore.setInbound(listResults.value);
    }
}

</script>
<template>
    <div>
        <div class="flex items-center max-w-sm">
            <label for="simple-search" class="sr-only">Search</label>
            
            <div class="relative w-full">
                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                </div>
                <input 
                    type="input" 
                    @input="requestTerm"
                    @click="showList = true"
                    v-model="locationInput"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search airports or countries" />
            </div>
        </div>
        <div
                v-show="locationResults.length != 0 && showList"
                class="absolute z-10 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            >
                <button
                    v-for="(location, index) in locationResults"
                    :key="index"
                    @click="
                        setInput(location.value, location.name), (showList = false)
                    "
                    type="button"
                    class="w-full px-4 py-2 font-medium text-left border-b border-gray-200 cursor-pointer hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white"
                >
                    <span v-if="location.type == 'airport'" class="bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300">{{ location.type }}</span>
                    <span v-else class="bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300">{{ location.type }}</span>
                    {{ location.value }} , {{ location.name }}
                </button>
            </div>
        <div v-if="listResults.length > 0">
            Selection:
            <div class="flex">
                <div v-for="(iata, index) in listResults" class="me-2" :key="index">
                    <button type="button" title="Click to remove this location"
                        class="flex items-center py-1 px-2 mx-2 text-xs font-medium text-center text-white bg-blue-700 rounded blue:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        @click="deleteIata(index)">
                        {{ iata }}
                        <IconTrashBin />
                    </button>
                </div>
            </div>
        </div>
       
       
    </div>
</template>