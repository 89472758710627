<script setup lang="ts">
import Datepicker from '@vuepic/vue-datepicker';
import { usePackageStore } from '@/stores/package';
import { getTransportsService } from '@/services/getRoutesForService';
import { reactive, ref, toRaw, watch, type Ref } from 'vue';
import type { RouteItem } from '@/types/builder/routeItem.interface';

import DateSelectors from './DateSelectors.vue';


const items: Ref<Array<RouteItem>> = ref([]);
const filteredItems: Ref<Array<RouteItem>> = ref([]);
const loader: Ref<boolean> = ref(false);
const datesToRender: Ref<RouteItem | undefined> = ref();

const store = usePackageStore();

const initialFiltersState = {
    departureCountry: '',
    departureCity: '',
    destinationCountry: '',
    destinationCity: '',
    datesInterval: ['', ''],
};

const filters = reactive({
    ...initialFiltersState,
});

const format = (date: Array<Date>) => {
    date = toRaw(date);

    const day = date[0].getDate();
    const month = date[0].getMonth() + 1;
    const year = date[0].getFullYear();

    if (date[1] != null) {
        const day1 = date[1].getDate();
        const month1 = date[1].getMonth() + 1;
        const year1 = date[1].getFullYear();

        return `${day}/${month}/${year} - ${day1}/${month1}/${year1}`;
    }
};

const getTransports = async (type: string, period: []) => {
    loader.value = true;
    items.value = filteredItems.value = [];
    store.package.transport.transportId = store.package.transport.transportName = '';

    getTransportsService(type, period).then((res) => {
        loader.value = false;
        if (res != undefined && res != false) {
            items.value = filteredItems.value = res;
        }
    });
};

const filterResults = (reset: boolean) => {
    if (reset) {
        Object.assign(filters, initialFiltersState);

        filteredItems.value = toRaw(items.value);

        return false;
    }

    filteredItems.value = toRaw(items.value).filter((route: any) => {
        if (toRaw(filters).datesInterval == null) {
            filters.datesInterval = ['', ''];
        }

        if (toRaw(filters.datesInterval)[0] === '' && toRaw(filters.datesInterval)[1] === '') {
            if (
                route.departureCountry
                    .toLowerCase()
                    .indexOf(toRaw(filters).departureCountry.toLowerCase()) === 0 &&
                route.departureCity
                    .toLowerCase()
                    .indexOf(toRaw(filters).departureCity.toLowerCase()) === 0 &&
                route.destinationCountry
                    .toLowerCase()
                    .indexOf(toRaw(filters).destinationCountry.toLowerCase()) === 0 &&
                route.destinationCity
                    .toLowerCase()
                    .indexOf(toRaw(filters).destinationCity.toLowerCase()) === 0
            ) {
                return true;
            }
        } else {
            if (
                route.departureCountry
                    .toLowerCase()
                    .indexOf(toRaw(filters).departureCountry.toLowerCase()) === 0 &&
                route.departureCity
                    .toLowerCase()
                    .indexOf(toRaw(filters).departureCity.toLowerCase()) === 0 &&
                route.destinationCountry
                    .toLowerCase()
                    .indexOf(toRaw(filters).destinationCountry.toLowerCase()) === 0 &&
                route.destinationCity
                    .toLowerCase()
                    .indexOf(toRaw(filters).destinationCity.toLowerCase()) === 0 &&
                Date.parse(String(route.startDate)) >=
                    Date.parse(
                        String(new Date(new Date(toRaw(filters).datesInterval[0]).toDateString()))
                    ) &&
                Date.parse(String(route.startDate)) <=
                    Date.parse(
                        String(new Date(new Date(toRaw(filters).datesInterval[1]).toDateString()))
                    )
            ) {
                return true;
            }
        }
    });
};

watch(
    () => store.package.generalInfo.period,
    () => {
        items.value = filteredItems.value = [];
        datesToRender.value = undefined;
    }
);

</script>

<template>
    <div>
        <div>
            <div class="text-xl underline underline-offset-2 mb-4">Packages Transport Type</div>
            <div class="flex my-4">
                <div class="mr-4 border-r-2 pr-4">
                    <div class="flex items-start">
                        <div class="flex items-center h-5">
                            <input
                                id="charterFlightTransportType"
                                name="transportType"
                                type="radio"
                                value="charter"
                                @change="
                                    getTransports(
                                        ($event.target as HTMLInputElement).value,
                                        store.package.generalInfo.period as []
                                    )
                                "
                                v-model="store.package.transport.transportType"
                                class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                            />
                        </div>
                        <div class="ml-3 text-sm">
                            <label for="charterFlightTransportType" class="cursor-pointer"
                                >Charter Flight</label
                            >
                            <p class="text-gray-500">Transportation by Charter Flight</p>
                        </div>
                    </div>
                </div>
                <div class="mr-4 border-r-2 pr-4">
                    <div class="flex items-start">
                        <div class="flex items-center h-5">
                            <input
                                id="charterBusTransportType"
                                name="transportType"
                                type="radio"
                                value="bus_charter"
                                @change="
                                    getTransports(
                                        ($event.target as HTMLInputElement).value,
                                        store.package.generalInfo.period as []
                                    )
                                "
                                v-model="store.package.transport.transportType"
                                class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                            />
                        </div>
                        <div class="ml-3 text-sm">
                            <label for="charterBusTransportType" class="cursor-pointer"
                                >Charter Bus</label
                            >
                            <p class="text-gray-500">Transportation by Charter Bus</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="flex items-start" title="Curently disabled... Work is in progress">
                        <div class="flex items-center h-5">
                            <input
                                id="airlineCompanyTransportType"
                                name="transportType"
                                value="airline"
                                type="radio"
                                @change="
                                    getTransports(
                                        ($event.target as HTMLInputElement).value,
                                        store.package.generalInfo.period as []
                                    )
                                "
                                v-model="store.package.transport.transportType"
                                disabled
                                class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                            />
                        </div>
                        <div class="ml-3 text-sm">
                            <label for="airlineCompanyTransportType" class="cursor-pointer"
                                >Airline Company</label
                            >
                            <p class="text-gray-500">Transportation by Airline Company</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-xl underline underline-offset-2 mb-2">Select Transport</div>
        <div class="ml-2 mb-4 text-sm">
            <p class="text-gray-500">Select one of the options listed below</p>
        </div>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg pt-0">
            <div>
                <div class="border-2 border-gray-400 rounded p-5">
                    <div class="flex justify-between">
                        <div class="text-xl">{{ items.length }} Results</div>
                    </div>
                    <hr class="border-gray-400 my-2" />
                    <div class="flex justify-between">
                        <div class="flex">
                            <div class="col-span-6 sm:col-span-3 mr-2">
                                <label for="departureCountry" class="block text-gray-700"
                                    >Departure Country</label
                                >
                                <input
                                    type="text"
                                    name="departureCountry"
                                    id="departureCountry"
                                    autocomplete="off"
                                    v-model="filters.departureCountry"
                                    placeholder="Romania"
                                    class="form-control mt-1"
                                />
                            </div>
                            <div class="col-span-6 sm:col-span-3 mr-2">
                                <label for="departureCity" class="block text-gray-700"
                                    >Departure City</label
                                >
                                <input
                                    type="text"
                                    name="departureCity"
                                    id="departureCity"
                                    autocomplete="off"
                                    v-model="filters.departureCity"
                                    placeholder="Bucharest"
                                    class="form-control mt-1"
                                />
                            </div>
                            <div class="col-span-6 sm:col-span-3 mr-2">
                                <label for="destinationCountry" class="block text-gray-700"
                                    >Arrival Country</label
                                >
                                <input
                                    type="text"
                                    name="destinationCountry"
                                    id="destinationCountry"
                                    autocomplete="off"
                                    v-model="filters.destinationCountry"
                                    placeholder="Turkey"
                                    class="form-control mt-1"
                                />
                            </div>
                            <div class="col-span-6 sm:col-span-3 mr-2">
                                <label for="destinationCity" class="block text-gray-700"
                                    >Arrival City</label
                                >
                                <input
                                    type="text"
                                    name="destinationCity"
                                    id="destinationCity"
                                    autocomplete="off"
                                    v-model="filters.destinationCity"
                                    placeholder="Antalya"
                                    class="form-control mt-1"
                                />
                            </div>
                            <div>
                                <label for="datesInterval" class="block text-gray-700"
                                    >Dates Interval</label
                                >
                                <Datepicker
                                    class="mt-1"
                                    v-model="filters.datesInterval"
                                    range
                                    :enableTimePicker="false"
                                    id="datesInterval"
                                    :format="format"
                                    :previewFormat="format"
                                ></Datepicker>
                            </div>
                        </div>
                        <div class="flex items-end">
                            <button class="btn-success mr-2" @click="filterResults(false)">
                                Filter
                            </button>
                            <button class="btn-warning mr-2" @click="filterResults(true)">
                                Reset
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 mb-0">
                <thead
                    class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
                >
                    <tr v-show="loader" colspan="99">
                        <svg
                            role="status"
                            class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                            />
                        </svg>
                    </tr>
                    <tr>
                        <th scope="col" class="px-6 py-3">Nr.</th>
                        <th scope="col" class="px-6 py-3">Name</th>
                        <th scope="col" class="px-6 py-3">Provider</th>
                        <th scope="col" class="px-6 py-3">Departure Country</th>
                        <th scope="col" class="px-6 py-3">Departure City</th>
                        <th scope="col" class="px-6 py-3">Arrival Country</th>
                        <th scope="col" class="px-6 py-3">Arrival City</th>
                        <th scope="col" class="px-6 py-3">Rotations</th>
                        <th scope="col" class="px-6 py-3">Start Date</th>
                        <th scope="col" class="px-6 py-3">End Date</th>
                        <th scope="col" class="px-6 py-3">Select</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(item, index) in filteredItems"
                        :key="index"
                        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                        <td class="px-3 py-2">
                            {{ index + 1 }}
                        </td>
                        <th
                            scope="row"
                            class="px-3 py-2 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                        >
                            {{ item.startDate }} : {{ item.departureCity }} -
                            {{ item.destinationCity }}
                        </th>
                        <td class="px-3 py-2">
                            {{ item.providerName }}
                        </td>
                        <td class="px-3 py-2">
                            {{ item.departureCountry }}
                        </td>
                        <td class="px-3 py-2">
                            {{ item.departureCity }}
                        </td>
                        <td class="px-3 py-2">
                            {{ item.destinationCountry }}
                        </td>
                        <td class="px-3 py-2">
                            {{ item.destinationCity }}
                        </td>
                        <td class="px-3 py-2">
                            {{ item.rotations }}
                        </td>
                        <td class="px-3 py-2">
                            {{ item.startDate }}
                        </td>
                        <td class="px-3 py-2">
                            {{ item.endDate }}
                        </td>
                        <td class="px-3 py-2">
                            <div class="flex items-center">
                                <input
                                    type="radio"
                                    name="transportId"
                                    v-model="store.package.transport.transportId"
                                    :value="(item.routeId as string)"
                                    @change="
                                        datesToRender = item;
                                        store.package.transport.dates = new Array();
                                        store.package.transport.transportName = `${item.departureCity} - ${item.destinationCity}`;
                                    "
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                                />
                            </div>
                        </td>
                    </tr>
                    <tr v-if="datesToRender != undefined" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <DateSelectors :dates="datesToRender" :key="datesToRender?.routeId"/>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
