<script setup lang="ts">
import IconDashboard from '@/components/icons/IconDashboard.vue';
import IconCharter from '@/components/icons/IconCharter.vue';
import IconAirline from '@/components/icons/IconAirline.vue';
import IconBus from '@/components/icons/IconBus.vue';
import IconPackage from '@/components/icons/IconPackage.vue';
import IconBooking from '@/components/icons/IconBooking.vue';
import IconLogout from '@/components/icons/IconLogout.vue';
import IconWallet from '@/components/icons/IconWallet.vue';
import IconRocket from '@/components/icons/IconRocket.vue';
import IconGearCode from '@/components/icons/IconGearCode.vue';
import IconChart from '@/components/icons/IconChart.vue';
import { onMounted } from 'vue';
import { useUserStore } from '@/stores/user';

const userStore = useUserStore();

onMounted(() => {
    // create an array of objects with the id, trigger element (eg. button), and the content element
    const accordionItems = [
        {
            id: 'accordion-example-heading-1',
            triggerEl: document.querySelector('#accordion-example-heading-1'),
            targetEl: document.querySelector('#accordion-example-body-1'),
            active: false,
        },
        {
            id: 'accordion-example-heading-2',
            triggerEl: document.querySelector('#accordion-example-heading-2'),
            targetEl: document.querySelector('#accordion-example-body-2'),
            active: false,
        },
        {
            id: 'accordion-example-heading-3',
            triggerEl: document.querySelector('#accordion-example-heading-3'),
            targetEl: document.querySelector('#accordion-example-body-3'),
            active: false,
        },
        {
            id: 'accordion-example-heading-4',
            triggerEl: document.querySelector('#accordion-example-heading-4'),
            targetEl: document.querySelector('#accordion-example-body-4'),
            active: false,
        },
    ];

    // @ts-ignore: Unreachable code error
    const accordion = new Accordion(accordionItems);
});
</script>
<template>
    <div class="w-1.5/6">
        <aside aria-label="Sidebar">
            <div class="overflow-y-auto py-4 px-3 bg-gray-50 dark:bg-gray-800 min-h-screen h-100">
                <div class="px-3 mb-3 dark:text-white">
                    <h1 class="text-xl">Package Builder</h1>
                    <i>by TCT</i>
                </div>
                <hr />
                <ul class="space-y-2">
                    <li>
                        <RouterLink
                            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                            to="/">
                            <IconDashboard class="w-6 mr-2" />
                            <span class="ml-3">Dashboard</span>
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink to="/charterRoutes"
                            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                            <IconCharter class="w-6 mr-2" />
                            <span class="ml-3">Charter Routes</span>
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink to="/busRoutes"
                            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                            <IconBus class="w-6 mr-2" />
                            <span class="ml-3">Bus Routes</span>
                        </RouterLink>
                    </li>
                    <li>
                        <div>
                            <h2 id="accordion-example-heading-1">
                                <button type="button"
                                    class="flex items-center p-2 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                    aria-expanded="false" aria-controls="accordion-example-body-1">
                                    <IconAirline class="w-6 mr-2" />
                                    <span class="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>Airline
                                        Routes</span>
                                    <svg sidebar-toggle-item class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                </button>
                            </h2>
                            <div id="accordion-example-body-1" class="hidden" aria-labelledby="accordion-example-heading-1">
                                <ul class="pl-6">
                                    <li>
                                        <router-link
                                            class="flex items-center p-2 pl-3 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                            to="/airlineRoutes">
                                            List Airline Routes
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            class="flex items-center p-2 pl-3 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                            :to="{
                                                name: 'Add New Airline Route',
                                            }">
                                            Add New Airline Route
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                            <h2 id="accordion-example-heading-2">
                                <button type="button"
                                    class="flex items-center p-2 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                    aria-expanded="false" aria-controls="accordion-example-body-2">
                                    <IconPackage class="w-6 mr-2" />
                                    <span class="flex-1 ml-3 text-left whitespace-nowrap"
                                        sidebar-toggle-item>Packages</span>
                                    <svg sidebar-toggle-item class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                </button>
                            </h2>
                            <div id="accordion-example-body-2" class="hidden" aria-labelledby="accordion-example-heading-2">
                                <ul class="pl-6">
                                    <li>
                                        <router-link
                                            class="flex items-center p-2 pl-3 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                            to="/packages">
                                            List Packages
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            class="flex items-center p-2 pl-3 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                            :to="{ name: 'Build New Packages' }">
                                            Build New Package
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div>
                            <h2 id="accordion-example-heading-3">
                                <button type="button"
                                    class="flex items-center p-2 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                    aria-expanded="false" aria-controls="accordion-example-body-3">
                                    <IconChart class="w-6 mr-2" />
                                    <span class="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>Extra</span>
                                    <svg sidebar-toggle-item class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                </button>
                            </h2>
                            <div id="accordion-example-body-3" class="hidden"
                                aria-labelledby="accordion-example-heading-3">
                                <ul class="pl-6">
                                    <li>
                                        <router-link
                                            class="flex items-center p-2 pl-3 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                            to="/extra">
                                            Add Extra
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            class="flex items-center p-2 pl-3 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                            to="/extra/list">
                                            Extra list
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            class="flex items-center p-2 pl-3 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                            to="/allocation">
                                            Add Allocation
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            class="flex items-center p-2 pl-3 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                            to="/allocation/list">
                                            Allocation list
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <div>

                        <li>
                            <RouterLink to="/caching"
                                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <IconRocket class="w-6 mr-2" />
                                <span class="ml-3">Caching</span>
                            </RouterLink>
                        </li>
                        <li>
                            <RouterLink to="/wallet"
                                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <IconWallet class="w-6 mr-2" />
                                <span class="ml-3">Wallet</span>
                            </RouterLink>
                        </li>
                        <li>
                            <div>
                                <h2 id="accordion-example-heading-4">
                                    <button type="button"
                                        class="flex items-center p-2 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                        aria-expanded="false" aria-controls="accordion-example-body-4">
                                        <IconGearCode class="w-6 mr-2" />
                                        <span class="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>UCM</span>
                                        <svg sidebar-toggle-item class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd"
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clip-rule="evenodd"></path>
                                        </svg>
                                    </button>
                                </h2>
                                <div id="accordion-example-body-4"
                                    class="hidden"
                                    aria-labelledby="accordion-example-heading-4">
                                    <ul class="pl-6">
                                        <li>
                                            <router-link
                                                class="flex items-center p-2 pl-3 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                                to="/ucm/hotels">
                                                Hotels
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </div>
                    <li>
                        <RouterLink to="/bookings"
                            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                            <IconBooking class="w-6 mr-2" />
                            <span class="ml-3">Bookings</span>
                        </RouterLink>
                    </li>
                </ul>
                <ul class="pt-4 mt-4 space-y-2 border-t border-gray-200 dark:border-gray-700">
                    <li>
                        <RouterLink to="/logout"
                            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                            <IconLogout class="w-6 mr-2" />
                            <span class="ml-3">Logout</span>
                        </RouterLink>
                    </li>
                </ul>
            </div>
        </aside>
    </div>
</template>
