<script setup lang="ts">
import { onMounted, ref, type Ref } from 'vue';
import { useUserStore } from '@/stores/user';
import { useRoute } from 'vue-router';
import Alert from '@/components/AlertBox.vue';
import IconChevronCircleLeft from '@/components/icons/IconChevronCircleLeft.vue';
import IconChevronCircleRight from '@/components/icons/IconChevronCircleRight.vue';
import GeneralInfo from '@/components/ucm/GeneralInfo.vue';
import Descriptions from '@/components/ucm/Descriptions.vue';
import Parameters from '@/components/ucm/Parameters.vue';
import Images from '@/components/ucm/Images.vue';
import Location from '@/components/ucm/Location.vue';


const route = useRoute();
const userStore = useUserStore();

const fullscreenLoader = ref(false);
const alertMessage: Ref<string> = ref('');
const alertType: Ref<string> = ref('');

let tabs: any = '';

onMounted(() => {
    const tabElements = [
        {
            id: 'general',
            triggerEl: document.querySelector('#general-tab'),
            targetEl: document.querySelector('#general'),
        },
        {
            id: 'descriptions',
            triggerEl: document.querySelector('#descriptions-tab'),
            targetEl: document.querySelector('#descriptions'),
        },
        {
            id: 'parameters',
            triggerEl: document.querySelector('#parameters-tab'),
            targetEl: document.querySelector('#parameters'),
        },
        {
            id: 'images',
            triggerEl: document.querySelector('#images-tab'),
            targetEl: document.querySelector('#images'),
        },
        {
            id: 'location',
            triggerEl: document.querySelector('#location-tab'),
            targetEl: document.querySelector('#location'),
        },
    ];

    // options with default values
    const options = {
        defaultTabId: 'settings',
        activeClasses:
            'text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-400 border-blue-600 dark:border-blue-500',
        inactiveClasses:
            'text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300',
    };

    // @ts-ignore: Unreachable code error
    tabs = new Tabs(tabElements, options);

});

const switchToTab = (tabNameFrom: string, tabNameTo: string, type: string): void | boolean => {

    if (type === 'next') {
        // if (!validateTab(tabNameFrom)) {
            // window.scrollTo(0, 0);
            // return false;
        // }
    }

    tabs.show(tabNameTo);
};

const saveUCMHotel = () => {

    // do the axios 

}

const prevent = () => {
    return false;
};

</script>
<template>
    <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
        <FullscreenLoader v-show="fullscreenLoader" />
        <Transition>
            <Alert
                v-show="alertMessage != ''"
                :alertMessage="alertMessage"
                :alertType="alertType"
            />
        </Transition>
        <ul
            class="flex flex-wrap -mb-px text-sm font-medium text-center"
            id="myTab"
            data-tabs-toggle="#tabBuildPackage"
            role="tablist"
        >
            <li class="mr-2" role="presentation">
                <button
                    class="inline-block p-4 rounded-t-lg border-b-2 text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-500 border-blue-600 dark:border-blue-500"
                    id="general-tab"
                    data-tabs-target="#general"
                    type="button"
                    role="tab"
                    aria-controls="general"
                    aria-selected="true"
                    
                >
                    General Info
                </button>
            </li>
            <li class="mr-2" role="presentation">
                <button
                    class="inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:border-transparent text-gray-500 dark:text-gray-400 border-gray-100 dark:border-gray-700"
                    id="descriptions-tab"
                    data-tabs-target="#descriptions"
                    type="button"
                    role="tab"
                    aria-controls="descriptions"
                    aria-selected="false"
                    
                >
                    Descriptions
                </button>
            </li>
            <li class="mr-2" role="presentation">
                <button
                    class="inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:border-transparent text-gray-500 dark:text-gray-400 border-gray-100 dark:border-gray-700"
                    id="parameters-tab"
                    data-tabs-target="#parameters"
                    type="button"
                    role="tab"
                    aria-controls="parameters"
                    aria-selected="false"
                    
                >
                    Parameters
                </button>
            </li>
            <li class="mr-2" role="presentation">
                <button
                    class="inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:border-transparent text-gray-500 dark:text-gray-400 border-gray-100 dark:border-gray-700"
                    id="images-tab"
                    data-tabs-target="#images"
                    type="button"
                    role="tab"
                    aria-controls="images"
                    aria-selected="false"
                    
                >
                    Images
                </button>
            </li>
            <li class="hidden mr-2" role="presentation">
                <button
                    class="inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:border-transparent text-gray-500 dark:text-gray-400 border-gray-100 dark:border-gray-700"
                    id="location-tab"
                    data-tabs-target="#location"
                    type="button"
                    role="tab"
                    aria-controls="location"
                    aria-selected="false"
                    
                >
                    Location
                </button>
            </li>
        </ul>
    </div>
    <form id="tabBuildPackage" @submit.prevent="prevent">
        <div
            class="p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
            id="general"
            role="tabpanel"
            aria-labelledby="general-tab"
        >
            <div class="container mx-auto p-4 bg-gray-50 rounded-lg dark:bg-gray-800 mb-8">
                <GeneralInfo />
                <div class="flex justify-end mt-8">
                    <button
                        type="button"
                        class="btn-success inline-flex items-center"
                        @click="switchToTab('general', 'descriptions', 'next')"
                    >
                        Next Step
                        <IconChevronCircleRight class="w-5 h-5 ml-2 -mr-1 fill-white" />
                    </button>
                </div>
            </div>
        </div>
        <div
            class="hidden p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
            id="descriptions"
            role="tabpanel"
            aria-labelledby="descriptions-tab"
        >
            <div class="container mx-auto p-4 pt-0 bg-gray-50 rounded-lg dark:bg-gray-800 mb-8">
                <Descriptions />
                <div class="flex justify-end mt-8">
                    <button
                        type="button"
                        class="btn-warning inline-flex items-center"
                        @click="switchToTab('descriptions', 'general', 'prev')"
                    >
                        <IconChevronCircleLeft class="w-5 h-5 mr-4 fill-white" />
                        Previous Step
                    </button>
                    <button
                        type="button"
                        class="btn-success inline-flex items-center"
                        @click="switchToTab('descriptions', 'parameters', 'next')"
                    >
                        Next Step
                        <IconChevronCircleRight class="w-5 h-5 ml-2 -mr-1 fill-white" />
                    </button>
                </div>
            </div>
        </div>
        <div
            class="hidden p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
            id="parameters"
            role="tabpanel"
            aria-labelledby="parameters-tab"
        >
            <div class="container mx-auto p-4 bg-gray-50 rounded-lg dark:bg-gray-800 mb-8">
                <Parameters />
                <div class="flex justify-end mt-8">
                    <button
                        type="button"
                        class="btn-warning inline-flex items-center"
                        @click="switchToTab('parameters', 'descriptions', 'prev')"
                    >
                        <IconChevronCircleLeft class="w-5 h-5 mr-4 fill-white" />
                        Previous Step
                    </button>
                    <button
                        type="button"
                        class="btn-success inline-flex items-center"
                        @click="switchToTab('parameters', 'images', 'next')"
                    >
                        Next Step
                        <IconChevronCircleRight class="w-5 h-5 ml-2 -mr-1 fill-white" />
                    </button>
                </div>
            </div>
        </div>
        <div
            class="hidden p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
            id="images"
            role="tabpanel"
            aria-labelledby="images-tab"
        >
            <div class="container mx-auto p-4 bg-gray-50 rounded-lg dark:bg-gray-800 mb-8">
                <Images />
                <div>
                    <div class="flex justify-end mt-8">
                        <button
                            type="button"
                            class="btn-warning inline-flex items-center"
                            @click="switchToTab('images', 'parameters', 'prev')"
                        >
                            <IconChevronCircleLeft class="w-5 h-5 mr-4 fill-white" />
                            Previous Step
                        </button>
                        <button
                            type="button"
                            class="btn-success inline-flex items-center"
                            @click="saveUCMHotel()"
                        >
                            Save changes
                            <IconChevronCircleRight class="w-5 h-5 ml-2 -mr-1 fill-white" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="hidden p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
            id="location"
            role="tabpanel"
            aria-labelledby="location-tab"
        >
            <div class="container mx-auto p-4 bg-gray-50 rounded-lg dark:bg-gray-800 mb-8">
                <Location />
            </div>
            <div>
                <div class="flex justify-end mt-8">
                    <button
                        type="button"
                        class="btn-warning inline-flex items-center"
                        @click="switchToTab('location', 'images', 'prev')"
                    >
                        <IconChevronCircleLeft class="w-5 h-5 mr-4 fill-white" />
                        Previous Step
                    </button>
                    <button
                        type="button"
                        class="btn-primary inline-flex items-center"
                    >
                        Create/Edit Package
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<style>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>