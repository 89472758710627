<script setup lang="ts">
import { RouterLink } from 'vue-router';
import { onMounted, ref, toRaw, type Ref } from 'vue';

import Datepicker from '@vuepic/vue-datepicker';
import Alert from '@/components/AlertBox.vue';

const alertMessage: Ref<string> = ref('');
const alertType: Ref<string> = ref('');

onMounted(() => {
    alertType.value = 'alert-warning';
    alertMessage.value = 'Page under construction!';
});

const format = (date: Array<Date>) => {
    date = toRaw(date);

    const day = date[0].getDate();
    const month = date[0].getMonth() + 1;
    const year = date[0].getFullYear();

    if (date[1] != null) {
        const day1 = date[1].getDate();
        const month1 = date[1].getMonth() + 1;
        const year1 = date[1].getFullYear();

        return `${day}/${month}/${year} - ${day1}/${month1}/${year1}`;
    }
};

const date: Ref<string> = ref('');
</script>
<template>
    <Alert v-show="alertMessage != ''" :alertMessage="alertMessage" :alertType="alertType" />
    <div class="p-10">
        <div>
            <div class="border-2 border-gray-400 rounded p-5">
                <div class="flex justify-between">
                    <div class="text-xl">2 Results, 2 Destinations</div>
                    <div>
                        <router-link
                            class="btn-primary mr-2"
                            :to="{ name: 'Add New Airline Route' }"
                        >
                            Add New Airline Route
                        </router-link>
                    </div>
                </div>
                <hr class="border-gray-400 my-2" />
                <div class="flex justify-between">
                    <div class="flex mr-2">
                        <div class="col-span-6 sm:col-span-3 mr-2">
                            <label for="provider" class="block text-gray-700">Provider</label>
                            <select id="provider" name="provider" class="form-select w-52 mt-1">
                                <option>Select Provider</option>
                                <option>Worldspan</option>
                            </select>
                        </div>
                        <div class="col-span-6 sm:col-span-3 mr-2">
                            <label for="airlineCompany" class="block text-gray-700"
                                >Airline Company</label
                            >
                            <input
                                type="text"
                                name="airlineCompany"
                                id="airlineCompany"
                                autocomplete="off"
                                placeholder="Airline Company"
                                class="form-control w-52 mt-1"
                            />
                        </div>
                        <div class="col-span-6 sm:col-span-3 mr-2">
                            <label for="departure" class="block text-gray-700">Departure</label>
                            <input
                                type="text"
                                name="departure"
                                id="departure"
                                autocomplete="off"
                                placeholder="Turkey"
                                class="form-control w-52 mt-1"
                            />
                        </div>
                        <div class="col-span-6 sm:col-span-3 mr-2">
                            <label for="arrival" class="block text-gray-700">Arrival</label>
                            <input
                                type="text"
                                name="arrival"
                                id="arrival"
                                autocomplete="off"
                                placeholder="Antalya"
                                class="form-control w-52 mt-1"
                            />
                        </div>
                        <div class="col-span-6 sm:col-span-3 mr-2">
                            <label for="provider" class="block text-gray-700">Type</label>
                            <select id="provider" name="provider" class="form-select w-52 mt-1">
                                <option>Select Type</option>
                                <option>Type(m/a)</option>
                            </select>
                        </div>
                        <div>
                            <label for="datesInterval" class="block text-gray-700"
                                >Dates Interval</label
                            >
                            <Datepicker
                                class="mt-1 w-56"
                                v-model="date"
                                range
                                :enableTimePicker="false"
                                id="datesInterval"
                                :format="format"
                                :previewFormat="format"
                            ></Datepicker>
                        </div>
                    </div>
                    <div class="flex items-end">
                        <button class="btn-success mr-2">Filter</button>
                        <button class="btn-warning mr-2">Reset</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg pt-0">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 mb-0">
                <thead
                    class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
                >
                    <tr>
                        <th scope="col" class="px-6 py-3">Nr.</th>
                        <th scope="col" class="px-6 py-3">Name</th>
                        <th scope="col" class="px-6 py-3">Provider</th>
                        <th scope="col" class="px-6 py-3">Departure Country</th>
                        <th scope="col" class="px-6 py-3">Departure City</th>
                        <th scope="col" class="px-6 py-3">Arrival Country</th>
                        <th scope="col" class="px-6 py-3">Arrival City</th>
                        <th scope="col" class="px-6 py-3">Rotations</th>
                        <th scope="col" class="px-6 py-3">Start Date</th>
                        <th scope="col" class="px-6 py-3">End Date</th>
                        <th scope="col" class="px-6 py-3">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-6 py-4">1</td>
                        <th
                            scope="row"
                            class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                        >
                            Flight No. 2341 BUC-ANT
                        </th>
                        <td class="px-6 py-4">Christian</td>
                        <td class="px-6 py-4">Romania</td>
                        <td class="px-6 py-4">Iasi</td>
                        <td class="px-6 py-4">Turkey</td>
                        <td class="px-6 py-4">Antalya</td>
                        <td class="px-6 py-4">16</td>
                        <td class="px-6 py-4">12.04.2022</td>
                        <td class="px-6 py-4">22.04.2022</td>
                        <td class="px-6 py-4">
                            <a
                                href="#"
                                class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                >View</a
                            >
                            |
                            <a
                                href="#"
                                class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                >Edit</a
                            >
                        </td>
                    </tr>
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-6 py-4">1</td>
                        <th
                            scope="row"
                            class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                        >
                            Flight No. 2341 BUC-ANT
                        </th>
                        <td class="px-6 py-4">Christian</td>
                        <td class="px-6 py-4">Romania</td>
                        <td class="px-6 py-4">Iasi</td>
                        <td class="px-6 py-4">Turkey</td>
                        <td class="px-6 py-4">Antalya</td>
                        <td class="px-6 py-4">16</td>
                        <td class="px-6 py-4">12.04.2022</td>
                        <td class="px-6 py-4">22.04.2022</td>
                        <td class="px-6 py-4">
                            <a
                                href="#"
                                class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                >View</a
                            >
                            |
                            <a
                                href="#"
                                class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                >Edit</a
                            >
                        </td>
                    </tr>
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-6 py-4">1</td>
                        <th
                            scope="row"
                            class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                        >
                            Flight No. 2341 BUC-ANT
                        </th>
                        <td class="px-6 py-4">Christian</td>
                        <td class="px-6 py-4">Romania</td>
                        <td class="px-6 py-4">Iasi</td>
                        <td class="px-6 py-4">Turkey</td>
                        <td class="px-6 py-4">Antalya</td>
                        <td class="px-6 py-4">16</td>
                        <td class="px-6 py-4">12.04.2022</td>
                        <td class="px-6 py-4">22.04.2022</td>
                        <td class="px-6 py-4">
                            <a
                                href="#"
                                class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                >View</a
                            >
                            |
                            <a
                                href="#"
                                class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                >Edit</a
                            >
                        </td>
                    </tr>
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-6 py-4">1</td>
                        <th
                            scope="row"
                            class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                        >
                            Flight No. 2341 BUC-ANT
                        </th>
                        <td class="px-6 py-4">Christian</td>
                        <td class="px-6 py-4">Romania</td>
                        <td class="px-6 py-4">Iasi</td>
                        <td class="px-6 py-4">Turkey</td>
                        <td class="px-6 py-4">Antalya</td>
                        <td class="px-6 py-4">16</td>
                        <td class="px-6 py-4">12.04.2022</td>
                        <td class="px-6 py-4">22.04.2022</td>
                        <td class="px-6 py-4">
                            <a
                                href="#"
                                class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                >View</a
                            >
                            |
                            <a
                                href="#"
                                class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                >Edit</a
                            >
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
