<script setup lang="ts">
import { usePackageStore } from '@/stores/package';
const store = usePackageStore();
</script>
<template>
    <div>
        <div>
            <div class="text-xl underline underline-offset-2 my-4">Transfer</div>
            <div class="flex mb-4">
                <div class="flex items-center h-8">
                    <input
                        id="addTransfer"
                        aria-describedby="addTransfer"
                        type="checkbox"
                        name="addTransfer"
                        v-model="store.package.services.transfer.withTransfer"
                        class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                    />
                </div>
                <div class="ml-2">
                    <label
                        for="addTransfer"
                        class="font-medium text-gray-900 dark:text-gray-300 cursor-pointer"
                        >Add Transfer to these packages</label
                    >
                    <p
                        id="addtransferLabel"
                        class="text-xs font-normal text-gray-500 dark:text-gray-300"
                    >
                        Customize your package with a transfer option.
                    </p>
                </div>
            </div>
            <div v-show="store.package.services.transfer.withTransfer == true">
                <div>
                    <div>
                        <label for="transferName" class="block text-gray-700">Transfer Name</label>
                        <input
                            type="text"
                            name="transferName"
                            id="transferName"
                            autocomplete="off"
                            v-model="store.package.services.transfer.transferDetails.transferName"
                            placeholder="..."
                            class="mt-1 form-control w-128"
                        />
                    </div>
                    <div class="flex mt-4">
                        <div class="mr-4">
                            <label for="transferGrossPrice" class="block text-gray-700"
                                >Gross Price</label
                            >
                            <input
                                type="text"
                                name="transferGrossPrice"
                                id="transferGrossPrice"
                                autocomplete="off"
                                v-model.number="
                                    store.package.services.transfer.transferDetails
                                        .transferGrossPrice
                                "
                                placeholder="$$"
                                class="mt-1 form-control"
                            />
                        </div>
                        <div class="mr-4">
                            <label for="transferNetPrice" class="block text-gray-700"
                                >Net Price</label
                            >
                            <input
                                type="text"
                                name="transferNetPrice"
                                id="transferNetPrice"
                                autocomplete="off"
                                v-model.number="
                                    store.package.services.transfer.transferDetails.transferNetPrice
                                "
                                placeholder="$$"
                                class="mt-1 form-control"
                            />
                        </div>
                        <div class="mr-4">
                            <label for="transferCommissions" class="block text-gray-700"
                                >Commissions</label
                            >
                            <input
                                type="text"
                                name="transferCommissions"
                                id="transferCommissions"
                                autocomplete="off"
                                v-model.number="
                                    store.package.services.transfer.transferDetails
                                        .transferCommissions
                                "
                                placeholder="$$"
                                class="mt-1 form-control"
                            />
                        </div>
                        <div class="mr-4">
                            <label for="transferCommissionType" class="block text-gray-700"
                                >Commission Type</label
                            >
                            <select
                                id="transferCommissionType"
                                name="transferCommissionType"
                                class="form-select w-48"
                                v-model="
                                    store.package.services.transfer.transferDetails
                                        .transferCommissionsType
                                "
                            >
                                <option selected value="flat">Flat</option>
                                <option value="percent">Percent</option>
                            </select>
                        </div>
                        <div class="mr-4">
                            <label for="transferCalculationType" class="block text-gray-700"
                                >Calculation</label
                            >
                            <select
                                id="transferCalculationType"
                                name="transferCalculationType"
                                class="form-select w-48"
                                v-model="
                                    store.package.services.transfer.transferDetails
                                        .transferCalculationType
                                "
                            >
                                <option selected value="perPax">per PAX</option>
                                <option value="perService">per Service</option>
                            </select>
                        </div>
                        <div
                            v-show="
                                store.package.services.transfer.transferDetails
                                    .transferCalculationType === 'perPax'
                            "
                            class="flex mt-6"
                        >
                            <div class="flex items-center h-5">
                                <input
                                    id="helper-checkbox"
                                    aria-describedby="helper-checkbox-text"
                                    type="checkbox"
                                    v-model="
                                        store.package.services.transfer.transferDetails
                                            .transferCustomChildPrices
                                    "
                                    value=""
                                    class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                            </div>
                            <div class="ml-2 text-sm">
                                <label class="font-medium text-gray-900 dark:text-gray-300"
                                    >Set custom child prices</label
                                >
                                <p class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                    Define rules based on child ages
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        v-show="
                            store.package.services.transfer.transferDetails
                                .transferCalculationType === 'perPax' &&
                            store.package.services.transfer.transferDetails
                                .transferCustomChildPrices
                        "
                    >
                        <div>
                            <div class="mt-4">
                                <b>Customize your prices for children</b>
                            </div>
                            <div
                                v-for="(rule, index) in store.package.services.transfer
                                    .transferDetails.transferCustomChildRules"
                                :key="index"
                                class="flex"
                            >
                                <div class="flex mt-4">
                                    <div class="mr-4">
                                        <label class="block text-gray-700">Age from</label
                                        ><select
                                            class="form-select w-24"
                                            v-model="
                                                store.package.services.transfer.transferDetails
                                                    .transferCustomChildRules[index].childAgeFrom
                                            "
                                        >
                                            <option v-for="n in 18" :value="n - 1" :key="n">
                                                {{ n - 1 }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="mr-4">
                                        <label class="block text-gray-700">Age to</label
                                        ><select
                                            class="form-select w-24"
                                            v-model="
                                                store.package.services.transfer.transferDetails
                                                    .transferCustomChildRules[index].childAgeTo
                                            "
                                        >
                                            <option v-for="n in 18" :value="n" :key="n">
                                                {{ n }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="mr-4">
                                        <label class="block text-gray-700">Gross Price</label
                                        ><input
                                            type="text"
                                            autocomplete="off"
                                            placeholder=""
                                            class="mt-1 form-control"
                                            v-model.number="
                                                store.package.services.transfer.transferDetails
                                                    .transferCustomChildRules[index].grossPrice
                                            "
                                        />
                                    </div>
                                    <div class="mr-4">
                                        <label class="block text-gray-700">Net Price</label
                                        ><input
                                            type="text"
                                            autocomplete="off"
                                            placeholder=""
                                            class="mt-1 form-control"
                                            v-model.number="
                                                store.package.services.transfer.transferDetails
                                                    .transferCustomChildRules[index].netPrice
                                            "
                                        />
                                    </div>
                                    <div class="mr-4">
                                        <label class="block text-gray-700">Commissions</label
                                        ><input
                                            type="text"
                                            autocomplete="off"
                                            placeholder=""
                                            class="mt-1 form-control"
                                            v-model.number="
                                                store.package.services.transfer.transferDetails
                                                    .transferCustomChildRules[index].commissions
                                            "
                                        />
                                    </div>
                                    <div class="mr-4">
                                        <label class="block text-gray-700">Commission Type</label
                                        ><select
                                            class="form-select w-48"
                                            v-model="
                                                store.package.services.transfer.transferDetails
                                                    .transferCustomChildRules[index].commissionsType
                                            "
                                        >
                                            <option value="flat">Flat</option>
                                            <option value="percent">Percent</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="flex mt-4">
                                    <div class="mr-4">
                                        <label class="block text-gray-700">&nbsp;</label>
                                        <button
                                            class="btn-primary btn-sm"
                                            @click="
                                                store.package.services.transfer.transferDetails.transferCustomChildRules.push(
                                                    {
                                                        childAgeFrom: 0,
                                                        childAgeTo: 1,
                                                        grossPrice: 0,
                                                        netPrice: 0,
                                                        commissions: 0,
                                                        commissionsType: 'flat',
                                                    }
                                                )
                                            "
                                        >
                                            Add New Rule
                                        </button>
                                    </div>
                                    <div class="mr-4">
                                        <label class="block text-gray-700">&nbsp;</label>
                                        <button
                                            class="btn-danger btn-sm"
                                            v-show="
                                                store.package.services.transfer.transferDetails
                                                    .transferCustomChildRules.length > 1
                                            "
                                            @click="
                                                store.package.services.transfer.transferDetails.transferCustomChildRules.splice(
                                                    index,
                                                    1
                                                )
                                            "
                                        >
                                            Delete Rule
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <label
                        for="message"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                        >Transfer Description</label
                    >
                    <textarea
                        id="message"
                        rows="4"
                        v-model="
                            store.package.services.transfer.transferDetails.transferDescription
                        "
                        class="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Your Description..."
                    ></textarea>
                </div>
            </div>
        </div>
        <div>
            <div class="text-xl underline underline-offset-2 my-4">Extra</div>
            <div class="flex mb-4">
                <div class="flex items-center h-8">
                    <input
                        id="addExtra"
                        aria-describedby="addExtra"
                        type="checkbox"
                        v-model="store.package.services.extra.withExtra"
                        name="addExtra"
                        class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                    />
                </div>
                <div class="ml-2">
                    <label
                        for="addExtra"
                        class="font-medium text-gray-900 dark:text-gray-300 cursor-pointer"
                        >Add extra to these packages</label
                    >
                    <p
                        id="addExtraLabel"
                        class="text-xs font-normal text-gray-500 dark:text-gray-300"
                    >
                        Customize your package with a extra options.
                    </p>
                </div>
            </div>
            <div v-show="store.package.services.extra.withExtra == true">
                <div
                    v-for="(extraService, index) in store.package.services.extra.extraServices"
                    :key="index"
                >
                    <div class="mt-5">
                        <div class="flex">
                            <div class="mr-5">
                                <label :for="'extraName-'" class="block text-gray-700"
                                    >Extra Name
                                </label>
                                <input
                                    type="text"
                                    autocomplete="off"
                                    placeholder="..."
                                    v-model="
                                        store.package.services.extra.extraServices[index].extraName
                                    "
                                    class="mt-1 form-control w-128"
                                />
                            </div>
                            <div>
                                <label class="block text-gray-700">Actions</label>
                                <button
                                    class="btn-success"
                                    @click="
                                        store.package.services.extra.extraServices.push({
                                            extraName: '',
                                            extraGrossPrice: 0,
                                            extraNetPrice: 0,
                                            extraCommissions: 0,
                                            extraCommissionType: 'flat',
                                            extraCalculationType: 'perPax',
                                            extraDescription: '',
                                            extraCustomChildPrices: false,
                                            extraCustomChildRules: [],
                                        })
                                    "
                                >
                                    Add More Services
                                </button>
                                <button
                                    class="btn-danger"
                                    @click="
                                        store.package.services.extra.extraServices =
                                            store.package.services.extra.extraServices.splice(
                                                index,
                                                1
                                            )
                                    "
                                >
                                    Delete Service
                                </button>
                            </div>
                        </div>
                        <div class="flex mt-4">
                            <div class="mr-4">
                                <label class="block text-gray-700">Gross Price</label>
                                <input
                                    type="text"
                                    autocomplete="off"
                                    placeholder="$$"
                                    v-model.number="
                                        store.package.services.extra.extraServices[index]
                                            .extraGrossPrice
                                    "
                                    class="mt-1 form-control"
                                />
                            </div>
                            <div class="mr-4">
                                <label class="block text-gray-700">Net Price</label>
                                <input
                                    type="text"
                                    autocomplete="off"
                                    placeholder="$$"
                                    v-model.number="
                                        store.package.services.extra.extraServices[index]
                                            .extraNetPrice
                                    "
                                    class="mt-1 form-control"
                                />
                            </div>
                            <div class="mr-4">
                                <label class="block text-gray-700">Commissions</label>
                                <input
                                    type="text"
                                    autocomplete="off"
                                    placeholder="$$"
                                    v-model.number="
                                        store.package.services.extra.extraServices[index]
                                            .extraCommissions
                                    "
                                    class="mt-1 form-control"
                                />
                            </div>
                            <div class="mr-4">
                                <label class="block text-gray-700">Commission Type</label>
                                <select
                                    class="form-select w-48"
                                    v-model.number="
                                        store.package.services.extra.extraServices[index]
                                            .extraCommissionType
                                    "
                                >
                                    <option selected value="flat">Flat</option>
                                    <option value="percent">Percent</option>
                                </select>
                            </div>
                            <div class="mr-4">
                                <label class="block text-gray-700">Calculation</label>
                                <select
                                    v-model.number="
                                        store.package.services.extra.extraServices[index]
                                            .extraCalculationType
                                    "
                                    class="form-select w-48"
                                >
                                    <option selected value="perPax">per PAX</option>
                                    <option value="perServices">per Service</option>
                                </select>
                            </div>
                            <div
                                v-show="
                                    store.package.services.extra.extraServices[index]
                                        .extraCalculationType === 'perPax'
                                "
                                class="flex mt-6"
                            >
                                <div class="flex items-center h-5">
                                    <input
                                        id="helper-checkbox"
                                        aria-describedby="helper-checkbox-text"
                                        type="checkbox"
                                        v-model="
                                            store.package.services.extra.extraServices[index]
                                                .extraCustomChildPrices
                                        "
                                        value=""
                                        class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                </div>
                                <div class="ml-2 text-sm">
                                    <label class="font-medium text-gray-900 dark:text-gray-300"
                                        >Set custom child prices</label
                                    >
                                    <p class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                        Define rules based on child ages
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div
                            v-show="
                                store.package.services.extra.extraServices[index]
                                    .extraCalculationType === 'perPax' &&
                                store.package.services.extra.extraServices[index]
                                    .extraCustomChildPrices
                            "
                        >
                            <div>
                                <div class="mt-4">
                                    <b>Customize your prices for children</b>
                                </div>
                                <div
                                    v-for="(rule, indexRule) in store.package.services.extra
                                        .extraServices[index].extraCustomChildRules"
                                    :key="indexRule"
                                    class="flex"
                                >
                                    <div class="flex mt-4">
                                        <div class="mr-4">
                                            <label class="block text-gray-700">Age from</label
                                            ><select
                                                class="form-select w-24"
                                                v-model="
                                                    store.package.services.extra.extraServices[
                                                        index
                                                    ].extraCustomChildRules[indexRule].childAgeFrom
                                                "
                                            >
                                                <option v-for="n in 18" :value="n - 1" :key="n">
                                                    {{ n - 1 }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="mr-4">
                                            <label class="block text-gray-700">Age to</label
                                            ><select
                                                class="form-select w-24"
                                                v-model="
                                                    store.package.services.extra.extraServices[
                                                        index
                                                    ].extraCustomChildRules[indexRule].childAgeTo
                                                "
                                            >
                                                <option v-for="n in 18" :value="n" :key="n">
                                                    {{ n }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="mr-4">
                                            <label class="block text-gray-700">Gross Price</label
                                            ><input
                                                type="text"
                                                autocomplete="off"
                                                placeholder=""
                                                class="mt-1 form-control"
                                                v-model.number="
                                                    store.package.services.extra.extraServices[
                                                        index
                                                    ].extraCustomChildRules[indexRule].grossPrice
                                                "
                                            />
                                        </div>
                                        <div class="mr-4">
                                            <label class="block text-gray-700">Net Price</label
                                            ><input
                                                type="text"
                                                autocomplete="off"
                                                placeholder=""
                                                class="mt-1 form-control"
                                                v-model.number="
                                                    store.package.services.extra.extraServices[
                                                        index
                                                    ].extraCustomChildRules[indexRule].netPrice
                                                "
                                            />
                                        </div>
                                        <div class="mr-4">
                                            <label class="block text-gray-700">Commissions</label
                                            ><input
                                                type="text"
                                                autocomplete="off"
                                                placeholder=""
                                                class="mt-1 form-control"
                                                v-model.number="
                                                    store.package.services.extra.extraServices[
                                                        index
                                                    ].extraCustomChildRules[indexRule].commissions
                                                "
                                            />
                                        </div>
                                        <div class="mr-4">
                                            <label class="block text-gray-700"
                                                >Commission Type</label
                                            ><select
                                                class="form-select w-48"
                                                v-model="
                                                    store.package.services.extra.extraServices[
                                                        index
                                                    ].extraCustomChildRules[indexRule]
                                                        .commissionsType
                                                "
                                            >
                                                <option value="flat">Flat</option>
                                                <option value="percent">Percent</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="flex mt-4">
                                        <div class="mr-4">
                                            <label class="block text-gray-700">&nbsp;</label>
                                            <button
                                                class="btn-primary btn-sm"
                                                @click="
                                                    store.package.services.extra.extraServices[
                                                        index
                                                    ].extraCustomChildRules.push({
                                                        childAgeFrom: 0,
                                                        childAgeTo: 1,
                                                        grossPrice: 0,
                                                        netPrice: 0,
                                                        commissions: 0,
                                                        commissionsType: 'flat',
                                                    })
                                                "
                                            >
                                                Add New Rule
                                            </button>
                                        </div>
                                        <div class="mr-4">
                                            <label class="block text-gray-700">&nbsp;</label>
                                            <button
                                                class="btn-danger btn-sm"
                                                v-show="
                                                    store.package.services.extra.extraServices[
                                                        index
                                                    ].extraCustomChildRules.length > 1
                                                "
                                                @click="
                                                    store.package.services.extra.extraServices[
                                                        index
                                                    ].extraCustomChildRules.splice(index, 1)
                                                "
                                            >
                                                Delete Rule
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4">
                        <label
                            :for="'extraDescription-'"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                            >Extra Description</label
                        >
                        <textarea
                            rows="4"
                            class="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Your Description..."
                            v-model.number="
                                store.package.services.extra.extraServices[index].extraDescription
                            "
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
