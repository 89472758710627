<script setup lang="ts">
import axiosApiInstance from '@/helpers/api.axios.headers';
import type { SuggestLocation } from '@/types/builder/suggestLocation.interface';
import { ref, type Ref } from 'vue';
import IconSearchLocation from './icons/IconSearchLocation.vue';

const locationResults: Ref<Array<SuggestLocation>> = ref([]);
const locationInput: Ref<string> = ref('');
const showList: Ref<boolean> = ref(false);
const locationId: Ref<number> = ref(-1);
const locationName: Ref<string> = ref('');
const alertSuggestMessage: Ref<string> = ref('');

let controller = new AbortController();
const requestTerm = (event: Event) => {
    showList.value = false;
    if ((event.target as HTMLInputElement).value.length < 3) return false;

    controller.abort();
    controller = new AbortController();
    axiosApiInstance
        .post(
            '/getGIATALocations',
            {
                term: (event.target as HTMLInputElement).value,
            },
            {
                signal: controller.signal,
            }
        )
        .then(function (response) {
            if (!response.data.status) {
                alertSuggestMessage.value = response.data.message;
                return false;
            } else {
                alertSuggestMessage.value = '';
                locationResults.value = response.data.locations;
                showList.value = true;
            }
        })
        .catch(function (error) {
            console.info(error.name);
        });
};

const setInput = (value: string, id: number) => {
    locationInput.value = locationName.value = value;
    locationId.value = id;

    alertSuggestMessage.value = '';
};

const clearInput = () => {
    locationInput.value = locationName.value = '';
    locationId.value = -1;
    locationResults.value = [];

    alertSuggestMessage.value = '';
};

defineExpose({
    clearInput,
});

defineEmits(['search-hotels']);
</script>
<template>
    <div>
        <div
            v-show="alertSuggestMessage != ''"
            class="p-4 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800"
            role="alert"
        >
            <svg
                aria-hidden="true"
                class="flex-shrink-0 inline w-5 h-5 mr-3"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clip-rule="evenodd"
                ></path>
            </svg>
            {{ alertSuggestMessage }}
        </div>
        <label for="default-search" class="mb-2 text-sm text-gray-900 sr-only dark:text-gray-300"
            >Search</label
        >
        <div class="relative">
            <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                    class="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                </svg>
            </div>
            <input
                type="search"
                id="default-search"
                @input="requestTerm"
                @click="showList = true"
                v-model="locationInput"
                class="block p-2 pl-10 w-full text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search City..."
            />
            <div
                v-show="locationResults.length != 0 && showList"
                class="absolute z-10 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            >
                <button
                    v-for="(location, index) in locationResults"
                    :key="index"
                    @click="
                        setInput(location.locationName, location.locationId), (showList = false)
                    "
                    type="button"
                    class="w-full px-4 py-2 font-medium text-left border-b border-gray-200 cursor-pointer hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white"
                >
                    {{ location.locationName }} , {{ location.countryName }}
                </button>
            </div>
        </div>
        <br />
        <button
            type="button"
            @click="$emit('search-hotels', locationId, locationName)"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
            <IconSearchLocation class="mr-2 -ml-1 w-5 h-5 fill-white" />
            Select Destination To Choose Hotels From
        </button>
    </div>
</template>
