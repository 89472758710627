<script setup lang="ts">
import { onMounted, reactive, ref, toRaw, type Ref } from 'vue';
import Spinner from '@/components/animations/TableSpinner.vue';
import axiosApiInstance from '@/helpers/api.axios.headers';
import FullscreenLoader from '@/components/animations/FullscreenLoader.vue';

interface Package {
    id: string;
    package_extra_service_ids: string;
    outbound: string;
    inbound: string;
    from_date: string;
    to_date: string;
    stay: string;
    hotel: string;
    created_at: string;
    updated_at: string;
    created_by_name: string;
    edited_by_name: string;
    extra_data: ExtraData;
}

interface ExtraData {
    hotels: Hotel[];
    services: Service[];
}

interface Hotel {
    hotel_id: string;
    hotel_name: string;
}

interface Service {
    id: string;
    name: string;
}


const items: Ref<Array<any>> = ref([]);
const loader: Ref<boolean> = ref(true);
const filteredItems: Ref<Array<Package>> = ref([]);
const fullscreenLoader = ref(false);

const initialFiltersState: any = {
    name: '',
    status: '',
};

const filters = reactive({
    ...initialFiltersState,
});

onMounted(() => {
    getAllAllocation();
});

const getAllAllocation = () => {
    axiosApiInstance
        .get('/extra_service_allocation_rules/get', {

        })
        .then(function (response) {
            loader.value = false;
            if (!response.data.status) {
                alert(response.data.message);
            } else {
                filteredItems.value = items.value = response.data.allocation_rules;
            }
        })
        .catch(function (error) {
            alert(error);
        });
}

const filterResults = (reset: boolean) => {

    fullscreenLoader.value = true;

    if (reset) {
        filters.name = '';
        filters.status = '';
    }

    axiosApiInstance
        .get(`/extra_service_allocation_rules/get?inbound=${filters.inbound ? filters.inbound : ''}&outbound=${filters.outbound ? filters.outbound : ''}`, {

        })
        .then(function (response) {
            loader.value = false;
            if (!response.data.status) {
                alert(response.data.message);
            } else {
                filteredItems.value = items.value = response.data.allocation_rules;
            }
            fullscreenLoader.value = false;
        })
        .catch(function (error) {
            alert(error);
            fullscreenLoader.value = false;
        });


};

const deleteAllocation = (id: string) => {

    fullscreenLoader.value = true;

    axiosApiInstance
        .get(`/extra_service_allocation_rules/delete/${id}`, {

        })
        .then(function (response) {

            alert(response.data.message);
            fullscreenLoader.value = false;
            getAllAllocation();
        })
        .catch(function (error) {
            alert(error);
            fullscreenLoader.value = false;
        });

}

</script>
<template>
    <FullscreenLoader v-show="fullscreenLoader" />
    <div>
        <div class="border-2 border-gray-400 rounded p-5">
            <div class="flex justify-between">
                <div class="text-xl">{{ items.length }} Results</div>
            </div>
            <hr class="border-gray-400 my-2" />
            <div class="flex justify-between">
                <div class="flex">
                    <div class="col-span-6 sm:col-span-3 mr-2">
                        <label for="outbound" class="block text-gray-700">Outbound</label>
                        <input type="text" name="outbound" id="outbound" autocomplete="off"
                            v-model="filters.outbound" placeholder="Search by Outbound" class="form-control mt-1" />
                    </div>
                    <div class="col-span-6 sm:col-span-3 mr-2">
                        <label for="inbound" class="block text-gray-700">Inbound</label>
                        <input type="text" name="inbound" id="inbound" autocomplete="off"
                            v-model="filters.inbound" placeholder="Search by Inbound" class="form-control mt-1" />
                    </div>
                </div>
                <div class="flex items-end">
                    <router-link
                            class="btn-primary mr-2"
                            style="color: white !important;"
                            to="/Allocation"
                        >
                        Add
                    </router-link>
                    <button class="btn-success mr-2" @click="filterResults(false)">
                        Filter
                    </button>
                    <button class="btn-warning mr-2" @click="filterResults(true)">Reset</button>
                </div>
            </div>
        </div>
    </div>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg pt-0">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 mb-0">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3">Nr.</th>
                    <th scope="col" class="px-6 py-3">Name</th>
                    <th scope="col" class="px-6 py-3">Extras</th>
                    <th scope="col" class="px-6 py-3">Hotels</th>
                    <th scope="col" class="px-6 py-3">Created by</th>
                    <th scope="col" class="px-6 py-3">Edited by</th>
                    <th scope="col" class="px-6 py-3">Created at</th>
                    <th scope="col" class="px-6 py-3">Edited at</th>
                    <th scope="col" class="px-6 py-3">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-show="loader">
                        <td
                            colspan="9"
                            class="bg-white border-b dark:bg-gray-900 dark:border-gray-700 p-2"
                        >
                            <div class="flex items-center justify-center">
                                <Spinner />
                                <div class="text-lg pl-2">Loading...</div>
                            </div>
                        </td>
                    </tr>
                <tr v-if="filteredItems.length === 0 && !loader">
                    <td colspan="99" class="text-center">
                        <div class="font-bold dark:text-white text-xl uppercase my-2">
                            No Allocations found.
                        </div>
                    </td>
                </tr>
                <tr v-for="(item, index) in filteredItems" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">

                    <td class="px-6 py-4">
                        {{ index + 1 }}
                    </td>
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        <div class="overflow-hidden truncate w-48" :title="item.outbound">
                            Outbound: {{ item.outbound }}
                        </div>
                        <div class="overflow-hidden truncate w-48" :title="item.inbound">
                            Inbound: {{ item.inbound }}
                        </div>
                    </th>
                    <td class="px-6 py-4">
                        {{ item.extra_data ? item.extra_data.services.map(services => services.name).toString() : '' }}
                    </td>
                    <!-- <td class="px-6 py-4">
                        {{ item.extra_data ? item.extra_data.hotels.map(hotel => hotel.hotel_name).toString() : '' }}
                    </td> -->
                    <td class="px-6 py-4">
                        {{ item.extra_data && item.extra_data.hotels.length ? item.extra_data.hotels.map(hotel => hotel.hotel_name).join(', ') : 'All hotels' }}
                    </td>

                    <td class="px-6 py-4">
                        {{ item.created_by_name }}
                    </td>
                    <td class="px-6 py-4">
                        {{ item.edited_by_name }}
                    </td>
                    <td class="px-6 py-4">
                        {{ item.created_at }}
                    </td>
                    <td class="px-6 py-4">
                        {{ item.updated_at }}
                    </td>
                    <td class="px-6 py-4">
                        <router-link
                            class="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
                            :to="{
                                name: 'Allocation Add',
                                params: {
                                    id: item.id
                                },
                            }"
                        >
                            Edit
                        </router-link>
                        |
                        <a type="button" href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline" @click="deleteAllocation(item.id)">Delete</a>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>
</template>