import { defineComponent, h } from 'vue';
import type { PropType } from 'vue';
import { Pie } from 'vue-chartjs';
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale,
    type ChartData,
    type DefaultDataPoint,
} from 'chart.js';
import type { Plugin } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default defineComponent({
    name: 'PieChart',
    components: {
        Pie,
    },
    props: {
        chartId: {
            type: String,
            default: 'pie-chart',
        },
        width: {
            type: Number,
            default: 400,
        },
        height: {
            type: Number,
            default: 400,
        },
        cssClasses: {
            default: '',
            type: String,
        },
        styles: {
            type: Object as PropType<Partial<CSSStyleDeclaration>>,
        },
        plugins: {
            type: Array as PropType<Plugin<'pie'>[]>,
            default: () => [],
        },
        chartData: {
            type: Object as PropType<ChartData<'pie', DefaultDataPoint<'pie'>, unknown>>,
            required: true,
        },
    },
    setup(props) {
        const chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
        };

        return () =>
            h(Pie, {
                chartData: props.chartData,
                chartOptions,
                chartId: props.chartId,
                width: props.width,
                height: props.height,
                cssClasses: props.cssClasses,
                styles: props.styles,
                plugins: props.plugins,
            });
    },
});
