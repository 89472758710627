<script setup lang="ts">
import Alert from '@/components/AlertBox.vue';
import { type Ref, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();

const alertMessage: Ref<string> = ref('');
const alertType: Ref<string> = ref('');
onMounted(() => {
    alertType.value = 'alert-danger';
    alertMessage.value =
        'It looks like you`ve reached a URL that doesn`t exist. Please use the navigation on the left to find your way back!';
});
</script>
<template>
    <div>
        <div>
            <Alert
                v-show="alertMessage != ''"
                :alertMessage="alertMessage"
                :alertType="alertType"
            />
        </div>
        <div>
            <button
                @click="router.push('/')"
                class="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            >
                To Dashboard
            </button>
        </div>
    </div>
</template>
