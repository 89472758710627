import { defineStore } from 'pinia';
import type { PackageInterface } from '@/types/builder/package.interface';

export const usePackageStore = defineStore('package', {
    state: (): PackageInterface => {
        return {
            package: {
                generalInfo: {
                    hotelNameInPackageRuleName: true,
                    nightsInPackageRuleName: false,
                    transportTypeInPackageRuleName: true,
                    hotelPromotionInPackageRuleName: true,
                    packageStatus: 'disabled',
                    stopBuy: false,
                    minOffset: 0,
                    maxOffset: 0,
                    minRotation: 0,
                    maxRotation: 0,
                    period: [],
                },
                transport: {
                    transportName: '',
                    transportType: '',
                    transportId: '',
                    dates: [
                        {
                            departure: null,
                            lessFive : 0,
                            stopSale: 0,
                            returns: [],
                        },
                    ],
                },
                hotels: {
                    providers: [],
                    tourOperators: [],
                    selectedHotels: [],
                    recommendedHotels: [],
                    hotelsWithLocations: {},
                },
                services: {
                    transfer: {
                        withTransfer: false,
                        transferDetails: {
                            transferName: '',
                            transferGrossPrice: 0,
                            transferNetPrice: 0,
                            transferCommissions: 0,
                            transferCommissionsType: 'flat',
                            transferCalculationType: 'perPax',
                            transferDescription: '',
                            transferCustomChildPrices: false,
                            transferCustomChildRules: [
                                {
                                    childAgeFrom: 0,
                                    childAgeTo: 1,
                                    grossPrice: 0,
                                    netPrice: 0,
                                    commissions: 0,
                                    commissionsType: 'flat',
                                },
                            ],
                        },
                    },
                    extra: {
                        withExtra: false,
                        extraServices: [
                            {
                                extraName: '',
                                extraGrossPrice: 0,
                                extraNetPrice: 0,
                                extraCommissions: 0,
                                extraCommissionType: 'flat',
                                extraCalculationType: 'perPax',
                                extraDescription: '',
                                extraCustomChildPrices: false,
                                extraCustomChildRules: [
                                    {
                                        childAgeFrom: 0,
                                        childAgeTo: 1,
                                        grossPrice: 0,
                                        netPrice: 0,
                                        commissions: 0,
                                        commissionsType: 'flat',
                                    },
                                ],
                            },
                        ],
                    },
                },
                commissions: {
                    hotelCalculationLogic: [],
                    transportationCalculationLogic: [],
                },
                cxlRules: {
                    cxlType: 'auto',
                    cxl: [],
                },
                createdBy: {
                    id: null,
                    username: null,
                },
                editedBy: {
                    id: null,
                    username: null,
                },
            },
        };
    },
    actions: {},
    getters: {
        getPackagePeriod: (state) => state.package.generalInfo.period,
    },
});
