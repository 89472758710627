<script setup lang="ts">
import axiosApiInstance from '@/helpers/api.axios.headers';
import { onMounted, ref, type Ref } from 'vue';

import GeneralInfo from '@/components/builder/GeneralInfoTab.vue';
import Transports from '@/components/builder/TransportsTab.vue';
import Hotels from '@/components/builder/HotelsTab.vue';
import Services from '@/components/builder/ServicesTab.vue';
import Commissions from '@/components/builder/CommissionsTab.vue';
import CxlRules from '@/components/builder/CxlRulesTab.vue';
import IconChevronCircleLeft from '@/components/icons/IconChevronCircleLeft.vue';
import IconChevronCircleRight from '@/components/icons/IconChevronCircleRight.vue';
import Alert from '@/components/AlertBox.vue';
import { isNumeric } from '@/helpers/validators/formValidators';
import { useRoute } from 'vue-router';
import router from '@/router';
import { useUserStore } from '@/stores/user';
import FullscreenLoader from '@/components/animations/FullscreenLoader.vue';
import type { BuildPackage } from '@/types/builder/buildPackage.interface';
import { usePackageStore } from '@/stores/package';
import SummaryTab from '@/components/builder/SummaryTab.vue';

const fullscreenLoader = ref(false);

const alertMessage: Ref<string> = ref('');
const alertType: Ref<string> = ref('');
const isGroup: Ref<string> = ref('');

let tabs: BuildPackage;

const route = useRoute();

const userStore = useUserStore();
const packageStore = usePackageStore();
packageStore.$reset();

onMounted(() => {
    const tabElements = [
        {
            id: 'general',
            triggerEl: document.querySelector('#general-tab'),
            targetEl: document.querySelector('#general'),
        },
        {
            id: 'transport',
            triggerEl: document.querySelector('#transport-tab'),
            targetEl: document.querySelector('#transport'),
        },
        {
            id: 'hotel',
            triggerEl: document.querySelector('#hotel-tab'),
            targetEl: document.querySelector('#hotel'),
        },
        {
            id: 'services',
            triggerEl: document.querySelector('#services-tab'),
            targetEl: document.querySelector('#services'),
        },
        {
            id: 'commissions',
            triggerEl: document.querySelector('#commissions-tab'),
            targetEl: document.querySelector('#commissions'),
        },
        {
            id: 'cxlrules',
            triggerEl: document.querySelector('#cxlrules-tab'),
            targetEl: document.querySelector('#cxlrules'),
        },
        {
            id: 'summary',
            triggerEl: document.querySelector('#summary-tab'),
            targetEl: document.querySelector('#summary'),
        },
    ];

    // options with default values
    const options = {
        defaultTabId: 'settings',
        activeClasses:
            'text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-400 border-blue-600 dark:border-blue-500',
        inactiveClasses:
            'text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300',
    };

    // @ts-ignore: Unreachable code error
    tabs = new Tabs(tabElements, options);

    if (route.params.isGroup === 'false') {
        switchToTab('hotel', 'services', 'prev');
    } else if (route.params.id != '' && route.params.id != undefined) {
        switchToTab('transport', 'hotel', 'prev');
    }

    if (route.params.id != '' && route.params.id != undefined) {
        fullscreenLoader.value = true;

        isGroup.value = String(route.params.isGroup);

        axiosApiInstance
            .get(`/viewPackage/?id=${route.params.id}&isGroup=${route.params.isGroup}`)
            .then(function (response) {
                fullscreenLoader.value = false;
                if (!response.data.status) {
                    alert(response.data.message);
                } else {
                    const packageDetails = response.data.packageDetails;

                    // console.log('edit=>', packageDetails.recommended_hotels);

                    packageStore.package.generalInfo = packageDetails.general_info;
                    packageStore.package.transport = packageDetails.transport_details;
                    packageStore.package.hotels = {
                        providers: packageDetails.hotel_providers,
                        tourOperators: packageDetails.hotel_tour_operators,
                        selectedHotels: packageDetails.hotel_ids,
                        recommendedHotels: packageDetails.recommended_hotels != null ? packageDetails.recommended_hotels.split("_") : [],
                        hotelsWithLocations: packageDetails.locations_with_hotels,
                    };
                    packageStore.package.services = {
                        transfer: packageDetails.transfer_details,
                        extra: {
                            withExtra: packageDetails.with_extra == 0 ? false : true,
                            extraServices: packageDetails.extra_services,
                        },
                    };
                    packageStore.package.commissions = packageDetails.commision_details;
                    packageStore.package.cxlRules = packageDetails.cxl_rules;
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }
});

const switchToTab = (tabNameFrom: string, tabNameTo: string, type: string): void | boolean => {
    if (type === 'next') {
        if (!validateTab(tabNameFrom)) {
            window.scrollTo(0, 0);
            return false;
        }
    }

    tabs.show(tabNameTo);
};

const validateTab = (tabNameFrom: string): boolean => {
    alertType.value = 'alert-danger';
    alertMessage.value = '';
    let exitFunction = false;

    switch (tabNameFrom) {
        case 'general':
            if (
                packageStore.package.generalInfo.hotelNameInPackageRuleName === false &&
                packageStore.package.generalInfo.nightsInPackageRuleName === false &&
                packageStore.package.generalInfo.transportTypeInPackageRuleName === false &&
                packageStore.package.generalInfo.hotelPromotionInPackageRuleName === false
            ) {
                alertMessage.value = 'Atleast one Package Rule Name option must be checked!';
                return false;
            }
            if (packageStore.package.generalInfo.packageStatus === '') {
                alertMessage.value = 'Please select Package Status!';
                return false;
            }
            if (
                packageStore.package.generalInfo.period == null ||
                packageStore.package.generalInfo.period.length == 0
            ) {
                alertMessage.value = 'Please select Package Period Range!';
                return false;
            }

            break;
        case 'transport': {
            if (packageStore.package.transport.transportType === '') {
                alertMessage.value = 'Please select Transport Type!';
                return false;
            }
            if (packageStore.package.transport.transportId === '') {
                alertMessage.value = 'Please select a Transport!';
                return false;
            }

            if (
                packageStore.package.transport.dates.length === 0 ||
                Object.keys(packageStore.package.transport.dates).length === 0
            ) {
                alertMessage.value =
                    'You must have atleast one Departure date with Returns selected!';
                return false;
            }

            packageStore.package.transport.dates.forEach((dateObject) => {
                if (dateObject.returns.length === 0) {
                    alertMessage.value =
                        'Please select atleast one Return Date for the Selected Departure!';
                    exitFunction = true;
                }
            });

            break;
        }

        case 'hotel':
            if ((packageStore.package.hotels.providers as Array<number>).length === 0) {
                alertMessage.value = 'Please select atleast one Hotel Provider!';
                return false;
            }
            if ((packageStore.package.hotels.selectedHotels as Array<number>).length === 0) {
                alertMessage.value = 'Please select atleast one Hotel!';
                return false;
            }

            break;
        case 'services':
            if (packageStore.package.services.transfer.withTransfer) {
                if (packageStore.package.services.transfer.transferDetails.transferName === '') {
                    alertMessage.value = 'Please add Transfer Name!';
                    return false;
                }
                if (packageStore.package.services.transfer.transferDetails.transferGrossPrice < 0) {
                    alertMessage.value = 'Please add a positive Transfer Gross Price!';
                    return false;
                }
                if (
                    isNumeric(
                        packageStore.package.services.transfer.transferDetails.transferGrossPrice
                    )
                ) {
                    alertMessage.value = 'Only numeric values accepted in Transfer Gross Price!';
                    return false;
                }
                // if ( (packageStore.package.services.transfer as any).transferDetails.transferNetPrice === 0 ) {
                //     alertMessage.value = 'Please add Transfer Net Price!';
                //     return false;
                // }
                if (
                    isNumeric(
                        packageStore.package.services.transfer.transferDetails.transferNetPrice
                    )
                ) {
                    alertMessage.value = 'Only numeric values accepted in Transfer Net Price!';
                    return false;
                }
                // if (
                //     packageStore.package.services.transfer.transferDetails.transferCommissions === 0
                // ) {
                //     alertMessage.value = 'Please add Transfer Commission!';
                //     return false;
                // }
                if (
                    isNumeric(
                        packageStore.package.services.transfer.transferDetails.transferCommissions
                    )
                ) {
                    alertMessage.value = 'Only numeric values accepted in Transfer Commission!';
                    return false;
                }
                if (
                    packageStore.package.services.transfer.transferDetails
                        .transferCommissionsType === ''
                ) {
                    alertMessage.value = 'Please Select Transfer Commission Type!';
                    return false;
                }
                if (
                    packageStore.package.services.transfer.transferDetails
                        .transferCalculationType === ''
                ) {
                    alertMessage.value = 'Please Select Transfer Calculation Type!';
                    return false;
                }
            }

            if (packageStore.package.services.extra.withExtra) {
                packageStore.package.services.extra.extraServices.forEach((extra) => {
                    console.log(extra);

                    if (extra.extraName === '') {
                        alertMessage.value = 'Please add Extra Name!';
                        exitFunction = true;
                    }
                    // if (extra.extraGrossPrice === 0) {
                    //     alertMessage.value = 'Please add Extra Gross Price!';
                    //     exitFunction = true;
                    // }
                    if (isNumeric(extra.extraGrossPrice)) {
                        alertMessage.value = 'Only numeric values accepted in Extra Gross Price!';
                        exitFunction = true;
                    }
                    // if ( extra.extraNetPrice === 0 ) {
                    //     alertMessage.value = 'Please add Extra Net Price!';
                    //     exitFunction = true;
                    // }
                    if (isNumeric(extra.extraNetPrice)) {
                        alertMessage.value = 'Only numeric values accepted in Extra Net Price!';
                        exitFunction = true;
                    }
                    // if ( extra.extraCommissions === 0 ) {
                    //     alertMessage.value = 'Please add Extra Commission!';
                    //     exitFunction = true;
                    // }
                    if (isNumeric(extra.extraCommissions)) {
                        alertMessage.value = 'Only numeric values accepted in Extra Commission!';
                        exitFunction = true;
                    }
                    if (extra.extraCommissionType === '') {
                        alertMessage.value = 'Please Select Extra Commission Type!';
                        exitFunction = true;
                    }
                    if (extra.extraCalculationType === '') {
                        alertMessage.value = 'Please Select Extra Calculation Type!';
                        exitFunction = true;
                    }
                });
            }

            break;
        case 'commissions':
            console.log(packageStore.package.commissions.hotelCalculationLogic);

            if (packageStore.package.commissions.hotelCalculationLogic.length === 0) {
                alertMessage.value = 'Please add atleast one Hotel Calcultaion Logic Rule!';
                return false;
            }

            packageStore.package.commissions.hotelCalculationLogic.forEach(
                (hotelCalculationRuleArray) => {
                    hotelCalculationRuleArray.rule.forEach((rule) => {
                        if (isNumeric(rule.markup)) {
                            alertMessage.value = 'Please add a numeric value in Hotel Markup!';
                            exitFunction = true;
                        }
                        if (isNumeric(rule.commission) || rule.commission < 0) {
                            alertMessage.value =
                                'Please add a positive numeric value in Hotel Commission!';
                            exitFunction = true;
                        }
                        if (rule.markupType == '') {
                            alertMessage.value = 'Please Select Hotel Markup Type!';
                            exitFunction = true;
                        }
                        if (rule.calculationLogic == '') {
                            alertMessage.value = 'Please Select Hotel Calculation Logic!';
                            exitFunction = true;
                        }
                        if (rule.commissionType == '') {
                            alertMessage.value = 'Please Select Hotel Commission Type!';
                            exitFunction = true;
                        }
                    });
                }
            );

            if (packageStore.package.commissions.transportationCalculationLogic.length != 0) {
                packageStore.package.commissions.transportationCalculationLogic.forEach(
                    (transportCalculationRule) => {
                        transportCalculationRule.rule.forEach((rule: any) => {
                            if (isNumeric(rule.markup)) {
                                alertMessage.value =
                                    'Please add a numeric value in Transport Markup!';
                                exitFunction = true;
                            }
                            if (isNumeric(rule.commission) || rule.commission < 0) {
                                alertMessage.value =
                                    'Please add a positive numeric value in Transport Commission!';
                                exitFunction = true;
                            }
                            if (rule.markupType == '') {
                                alertMessage.value = 'Please Select Transport Markup Type!';
                                exitFunction = true;
                            }
                            if (rule.calculationLogic == '') {
                                alertMessage.value = 'Please Select Transport Calculation Logic!';
                                exitFunction = true;
                            }
                            if (rule.commissionType == '') {
                                alertMessage.value = 'Please Select Transport Commission Type!';
                                exitFunction = true;
                            }
                        });
                    }
                );
            }

            break;
        case 'cxlrules':
            // if (packageStore.package.cxlRules.cxlType === 'custom') {
            //     packageStore.package.cxlRules.cxl.forEach( rule => {
            //         console.log(rule);

            //     });
            // }
            break;

        default:
            break;
    }
    if (exitFunction) {
        return false;
    }

    return true;
};

const buildPackage = () => {
    fullscreenLoader.value = true;
    let action = 'add';
    let id;

    if (route.params.id != '' && route.params.id != undefined) {
        action = 'edit';
        id = route.params.id;
        isGroup.value = String(route.params.isGroup);
    }

    // we validate the last tab
    if (!validateTab('cxlrules')) {
        return false;
    }

    if (route.params.id != '' && route.params.id != undefined) {
        packageStore.package.editedBy = {
            id: userStore.userId,
            username: userStore.userName,
        };
    } else {
        packageStore.package.createdBy = {
            id: userStore.userId,
            username: userStore.userName,
        };
    }

    axiosApiInstance
        .post('/addEditDeletePackage', {
            package: packageStore.package,
            action: action,
            id: id,
            isGroup: route.params.isGroup == 'true' ? true : false,
        })
        .then(function (response) {
            fullscreenLoader.value = false;
            if (!response.data.status) {
                alert(response.data.message);
            } else {
                alert(response.data.message);
                router.push('/packages');
            }
        })
        .catch(function (error) {
            alert(error);
        });
};

const prevent = () => {
    return false;
};
</script>
<template>
    <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
        <FullscreenLoader v-show="fullscreenLoader" />
        <Transition>
            <Alert
                v-show="alertMessage != ''"
                :alertMessage="alertMessage"
                :alertType="alertType"
            />
        </Transition>
        <ul
            class="flex flex-wrap -mb-px text-sm font-medium text-center"
            id="myTab"
            data-tabs-toggle="#tabBuildPackage"
            role="tablist"
        >
            <li class="mr-2" role="presentation">
                <button
                    class="inline-block p-4 rounded-t-lg border-b-2 text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-500 border-blue-600 dark:border-blue-500"
                    id="general-tab"
                    data-tabs-target="#general"
                    type="button"
                    role="tab"
                    aria-controls="general"
                    aria-selected="true"
                    disabled
                >
                    General Info
                </button>
            </li>
            <li class="mr-2" role="presentation">
                <button
                    class="inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:border-transparent text-gray-500 dark:text-gray-400 border-gray-100 dark:border-gray-700"
                    id="transport-tab"
                    data-tabs-target="#transport"
                    type="button"
                    role="tab"
                    aria-controls="transport"
                    aria-selected="false"
                    disabled
                >
                    Transport
                </button>
            </li>
            <li class="mr-2" role="presentation">
                <button
                    class="inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:border-transparent text-gray-500 dark:text-gray-400 border-gray-100 dark:border-gray-700"
                    id="hotel-tab"
                    data-tabs-target="#hotel"
                    type="button"
                    role="tab"
                    aria-controls="hotel"
                    aria-selected="false"
                    disabled
                >
                    Hotel
                </button>
            </li>
            <li class="mr-2" role="presentation">
                <button
                    class="inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:border-transparent text-gray-500 dark:text-gray-400 border-gray-100 dark:border-gray-700"
                    id="services-tab"
                    data-tabs-target="#services"
                    type="button"
                    role="tab"
                    aria-controls="services"
                    aria-selected="false"
                    disabled
                >
                    Services
                </button>
            </li>
            <li class="mr-2" role="presentation">
                <button
                    class="inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:border-transparent text-gray-500 dark:text-gray-400 border-gray-100 dark:border-gray-700"
                    id="commissions-tab"
                    data-tabs-target="#commissions"
                    type="button"
                    role="tab"
                    aria-controls="commissions"
                    aria-selected="false"
                    disabled
                >
                    Commissions
                </button>
            </li>
            <li class="mr-2" role="presentation">
                <button
                    class="inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:border-transparent text-gray-500 dark:text-gray-400 border-gray-100 dark:border-gray-700"
                    id="cxlrules-tab"
                    data-tabs-target="#cxlrules"
                    type="button"
                    role="tab"
                    aria-controls="cxlrules"
                    aria-selected="false"
                    disabled
                >
                    CXL Rules
                </button>
            </li>
            <li class="mr-2" role="presentation">
                <button
                    class="inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:border-transparent text-gray-500 dark:text-gray-400 border-gray-100 dark:border-gray-700"
                    id="summary-tab"
                    data-tabs-target="#summary"
                    type="button"
                    role="tab"
                    aria-controls="summary"
                    aria-selected="false"
                    disabled
                >
                    Summary
                </button>
            </li>
        </ul>
    </div>
    <form id="tabBuildPackage" @submit.prevent="prevent">
        <div
            class="p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
            id="general"
            role="tabpanel"
            aria-labelledby="general-tab"
        >
            <div class="container mx-auto p-4 bg-gray-50 rounded-lg dark:bg-gray-800 mb-8">
                <GeneralInfo />
                <div class="flex justify-end mt-8">
                    <button
                        type="button"
                        class="btn-success inline-flex items-center"
                        @click="switchToTab('general', 'transport', 'next')"
                    >
                        Next Step
                        <IconChevronCircleRight class="w-5 h-5 ml-2 -mr-1 fill-white" />
                    </button>
                </div>
            </div>
        </div>
        <div
            class="hidden p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
            id="transport"
            role="tabpanel"
            aria-labelledby="transport-tab"
        >
            <div class="container mx-auto p-4 pt-0 bg-gray-50 rounded-lg dark:bg-gray-800 mb-8">
                <Transports />
                <div class="flex justify-end mt-8">
                    <button
                        type="button"
                        class="btn-warning inline-flex items-center"
                        @click="switchToTab('transport', 'general', 'prev')"
                    >
                        <IconChevronCircleLeft class="w-5 h-5 mr-4 fill-white" />
                        Previous Step
                    </button>
                    <button
                        type="button"
                        class="btn-success inline-flex items-center"
                        @click="switchToTab('transport', 'hotel', 'next')"
                    >
                        Next Step
                        <IconChevronCircleRight class="w-5 h-5 ml-2 -mr-1 fill-white" />
                    </button>
                </div>
            </div>
        </div>
        <div
            class="hidden p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
            id="hotel"
            role="tabpanel"
            aria-labelledby="hotel-tab"
        >
            <div class="container mx-auto p-4 bg-gray-50 rounded-lg dark:bg-gray-800 mb-8">
                <Hotels />
                <div class="flex justify-end mt-8">
                    <button
                        v-if="
                            isGroup == '' ||
                            (isGroup != 'true' &&
                                (route.params.id != '' || route.params.id != undefined))
                        "
                        type="button"
                        class="btn-warning inline-flex items-center"
                        @click="switchToTab('hotel', 'transport', 'prev')"
                    >
                        <IconChevronCircleLeft class="w-5 h-5 mr-4 fill-white" />
                        Previous Step
                    </button>
                    <button
                        type="button"
                        class="btn-success inline-flex items-center"
                        @click="switchToTab('hotel', 'services', 'next')"
                    >
                        Next Step
                        <IconChevronCircleRight class="w-5 h-5 ml-2 -mr-1 fill-white" />
                    </button>
                </div>
            </div>
        </div>
        <div
            class="hidden p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
            id="services"
            role="tabpanel"
            aria-labelledby="services-tab"
        >
            <div class="container mx-auto p-4 bg-gray-50 rounded-lg dark:bg-gray-800 mb-8">
                <Services />
                <div>
                    <div class="flex justify-end mt-8">
                        <button
                            v-if="
                                isGroup == '' ||
                                (isGroup == 'true' &&
                                    (route.params.id != '' || route.params.id != undefined))
                            "
                            type="button"
                            class="btn-warning inline-flex items-center"
                            @click="switchToTab('services', 'hotel', 'prev')"
                        >
                            <IconChevronCircleLeft class="w-5 h-5 mr-4 fill-white" />
                            Previous Step
                        </button>
                        <button
                            type="button"
                            class="btn-success inline-flex items-center"
                            @click="switchToTab('services', 'commissions', 'next')"
                        >
                            Next Step
                            <IconChevronCircleRight class="w-5 h-5 ml-2 -mr-1 fill-white" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="hidden p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
            id="commissions"
            role="tabpanel"
            aria-labelledby="commissions-tab"
        >
            <div class="container mx-auto p-4 bg-gray-50 rounded-lg dark:bg-gray-800 mb-8">
                <Commissions />
            </div>
            <div>
                <div class="flex justify-end mt-8">
                    <button
                        type="button"
                        class="btn-warning inline-flex items-center"
                        @click="switchToTab('commissions', 'services', 'prev')"
                    >
                        <IconChevronCircleLeft class="w-5 h-5 mr-4 fill-white" />
                        Previous Step
                    </button>
                    <button
                        type="button"
                        class="btn-success inline-flex items-center"
                        @click="switchToTab('commissions', 'cxlrules', 'next')"
                    >
                        Next Step
                        <IconChevronCircleRight class="w-5 h-5 ml-2 -mr-1 fill-white" />
                    </button>
                </div>
            </div>
        </div>
        <div
            class="hidden p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
            id="cxlrules"
            role="tabpanel"
            aria-labelledby="cxlrules-tab"
        >
            <div class="container mx-auto p-4 bg-gray-50 rounded-lg dark:bg-gray-800 mb-8">
                <CxlRules />
            </div>
            <div>
                <div class="flex justify-end mt-8">
                    <button
                        type="button"
                        class="btn-warning inline-flex items-center"
                        @click="switchToTab('cxlrules', 'commissions', 'prev')"
                    >
                        <IconChevronCircleLeft class="w-5 h-5 mr-4 fill-white" />
                        Previous Step
                    </button>
                    <button
                        type="button"
                        class="btn-success inline-flex items-center"
                        @click="switchToTab('cxlrules', 'summary', 'next')"
                    >
                        Next Step
                        <IconChevronCircleRight class="w-5 h-5 ml-2 -mr-1 fill-white" />
                    </button>
                </div>
            </div>
        </div>
        <div
            class="hidden p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
            id="summary"
            role="tabpanel"
            aria-labelledby="summary-tab"
        >
            <div class="container mx-auto p-4 bg-gray-50 rounded-lg dark:bg-gray-800 mb-8">
                <SummaryTab />
            </div>
            <div>
                <div class="flex justify-end mt-8">
                    <button
                        type="button"
                        class="btn-warning inline-flex items-center"
                        @click="switchToTab('summary', 'cxlrules', 'prev')"
                    >
                        <IconChevronCircleLeft class="w-5 h-5 mr-4 fill-white" />
                        Previous Step
                    </button>
                    <button
                        type="button"
                        class="btn-primary inline-flex items-center"
                        @click="buildPackage"
                    >
                        Create/Edit Package
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>
<style>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
