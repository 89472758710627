<script setup lang="ts">
import { useUCMHotelStore } from '@/stores/ucmHotel';
import { ref, type Ref } from 'vue';
import IconTrashBin from '@/components/icons/IconTrashBin.vue';
import draggable from 'vuedraggable';
const store = useUCMHotelStore();

const fileInput = ref<HTMLInputElement | null>(null);
const selectedFiles = ref<FileList | null>(null);
const uploadedImages = ref<Array<{ url: string, name: string }>>([]);

const handleFileChange = (event: Event) => {
    const input = event.target as HTMLInputElement;
    if (input.files) {
        selectedFiles.value = input.files;
    }
    // Display uploaded images in real-time
    const imagesArray = Array.from(input.files as FileList).map((file) => ({
        url: URL.createObjectURL(file as Blob | MediaSource),
        name: file.name as string
    }));
    uploadedImages.value = imagesArray;

}
</script>
<template>
    <div>
        <div class="flex items-center justify-center w-full">
            <label for="dropzone-file"
                class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                <div class="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                    </svg>
                    <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to
                            upload</span> or drag and drop</p>
                    <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                </div>
                <input id="dropzone-file" type="file" class="hidden" ref="fileInput" @change="handleFileChange" multiple />
            </label>
        </div>
        <div>
            <div class="mt-4">
                * Images will be displayed in the order below
                <br>
                Drag uploaded images to change the display order
            </div>
            <hr class="my-2">
            <draggable :list="uploadedImages" item-key="name" class="grid gap-6 mb-6 md:grid-cols-6">
                <template #item="{ element, index }">
                    <figure class="max-w-lg relative">
                        <img class="h-28 max-w-full rounded-lg" :src="element.url" alt="image description">
                        <figcaption class="mt-2 text-sm text-center text-gray-500 dark:text-gray-400">
                            {{ element.name }}
                        </figcaption>
                        <button
                            class="absolute top-0 right-0 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-2 py-1 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900;btn-danger"
                            title="Remove this image"
                            type="button" @click="
                                uploadedImages.splice(
                                    index,
                                    1
                                )
                            ">
                            <IconTrashBin />
                        </button>
                    </figure>
                </template>
            </draggable>
        </div>
    </div>
</template>