import axiosApiInstance from '@/helpers/api.axios.headers';
import type { RouteItem } from '@/types/builder/routeItem.interface';

export async function getTransportsService(type: string, period: Array<Date>) {
    try {
        const res = await axiosApiInstance.post('/getRoutesForService', {
            service: type,
            period: period,
        });
        if (!res.data.status) {
            alert(res.data.message);
            return false;
        }

        if (res.data.routes.length === 0) {
            alert('No routes found, please change package period dates!');
            return false;
        }

        return res.data.routes as Array<RouteItem>;
    } catch (err) {
        console.error(err);
    }
}
