<script setup lang="ts">
import { onMounted } from 'vue';

onMounted(() => {
    if (localStorage.getItem('color-theme')) {
        if (localStorage.getItem('color-theme') === 'light') {
            toggleDarkMode(null, false);
        } else {
            toggleDarkMode(null, true);
            (document.getElementById('darkmode-toggle') as HTMLInputElement).checked = true;
        }
    }
});

const toggleDarkMode = (event: Event | null, state: boolean) => {
    if (event !== null) {
        if ((event.target as HTMLElement).nodeName != 'INPUT') {
            event.stopPropagation();
            return false;
        }
    }

    if (state) {
        document.documentElement.classList.add('dark');
        localStorage.setItem('color-theme', 'dark');
    } else {
        document.documentElement.classList.remove('dark');
        localStorage.setItem('color-theme', 'light');
    }
};
</script>
<template>
    <div class="flex items-center" title="Toggle dark mode">
        <label
            @click="toggleDarkMode($event, ($event.target as HTMLInputElement).checked)"
            class="cursor-pointer"
        >
            <input id="darkmode-toggle" class="toggle-checkbox" type="checkbox" />
            <div class="toggle-slot">
                <div id="theme-toggle-light-icon" class="sun-icon-wrapper">
                    <svg class="sun-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path
                            d="M256 159.1c-53.02 0-95.1 42.98-95.1 95.1S202.1 351.1 256 351.1s95.1-42.98 95.1-95.1S309 159.1 256 159.1zM509.3 347L446.1 255.1l63.15-91.01c6.332-9.125 1.104-21.74-9.826-23.72l-109-19.7l-19.7-109c-1.975-10.93-14.59-16.16-23.72-9.824L256 65.89L164.1 2.736c-9.125-6.332-21.74-1.107-23.72 9.824L121.6 121.6L12.56 141.3C1.633 143.2-3.596 155.9 2.736 164.1L65.89 256l-63.15 91.01c-6.332 9.125-1.105 21.74 9.824 23.72l109 19.7l19.7 109c1.975 10.93 14.59 16.16 23.72 9.824L256 446.1l91.01 63.15c9.127 6.334 21.75 1.107 23.72-9.822l19.7-109l109-19.7C510.4 368.8 515.6 356.1 509.3 347zM256 383.1c-70.69 0-127.1-57.31-127.1-127.1c0-70.69 57.31-127.1 127.1-127.1s127.1 57.3 127.1 127.1C383.1 326.7 326.7 383.1 256 383.1z"
                        />
                    </svg>
                </div>
                <div class="toggle-button"></div>
                <div id="theme-toggle-dark-icon" class="moon-icon-wrapper">
                    <svg class="moon-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path
                            d="M32 256c0-123.8 100.3-224 223.8-224c11.36 0 29.7 1.668 40.9 3.746c9.616 1.777 11.75 14.63 3.279 19.44C245 86.5 211.2 144.6 211.2 207.8c0 109.7 99.71 193 208.3 172.3c9.561-1.805 16.28 9.324 10.11 16.95C387.9 448.6 324.8 480 255.8 480C132.1 480 32 379.6 32 256z"
                        />
                    </svg>
                </div>
            </div>
        </label>
    </div>
</template>

<style>
.toggle-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.toggle-slot {
    position: relative;
    height: 2.5em;
    width: 5em;
    border: 5px solid #e4e7ec;
    border-radius: 10em;
    background-color: white;
    box-shadow: 1px 1px 15px #e4e7ec;
    transition: background-color 250ms;
}

.toggle-checkbox:checked ~ .toggle-slot {
    background-color: #374151;
}

.toggle-button {
    transform: translate(2.7em, 0.2em);
    position: absolute;
    height: 1.5em;
    width: 1.5em;
    border-radius: 50%;
    background-color: #ffeccf;
    box-shadow: inset 0px 0px 0px 0.15em #ffbb52;
    transition: background-color 250ms, border-color 250ms,
        transform 500ms cubic-bezier(0.26, 2, 0.46, 0.71);
}

.toggle-checkbox:checked ~ .toggle-slot .toggle-button {
    background-color: #485367;
    box-shadow: inset 0px 0px 0px 0.75em white;
    transform: translate(0.25em, 0.2em);
}

.sun-icon {
    fill: #ffbb52;
}

.sun-icon-wrapper {
    position: absolute;
    height: 1.5em;
    width: 1.5em;
    opacity: 1;
    transform: translate(0.2em, 0.2em) rotate(15deg);
    transform-origin: 50% 50%;
    transition: opacity 150ms, transform 500ms cubic-bezier(0.26, 2, 0.46, 0.71);
}

.toggle-checkbox:checked ~ .toggle-slot .sun-icon-wrapper {
    opacity: 0;
    transform: translate(3em, 0.2em) rotate(0deg);
}

.moon-icon {
    fill: white;
}

.moon-icon-wrapper {
    position: absolute;
    height: 1.5em;
    width: 1.5em;
    opacity: 0;
    transform-origin: 50% 50%;
    transition: opacity 150ms, transform 500ms cubic-bezier(0.26, 2.5, 0.46, 0.71);
}

.toggle-checkbox:checked ~ .toggle-slot .moon-icon-wrapper {
    opacity: 1;
    transform: translate(2.75em, 0.2em) rotate(-10deg);
}
</style>
