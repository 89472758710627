<script setup lang="ts">
import { usePackageStore } from '@/stores/package';
const store = usePackageStore();
</script>

<template>
    <div>
        <div class="text-xl underline underline-offset-2 my-4">CXL Rules</div>
        <div class="flex">
            <div class="mr-4 border-r-2 pr-4">
                <div class="flex items-start">
                    <div class="flex items-center h-5">
                        <input
                            id="autoCXLRules"
                            name="status"
                            type="radio"
                            value="auto"
                            v-model="store.package.cxlRules.cxlType"
                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                        />
                    </div>
                    <div class="ml-3 text-sm">
                        <label for="autoCXLRules" class="cursor-pointer">Self Calculation</label>
                        <p class="text-gray-500">Let traviola decide Cancellation Rules</p>
                    </div>
                </div>
            </div>
            <div>
                <div class="flex items-start">
                    <div class="flex items-center h-5">
                        <input
                            id="customCXLRules"
                            name="status"
                            type="radio"
                            value="custom"
                            v-model="store.package.cxlRules.cxlType"
                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                        />
                    </div>
                    <div class="ml-3 text-sm">
                        <label for="customCXLRules" class="cursor-pointer">Custom Rules</label>
                        <p class="text-gray-500">User defined Rules</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="store.package.cxlRules.cxlType === 'custom'">
            <br />
            <div
                class="my-2 border p-4"
                v-for="(rule, index) in store.package.cxlRules.cxl"
                :key="index"
            >
                <div class="mb-2 text-sm text-gray-900 dark:text-gray-300">
                    {{ index + 1 }}. CXL Rules for Departure:
                    <b>{{ rule.departureDate }}</b>
                </div>
                <label class="inline-flex relative items-center cursor-pointer">
                    <input
                        v-model="rule.cxlRules[0].nonRefundable"
                        type="checkbox"
                        value=""
                        class="sr-only peer"
                    />
                    <div
                        class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                    ></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >Make default cxl policy non-refundable by eventually removing the free
                        interval</span
                    >
                </label>
            </div>
        </div>
    </div>
</template>
