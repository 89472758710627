<script setup lang="ts">
import IconPackage from '@/components/icons/IconPackage.vue';
import PieChart from '../assets/charts/PieChart';
import axiosApiInstance from '@/helpers/api.axios.headers';
import { type Ref, ref, toRaw } from 'vue';
import FullscreenLoader from '@/components/animations/FullscreenLoader.vue';
import type { DashboardObject, CartData } from '@/types/dashboard.interface';
import IconMapLocation from '../components/icons/IconMapLocation.vue';
import IconBed from '../components/icons/IconBed.vue';

const fullscreenLoader = ref(true);
const dashboardObject: Ref<DashboardObject> = ref({
    packages: 0,
    destinations: 0,
    selectedHotels: 0,
    destinations4Dasboard: [
        {
            locationName: '',
            nrPackages: 0,
            transportTypes: '',
        },
    ],
});

const chartData: Ref<CartData> = ref({
    labels: [],
    datasets: [
        {
            backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16', '#4f46e5'],
            data: [],
        },
    ],
});

axiosApiInstance
    .get('/getDashboardInformation')
    .then(function (response) {
        fullscreenLoader.value = false;
        if (!response.data.status) {
            alert(response.data.message);
        } else {
            dashboardObject.value = response.data;

            chartData.value = {
                labels: response.data.destinations4Dasboard.map(
                    (a: { locationName: string }) => a.locationName
                ),
                datasets: [
                    {
                        backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16', '#4f46e5'],
                        data: response.data.destinations4Dasboard.map(
                            (a: { nrPackages: number }) => a.nrPackages
                        ),
                    },
                ],
            };
        }
    })
    .catch(function (error) {
        alert(error);
    });
</script>
<template>
    <div class="p-10">
        <FullscreenLoader v-show="fullscreenLoader" />
        <div class="flex justify-between mt-8">
            <div class="w-1/3 mx-3">
                <div
                    class="p-5 bg-white border rounded flex dark:bg-gray-900 dark:text-white dark:border-gray-500"
                >
                    <div class="bg-indigo-600 p-2 w-12 rounded flex">
                        <IconPackage class="fill-white" />
                    </div>
                    <div class="ml-4">
                        <div class="text-slate-500 dark:text-white">Packages</div>
                        <div class="flex">
                            <div class="text-xl font-bold">
                                {{ dashboardObject.packages }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-1/3 mx-3">
                <div
                    class="p-5 bg-white border rounded flex dark:bg-gray-900 dark:text-white dark:border-gray-500"
                >
                    <div class="bg-indigo-600 p-2 w-12 rounded flex">
                        <IconMapLocation class="fill-white" />
                    </div>
                    <div class="ml-4">
                        <div class="text-slate-500 dark:text-white">Destinations</div>
                        <div class="flex">
                            <div class="text-xl font-bold">
                                {{ dashboardObject.destinations }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-1/3 mx-3">
                <div
                    class="p-5 bg-white border rounded flex dark:bg-gray-900 dark:text-white dark:border-gray-500"
                >
                    <div class="bg-indigo-600 p-2 w-12 rounded flex">
                        <IconBed class="fill-white" />
                    </div>
                    <div class="ml-4">
                        <div class="text-slate-500 dark:text-white">Hotels</div>
                        <div class="flex">
                            <div class="text-xl font-bold">
                                {{ dashboardObject.selectedHotels }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div class="flex justify-between mt-8 hidden">
            <div class="w-1/2 mx-3">
                <div class="overflow-x-auto relative">
                    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead
                            class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
                        >
                            <tr>
                                <th scope="col" class="py-3 px-6">Destination</th>
                                <th scope="col" class="py-3 px-6">Nr. Packages</th>
                                <th scope="col" class="py-3 px-6">Transport Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in dashboardObject.destinations4Dasboard"
                                :key="index"
                                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                            >
                                <th
                                    scope="row"
                                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                >
                                    {{ item.locationName }}
                                </th>
                                <td class="py-4 px-6">
                                    {{ item.nrPackages }}
                                </td>
                                <td class="py-4 px-6">
                                    {{
                                        item.transportTypes == 'bus_charter'
                                            ? 'Bus Charter'
                                            : 'Flight Charter'
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="w-1/2 mx-3">
                <PieChart :chart-data="toRaw(chartData)" />
            </div>
        </div>
    </div>
</template>
