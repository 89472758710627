import { defineStore } from 'pinia';
import type { ExtraInterface, Pricing } from '@/types/extra/extra.interface';

export const useExtraStore = defineStore('extra', {
    state: (): ExtraInterface =>{
        return {
            name: "",
            description: "",
            status: "onRequest",
            category: "-1",
            commission: "",
            commissionType: "",
            markup: "",
            markupType: "",
            priceIntervals: [],
            pricing: [
                {
                    dateInterval: [],
                    priceIntervals: [
                        {
                            from: '',
                            to: '',
                            criteria: [
                               
                            ]
                        }
                    ]
                }
            ]
        }
    },
    actions: {
        resetExtra() {
            this.name = "";
            this.description = "";
            this.status = "onRequest";
            this.category = "-1";
            this.commission = "";
            this.commissionType = "";
            this.markup = "";
            this.markupType = "";
            this.priceIntervals = [];
            this.pricing = [
                {
                    dateInterval: [],
                    priceIntervals: [
                        {
                            from: '',
                            to: '',
                            criteria: []
                        }
                    ]
                }
            ];
        },
        checkDateRangesOverlap(intervals: Pricing[]) {

            let status = true;
            let message = '';

            console.log();
            
            if( intervals.length > 1){
                for (let i = 0; i < intervals.length; i++) {
                    const [start1, end1] = intervals[i].dateInterval.map(date => new Date(date));
                    for (let j = i + 1; j < intervals.length; j++) {
                        const [start2, end2] = intervals[j].dateInterval.map(date => new Date(date));
                        if ((start1 <= end2) && (start2 <= end1)) {
                            message = `Error: Date ranges overlap between intervals ${i + 1} and ${j + 1}`;
                            status = false;
                            
                        }
                    }
                }
            }

            return {
                status,
                message
            };
        }
    }
})