import axios, {
    type AxiosInstance,
    type AxiosRequestConfig,
    type AxiosRequestHeaders,
} from 'axios';
import { useAuthStore } from '@/stores/auth';
import router from '@/router';

const axiosClient: AxiosInstance = axios.create({
    baseURL: String(import.meta.env.VITE_API_URL),
});

axiosClient.interceptors.request.use((config: AxiosRequestConfig<AxiosRequestHeaders>) => {
    const authStore = useAuthStore();
    config.headers!.Authorization = `Bearer ${authStore.getJwt}`;
    config.headers!.Accept = 'application/json';
    return config;
});

axiosClient.interceptors.response.use(
    function (response) {
        if (
            response.status === 200 &&
            response.data.status === false &&
            response.data.errorCode === 1007
        ) {
            router.push('/logout');
        }

        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);

export default axiosClient;
