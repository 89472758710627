<script setup lang="ts">
import { onMounted, reactive, ref, toRaw, type Ref } from 'vue';

import Datepicker from '@vuepic/vue-datepicker';
import axiosApiInstance from '@/helpers/api.axios.headers';
import type { FilterInputsCharterRoutes, RouteItem } from '@/types/builder/routeItem.interface';
import Spinner from '@/components/animations/TableSpinner.vue';

const items: Ref<Array<RouteItem>> = ref([]);
const filteredItems: Ref<Array<RouteItem>> = ref([]);
const loader: Ref<boolean> = ref(true);

const initialFiltersState: FilterInputsCharterRoutes = {
    departureCountry: '',
    departureCity: '',
    destinationCountry: '',
    destinationCity: '',
    datesInterval: ['', ''],
};

const filters = reactive({
    ...initialFiltersState,
});

onMounted(() => {
    axiosApiInstance
        .post('/getRoutesForService', {
            service: 'charter',
            period: [],
        })
        .then(function (response) {
            loader.value = false;
            if (!response.data.status) {
                alert(response.data.message);
            } else {
                filteredItems.value = items.value = response.data.routes;
            }
        })
        .catch(function (error) {
            alert(error);
        });
});

const filterResults = (reset: boolean) => {
    if (reset) {
        Object.assign(filters, initialFiltersState);

        filteredItems.value = toRaw(items.value);

        return false;
    }

    filteredItems.value = toRaw(items.value).filter((route) => {
        if (toRaw(filters).datesInterval == null) {
            filters.datesInterval = ['', ''];
        }

        if (toRaw(filters.datesInterval)[0] === '' && toRaw(filters.datesInterval)[1] === '') {
            if (
                route.departureCountry
                    .toLowerCase()
                    .indexOf(toRaw(filters).departureCountry.toLowerCase()) === 0 &&
                route.departureCity
                    .toLowerCase()
                    .indexOf(toRaw(filters).departureCity.toLowerCase()) === 0 &&
                route.destinationCountry
                    .toLowerCase()
                    .indexOf(toRaw(filters).destinationCountry.toLowerCase()) === 0 &&
                route.destinationCity
                    .toLowerCase()
                    .indexOf(toRaw(filters).destinationCity.toLowerCase()) === 0
            ) {
                return true;
            }
        } else {
            if (
                route.departureCountry
                    .toLowerCase()
                    .indexOf(toRaw(filters).departureCountry.toLowerCase()) === 0 &&
                route.departureCity
                    .toLowerCase()
                    .indexOf(toRaw(filters).departureCity.toLowerCase()) === 0 &&
                route.destinationCountry
                    .toLowerCase()
                    .indexOf(toRaw(filters).destinationCountry.toLowerCase()) === 0 &&
                route.destinationCity
                    .toLowerCase()
                    .indexOf(toRaw(filters).destinationCity.toLowerCase()) === 0 &&
                Date.parse(String(route.startDate)) >=
                    Date.parse(
                        String(new Date(new Date(toRaw(filters).datesInterval[0]).toDateString()))
                    ) &&
                Date.parse(String(route.startDate)) <=
                    Date.parse(
                        String(new Date(new Date(toRaw(filters).datesInterval[1]).toDateString()))
                    )
            ) {
                return true;
            }
        }
    });
};

const format = (date: Array<Date>) => {
    date = toRaw(date);

    const day = date[0].getDate();
    const month = date[0].getMonth() + 1;
    const year = date[0].getFullYear();

    if (date[1] != null) {
        const day1 = date[1].getDate();
        const month1 = date[1].getMonth() + 1;
        const year1 = date[1].getFullYear();

        return `${day}/${month}/${year} - ${day1}/${month1}/${year1}`;
    }
};
</script>
<template>
    <div class="p-10">
        <div>
            <div class="border-2 border-gray-400 rounded p-5">
                <div class="flex justify-between">
                    <div class="text-xl">{{ items.length }} Results</div>
                </div>
                <hr class="border-gray-400 my-2" />
                <div class="flex justify-between">
                    <div class="flex">
                        <div class="col-span-6 sm:col-span-3 mr-2">
                            <label for="departureCountry" class="block text-gray-700"
                                >Departure Country</label
                            >
                            <input
                                type="text"
                                name="departureCountry"
                                id="departureCountry"
                                autocomplete="off"
                                v-model="filters.departureCountry"
                                placeholder="Romania"
                                class="form-control mt-1"
                            />
                        </div>
                        <div class="col-span-6 sm:col-span-3 mr-2">
                            <label for="departureCity" class="block text-gray-700"
                                >Departure City</label
                            >
                            <input
                                type="text"
                                name="departureCity"
                                id="departureCity"
                                autocomplete="off"
                                v-model="filters.departureCity"
                                placeholder="Bucharest"
                                class="form-control mt-1"
                            />
                        </div>
                        <div class="col-span-6 sm:col-span-3 mr-2">
                            <label for="destinationCountry" class="block text-gray-700"
                                >Arrival Country</label
                            >
                            <input
                                type="text"
                                name="destinationCountry"
                                id="destinationCountry"
                                autocomplete="off"
                                v-model="filters.destinationCountry"
                                placeholder="Turkey"
                                class="form-control mt-1"
                            />
                        </div>
                        <div class="col-span-6 sm:col-span-3 mr-2">
                            <label for="destinationCity" class="block text-gray-700"
                                >Arrival City</label
                            >
                            <input
                                type="text"
                                name="destinationCity"
                                id="destinationCity"
                                autocomplete="off"
                                v-model="filters.destinationCity"
                                placeholder="Antalya"
                                class="form-control mt-1"
                            />
                        </div>
                        <div>
                            <label for="datesInterval" class="block text-gray-700"
                                >Dates Interval</label
                            >
                            <Datepicker
                                class="mt-1"
                                v-model="filters.datesInterval"
                                range
                                :enableTimePicker="false"
                                id="datesInterval"
                                :format="format"
                                :previewFormat="format"
                            ></Datepicker>
                        </div>
                    </div>
                    <div class="flex items-end">
                        <button class="btn-success mr-2" @click="filterResults(false)">
                            Filter
                        </button>
                        <button class="btn-warning mr-2" @click="filterResults(true)">Reset</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg pt-0">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 mb-0">
                <thead
                    class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
                >
                    <tr>
                        <th scope="col" class="px-6 py-3">Nr.</th>
                        <th scope="col" class="px-6 py-3">Name</th>
                        <th scope="col" class="px-6 py-3">Provider</th>
                        <th scope="col" class="px-6 py-3">Departure Country</th>
                        <th scope="col" class="px-6 py-3">Departure City</th>
                        <th scope="col" class="px-6 py-3">Arrival Country</th>
                        <th scope="col" class="px-6 py-3">Arrival City</th>
                        <th scope="col" class="px-6 py-3">Rotations</th>
                        <th scope="col" class="px-6 py-3">Start Date</th>
                        <th scope="col" class="px-6 py-3">End Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-show="loader">
                        <td colspan="9" class="p-2">
                            <div class="flex items-center justify-center">
                                <Spinner />
                                <div class="text-lg pl-2">Loading...</div>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="filteredItems.length === 0 && !loader">
                        <td colspan="99" class="text-center">
                            <div class="font-bold dark:text-white text-xl uppercase my-2">
                                No Charter Routes found.
                            </div>
                        </td>
                    </tr>
                    <tr
                        v-for="(item, index) in filteredItems"
                        :key="index"
                        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                        <td class="px-6 py-4">
                            {{ index + 1 }}
                        </td>
                        <th
                            scope="row"
                            class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                        >
                            {{ item.startDate }} : {{ item.departureCity }} -
                            {{ item.destinationCity }}
                        </th>
                        <td class="px-6 py-4">
                            {{ item.providerName }}
                        </td>
                        <td class="px-6 py-4">
                            {{ item.departureCountry }}
                        </td>
                        <td class="px-6 py-4">
                            {{ item.departureCity }}
                        </td>
                        <td class="px-6 py-4">
                            {{ item.destinationCountry }}
                        </td>
                        <td class="px-6 py-4">
                            {{ item.destinationCity }}
                        </td>
                        <td class="px-6 py-4">
                            {{ item.rotations }}
                        </td>
                        <td class="px-6 py-4">
                            {{ item.startDate }}
                        </td>
                        <td class="px-6 py-4">
                            {{ item.endDate }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
