<script setup lang="ts">
import { onMounted, ref, type Ref } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import Alert from '@/components/AlertBox.vue';

const alertMessage: Ref<string> = ref('');
const alertType: Ref<string> = ref('');

const dateRange: Ref<string> = ref('');
const blackoutDate: Ref<string> = ref('');
const outboundTakeOff: Ref<string> = ref('');
const outboundDuration: Ref<string> = ref('');
const inboundTakeOff: Ref<string> = ref('');
const inboundDuration: Ref<string> = ref('');

onMounted(() => {
    alertType.value = 'alert-warning';
    alertMessage.value = 'Page under construction!';

    Array.from(document.querySelectorAll('[role="tab"]')).forEach((el) => {
        el.addEventListener('click', (e) => {
            toggleTabs(e);
        });
    });
});

function toggleTabs(e: Event) {
    const elemTarget = e.target as HTMLElement;

    Array.from(document.querySelectorAll('div[role="tabpanel"]')).forEach((el) => {
        const tabPanel = el as HTMLElement;
        tabPanel.style.display = 'none';
    });

    const tabToShow = document.querySelector(
        `${elemTarget.getAttribute('data-tabs-target')}`
    ) as HTMLElement;
    tabToShow.style.display = 'block';
}
</script>
<template>
    <Alert v-show="alertMessage != ''" :alertMessage="alertMessage" :alertType="alertType" />
    <div>
        <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
            <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                id="myTab"
                data-tabs-toggle="#myTabContent"
                role="tablist"
            >
                <li class="mr-2" role="presentation">
                    <button
                        class="inline-block p-4 rounded-t-lg border-b-2 text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-500 border-blue-600 dark:border-blue-500"
                        id="profile-tab"
                        data-tabs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="true"
                    >
                        General Info
                    </button>
                </li>
                <li class="mr-2" role="presentation">
                    <button
                        class="inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:border-transparent text-gray-500 dark:text-gray-400 border-gray-100 dark:border-gray-700"
                        id="dashboard-tab"
                        data-tabs-target="#dashboard"
                        type="button"
                        role="tab"
                        aria-controls="dashboard"
                        aria-selected="false"
                    >
                        Prices
                    </button>
                </li>
                <li class="mr-2" role="presentation">
                    <button
                        class="inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:border-transparent text-gray-500 dark:text-gray-400 border-gray-100 dark:border-gray-700"
                        id="settings-tab"
                        data-tabs-target="#settings"
                        type="button"
                        role="tab"
                        aria-controls="settings"
                        aria-selected="false"
                    >
                        History
                    </button>
                </li>
                <li role="presentation">
                    <button
                        class="inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:border-transparent text-gray-500 dark:text-gray-400 border-gray-100 dark:border-gray-700"
                        id="contacts-tab"
                        data-tabs-target="#contacts"
                        type="button"
                        role="tab"
                        aria-controls="contacts"
                        aria-selected="false"
                    >
                        Contacts
                    </button>
                </li>
            </ul>
        </div>
        <div id="myTabContent">
            <div
                class="container mx-auto p-4 bg-gray-50 rounded-lg dark:bg-gray-800 mb-8"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
            >
                <div>
                    <div class="text-xl underline underline-offset-2 mb-4">General Info</div>
                    <div class="flex items-center my-2">
                        <div class="pr-4 mr-4 border-r-2 border-sky-500">Route Type</div>
                        <div class="mr-4">
                            <div class="flex items-start">
                                <div class="flex items-center h-5">
                                    <input
                                        id="routeTypeNormal"
                                        name="routeType"
                                        type="radio"
                                        checked
                                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                                    />
                                </div>
                                <div class="ml-3 text-sm">
                                    <label for="routeTypeNormal" class="cursor-pointer"
                                        >Normal</label
                                    >
                                    <p class="text-gray-500">Normal route type on posting.</p>
                                </div>
                            </div>
                        </div>
                        <div class="mr-4 border-r-2 pr-4">
                            <div class="flex items-start">
                                <div class="flex items-center h-5">
                                    <input
                                        id="routeTypeAuto"
                                        name="routeType"
                                        type="radio"
                                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                                    />
                                </div>
                                <div class="ml-3 text-sm">
                                    <label for="routeTypeAuto" class="cursor-pointer"
                                        >Automatic</label
                                    >
                                    <p class="text-gray-500">Automatic route type on posting.</p>
                                </div>
                            </div>
                        </div>
                        <div class="mr-4">
                            <div class="flex items-center my-2">
                                <div class="pr-4 mr-4 border-r-2 border-sky-500">
                                    Airline Company
                                </div>
                                <div class="mr-4">
                                    <select
                                        id="country"
                                        name="country"
                                        autocomplete="country-name"
                                        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    >
                                        <option>United States</option>
                                        <option>Canada</option>
                                        <option>Mexico</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="flex items-center my-2 mr-4 border-r-2 pr-4">
                            <div class="pr-4 mr-4 border-r-2 border-sky-500">
                                Start Date - End Date
                            </div>
                            <div class="mr-4">
                                <Datepicker
                                    class="mt-1 w-96"
                                    v-model="dateRange"
                                    range
                                    :enableTimePicker="false"
                                    id="datesInterval"
                                ></Datepicker>
                            </div>
                        </div>
                        <div class="flex items-center my-2">
                            <div class="pr-4 mr-4 border-r-2 border-sky-500">Rotation</div>
                            <div class="mr-4">
                                <select
                                    id="country"
                                    name="country"
                                    autocomplete="country-name"
                                    class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                >
                                    <option>7</option>
                                    <option>10</option>
                                    <option>14</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="flex items-center my-2">
                            <div class="pr-4 mr-4 border-r-2 border-sky-500">Blackout Dates</div>
                            <div class="mr-4">
                                <Datepicker
                                    class="mt-1 w-56"
                                    v-model="blackoutDate"
                                    multiDates
                                    :enableTimePicker="false"
                                    id="datesInterval"
                                >
                                </Datepicker>
                            </div>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="flex items-center my-2 mr-4 border-r-2 pr-4">
                            <div class="pr-4 mr-4 border-r-2 border-sky-500">Offset inboud</div>
                            <div class="flex items-center mr-4">
                                <input
                                    type="text"
                                    name="offsetInbound"
                                    id="offsetInbound"
                                    autocomplete="off"
                                    class="form-control w-16 mt-1 mr-2"
                                />
                                <span> Days </span>
                            </div>
                        </div>
                        <div class="flex items-center my-2">
                            <div class="pr-4 mr-4 border-r-2 border-sky-500">Offset outbound</div>
                            <div class="flex items-center mr-4">
                                <input
                                    type="text"
                                    name="offsetOutbound"
                                    id="offsetOutbound"
                                    autocomplete="off"
                                    class="form-control mt-1 w-16 mr-2"
                                />
                                <span> Days </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="text-xl underline underline-offset-2 my-4">Flight</div>
                    <div class="border p-2 rounded">
                        <div class="flex items-center p-2 bg-slate-300 rounded">
                            <div class="text-xl font-semibold mr-4">Outbound</div>
                            <div>
                                <input
                                    type="text"
                                    name="outboundFlightNumber"
                                    id="outboundFlightNumber"
                                    placeholder="Flight Number"
                                    autocomplete="off"
                                    class="form-control w-44 mr-2"
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    name="outboundFlightCabin"
                                    id="outboundFlightCabin"
                                    placeholder="Flight Cabin"
                                    autocomplete="off"
                                    class="form-control w-44 mr-2"
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    name="outboundFlightClass"
                                    id="outboundFlightClass"
                                    placeholder="Flight Class"
                                    autocomplete="off"
                                    class="form-control w-44 mr-2"
                                />
                            </div>
                        </div>
                        <div class="mt-2">
                            <div class="flex">
                                <div class="col-span-6 sm:col-span-3 mr-2">
                                    <label
                                        for="outboundDepartureAirport"
                                        class="block text-gray-700"
                                        >Departure Airport</label
                                    >
                                    <input
                                        type="text"
                                        name="outboundDepartureAirport"
                                        id="outboundDepartureAirport"
                                        autocomplete="off"
                                        placeholder=""
                                        class="mt-1 form-control w-80"
                                    />
                                </div>
                                <div class="col-span-6 sm:col-span-3 mr-2">
                                    <label for="outboundArrivalAirport" class="block text-gray-700"
                                        >Arrival Airport</label
                                    >
                                    <input
                                        type="text"
                                        name="outboundArrivalAirport"
                                        id="outboundArrivalAirport"
                                        autocomplete="off"
                                        placeholder=""
                                        class="mt-1 form-control w-80"
                                    />
                                </div>
                                <div class="col-span-6 sm:col-span-3 mr-2">
                                    <label for="outboundTakeOff" class="block text-gray-700"
                                        >Take Off Date and Time</label
                                    >
                                    <Datepicker
                                        class="mt-1 w-80"
                                        v-model="outboundTakeOff"
                                        id="outboundTakeOff"
                                    >
                                    </Datepicker>
                                </div>
                                <div class="col-span-6 sm:col-span-3 mr-2">
                                    <label for="outboundDuration" class="block text-gray-700"
                                        >Flight Duration</label
                                    >
                                    <Datepicker
                                        class="mt-1 w-80"
                                        v-model="outboundDuration"
                                        range
                                        id="outboundDuration"
                                    ></Datepicker>
                                </div>
                                <div>
                                    <button class="mt-8 py-4 btn-primary">Add Segment</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="border p-2 rounded">
                        <div class="flex items-center p-2 bg-slate-300 rounded">
                            <div class="text-xl font-semibold mr-4">Inbound</div>
                            <div>
                                <input
                                    type="text"
                                    name="inboundFlightNumber"
                                    id="inboundFlightNumber"
                                    placeholder="Flight Number"
                                    autocomplete="off"
                                    class="form-control w-44 mr-2"
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    name="inboundFlightCabin"
                                    id="inboundFlightCabin"
                                    placeholder="Flight Cabin"
                                    autocomplete="off"
                                    class="form-control w-44 mr-2"
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    name="inboundFlightClass"
                                    id="inboundFlightClass"
                                    placeholder="Flight Class"
                                    autocomplete="off"
                                    class="form-control w-44 mr-2"
                                />
                            </div>
                        </div>
                        <div class="mt-2">
                            <div class="flex">
                                <div class="col-span-6 sm:col-span-3 mr-2">
                                    <label for="inboundDepartureAirport" class="block text-gray-700"
                                        >Departure Airport</label
                                    >
                                    <input
                                        type="text"
                                        name="inboundDepartureAirport"
                                        id="inboundDepartureAirport"
                                        autocomplete="off"
                                        placeholder=""
                                        class="mt-1 form-control w-80"
                                    />
                                </div>
                                <div class="col-span-6 sm:col-span-3 mr-2">
                                    <label for="inboundArrivalAirport" class="block text-gray-700"
                                        >Arrival Airport</label
                                    >
                                    <input
                                        type="text"
                                        name="inboundArrivalAirport"
                                        id="inboundArrivalAirport"
                                        autocomplete="off"
                                        placeholder=""
                                        class="mt-1 form-control w-80"
                                    />
                                </div>
                                <div class="col-span-6 sm:col-span-3 mr-2">
                                    <label for="inboundTakeOff" class="block text-gray-700"
                                        >Take Off Date and Time</label
                                    >
                                    <Datepicker
                                        class="mt-1 w-80"
                                        v-model="inboundTakeOff"
                                        id="inboundTakeOff"
                                    >
                                    </Datepicker>
                                </div>
                                <div class="col-span-6 sm:col-span-3 mr-2">
                                    <label for="inboundDuration" class="block text-gray-700"
                                        >Flight Duration</label
                                    >
                                    <Datepicker
                                        class="mt-1 w-80"
                                        v-model="inboundDuration"
                                        range
                                        id="inboundDuration"
                                    >
                                    </Datepicker>
                                </div>
                                <div>
                                    <button class="mt-8 py-4 btn-primary">Add Segment</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="text-xl underline underline-offset-2 my-4">Extras</div>
                    <div class="border p-2 rounded">
                        <div class="flex items-center p-2 bg-slate-300 rounded">
                            <div class="text-xl font-semibold mr-4">Luggage</div>
                        </div>
                        <div class="flex justify-between mt-2">
                            <div class="flex items-start">
                                <div class="flex items-center h-5">
                                    <input
                                        id="noLuggage"
                                        name="noLuggage"
                                        type="checkbox"
                                        checked
                                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                                    />
                                </div>
                                <div class="ml-3 text-sm">
                                    <label for="noLuggage">No Luggage</label>
                                </div>
                            </div>
                            <div class="flex items-start">
                                <div class="flex items-center h-5">
                                    <input
                                        id="smallHandLuggage"
                                        name="smallHandLuggage"
                                        type="checkbox"
                                        checked
                                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                                    />
                                </div>
                                <div class="ml-3 text-sm">
                                    <label for="smallHandLuggage" class="cursor-pointer"
                                        >Small Hand Luggage</label
                                    >
                                </div>
                            </div>
                            <div class="flex items-start">
                                <div class="flex items-center h-5">
                                    <input
                                        id="bigHandLuggage"
                                        name="bigHandLuggage"
                                        type="checkbox"
                                        checked
                                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                                    />
                                </div>
                                <div class="ml-3 text-sm">
                                    <label for="bigHandLuggage" class="cursor-pointer"
                                        >Big Hand Luggage</label
                                    >
                                </div>
                            </div>
                            <div class="flex items-start">
                                <div class="flex items-center h-5">
                                    <input
                                        id="tenKgCargoLuggage"
                                        name="tenKgCargoLuggage"
                                        type="checkbox"
                                        checked
                                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                                    />
                                </div>
                                <div class="ml-3 text-sm">
                                    <label for="tenKgCargoLuggage" class="cursor-pointer"
                                        >10kg cargo Luggage</label
                                    >
                                </div>
                            </div>
                            <div class="flex items-start">
                                <div class="flex items-center h-5">
                                    <input
                                        id="twentyKgCargoLuggage"
                                        name="twentyKgCargoLuggage"
                                        type="checkbox"
                                        checked
                                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                                    />
                                </div>
                                <div class="ml-3 text-sm">
                                    <label for="twentyKgCargoLuggage" class="cursor-pointer"
                                        >20kg cargo Luggage</label
                                    >
                                </div>
                            </div>
                            <div class="flex items-start">
                                <div class="flex items-center h-5">
                                    <input
                                        id="twentyThreeKgCargoLuggage"
                                        name="twentyThreeKgCargoLuggage"
                                        type="checkbox"
                                        checked
                                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                                    />
                                </div>
                                <div class="ml-3 text-sm">
                                    <label for="twentyThreeKgCargoLuggage" class="cursor-pointer"
                                        >23kg cargo Luggage</label
                                    >
                                </div>
                            </div>
                            <div class="flex items-start">
                                <div class="flex items-center h-5">
                                    <input
                                        id="thrityKgCargoLuggage"
                                        name="thrityKgCargoLuggage"
                                        type="checkbox"
                                        checked
                                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                                    />
                                </div>
                                <div class="ml-3 text-sm">
                                    <label for="thrityKgCargoLuggage" class="cursor-pointer"
                                        >30kg cargo Luggage</label
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="border p-2 rounded">
                        <div class="flex items-center p-2 bg-slate-300 rounded">
                            <div class="text-xl font-semibold mr-4">Others</div>
                        </div>
                        <div class="flex justify-between mt-2">
                            <div class="flex items-start">
                                <div class="flex items-center h-5">
                                    <input
                                        id="selectedSeat"
                                        name="selectedSeat"
                                        type="checkbox"
                                        checked
                                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                                    />
                                </div>
                                <div class="ml-3 text-sm">
                                    <label for="selectedSeat">Selected Seat</label>
                                </div>
                            </div>
                            <div class="flex items-start">
                                <div class="flex items-center h-5">
                                    <input
                                        id="meal"
                                        name="meal"
                                        type="checkbox"
                                        checked
                                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                                    />
                                </div>
                                <div class="ml-3 text-sm">
                                    <label for="meal" class="cursor-pointer">Meal</label>
                                </div>
                            </div>
                            <div class="flex items-start">
                                <div class="flex items-center h-5">
                                    <input
                                        id="specialLuggage"
                                        name="specialLuggage"
                                        type="checkbox"
                                        checked
                                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                                    />
                                </div>
                                <div class="ml-3 text-sm">
                                    <label for="specialLuggage" class="cursor-pointer"
                                        >Special Luggage</label
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="my-8">
                        <button type="submit" class="btn-primary">Submit Form</button>
                    </div>
                </div>
            </div>
            <div
                class="hidden p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
                id="dashboard"
                role="tabpanel"
                aria-labelledby="dashboard-tab"
            >
                Prices
            </div>
            <div
                class="hidden p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
                id="settings"
                role="tabpanel"
                aria-labelledby="settings-tab"
            >
                History
            </div>
            <div
                class="hidden p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
                id="contacts"
                role="tabpanel"
                aria-labelledby="contacts-tab"
            >
                Contacts
            </div>
        </div>
    </div>
</template>
