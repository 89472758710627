<script setup lang="ts">
import { useUCMHotelStore } from '@/stores/ucmHotel';
import { onMounted, ref, type Ref } from 'vue';
const store = useUCMHotelStore();

let modal: any;

const language: Ref<string> = ref('-1');

onMounted(() => {
    const $targetEl = document.getElementById('modalDescriptions');
    const options = {
        backdrop: 'dynamic',
        backdropClasses:
            'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
        closable: true,
       
    };
    // @ts-ignore: Unreachable code error
    modal = new Modal($targetEl, options);
})


const newDescription = () => {
    modal.hide();
    store.hotel.descriptions.push(
        {
            language: language.value,
            description: {
                hotelInfo: '',
                hotelDescription: '',
                roomsDescription: '',
                entertainment: '',
                meals: '',
                beachInformation: '',
                sportActivities: '',
                childrenFacilities: '',
                specialRequirements: '',
                wellness: '',
                internet: '',
                accessibility: '',
                notes: '',
            }
        }
    )
}

const chooseLanguage = () => {
    modal.show();
}

const closeModal = () => {
    modal.hide();
}


</script>
<template>
    <div>
        <button class="btn-primary btn-sm" type="button" @click="chooseLanguage()">
            Add Descriptions
        </button>
    </div>
    <div v-for="(description, index) in store.hotel.descriptions" :key="index">
        <div class="flex justify-between">
            <div>
                Language: <span class="uppercase">{{ description.language }}</span>
            </div>
            <div>
                <button
                    class="btn-danger"
                    type="button"
                    @click="
                            store.hotel.descriptions.splice(
                                index,
                                1
                            )
                    "
                >
                    Delete Description
                </button>
            </div>
        </div>
        <div class="grid gap-6 mb-6 md:grid-cols-2">
            <div>
                <label :for="'hotel-info'+index" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Hotel Information
                </label>
                <textarea :id="'hotel-info'+index" v-model="store.hotel.descriptions[index].description.hotelInfo" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your Hotel Information here...">
                </textarea>
            </div>
            <div>
                <label :for="'hotel-description'+index" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Hotel Description
                </label>
                <textarea :id="'hotel-description'+index" v-model="store.hotel.descriptions[index].description.hotelDescription" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your Hotel Description here...">
                </textarea>
            </div>
            <div>
                <label :for="'hotel-rooms-description'+index" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Rooms Description
                </label>
                <textarea :id="'hotel-rooms-description'+index" v-model="store.hotel.descriptions[index].description.roomsDescription" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your Hotel Rooms Description here...">
                </textarea>
            </div>
            <div>
                <label :for="'hotel-entertainment'+index" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Entertainment
                </label>
                <textarea :id="'hotel-entertainment'+index" v-model="store.hotel.descriptions[index].description.entertainment" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your Hotel Entertainment here...">
                </textarea>
            </div>
            <div>
                <label :for="'hotel-meals'+index" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Meals
                </label>
                <textarea :id="'hotel-meals'+index" v-model="store.hotel.descriptions[index].description.meals" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your Hotel Meals here...">
                </textarea>
            </div>
            <div>
                <label :for="'hotel-beach-information'+index" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Beach Information
                </label>
                <textarea :id="'hotel-beach-information'+index" v-model="store.hotel.descriptions[index].description.beachInformation" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your Hotel Beach Information here...">
                </textarea>
            </div>
            <div>
                <label :for="'hotel-sport-activities'+index" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Sport Activities
                </label>
                <textarea :id="'hotel-sport-activities'+index" v-model="store.hotel.descriptions[index].description.sportActivities" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your Hotel Sport Activities here...">
                </textarea>
            </div>
            <div>
                <label :for="'hotel-children-facilities'+index" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Children Facilities
                </label>
                <textarea :id="'hotel-children-facilities'+index" v-model="store.hotel.descriptions[index].description.childrenFacilities" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your Hotel Children Facilities here...">
                </textarea>
            </div>
            <div>
                <label :for="'hotel-special-requirements'+index" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Special Requirements
                </label>
                <textarea :id="'hotel-special-requirements'+index" v-model="store.hotel.descriptions[index].description.specialRequirements" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your Hotel Special Requirements here...">
                </textarea>
            </div>
            <div>
                <label :for="'hotel-wellness'+index" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Hotel Wellness
                </label>
                <textarea :id="'hotel-wellness'+index" v-model="store.hotel.descriptions[index].description.wellness" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your Hotel Wellness here...">
                </textarea>
            </div>
            <div>
                <label :for="'hotel-internet'+index" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Hotel Internet
                </label>
                <textarea :id="'hotel-internet'+index" v-model="store.hotel.descriptions[index].description.internet" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your Hotel Internet here...">
                </textarea>
            </div>
            <div>
                <label :for="'hotel-accessibility'+index" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Hotel Accessibility
                </label>
                <textarea :id="'hotel-notes'+index" v-model="store.hotel.descriptions[index].description.accessibility" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your Hotel Accessibility here...">
                </textarea>
            </div>
            <div>
                <label :for="'hotel-notes'+index" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Hotel Notes
                </label>
                <textarea :id="'hotel-notes'+index" v-model="store.hotel.descriptions[index].description.notes" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your Hotel Notes here...">
                </textarea>
            </div>
        </div>
    </div>
    <div id="modalDescriptions" tabindex="-1" aria-hidden="true"
        class="fixed left-0 right-0 top-0 z-50 hidden h-[calc(100%-1rem)] max-h-full w-full overflow-y-auto overflow-x-hidden p-4 md:inset-0">
        <div class="relative max-h-full w-full max-w-2xl">
            <!-- Modal content -->
            <div class="relative rounded-lg bg-white shadow dark:bg-gray-700">
                <!-- Modal header -->
                <div class="flex items-start justify-between rounded-t border-b p-5 dark:border-gray-600">
                    <h3 class="text-xl font-semibold text-gray-900 dark:text-white lg:text-2xl">
                        Description language
                    </h3>
                    <button type="button" @click="closeModal()"
                        class="ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white">
                        <svg class="h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <!-- Modal body -->
                <div class="space-y-6 p-6">
                    <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        Setup descriptions in a certain language
                    </p>
                    <label for="languages" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select a
                        language</label>
                    <select id="languages"
                        v-model="language"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option selected value="-1">Choose a language</option>
                        <option value="ro">Romanian</option>
                        <option value="en">English</option>
                        <option value="de">German</option>
                        <option value="es">Spanish</option>
                        <option value="zh">Chinese</option>
                        <option value="ar">Arabic</option>
                        <option value="ru">Russian</option>
                        <option value="ja">Japanese</option>
                        <option value="fr">French</option>
                        <option value="it">Italian</option>
                    </select>

                </div>
                <!-- Modal footer -->
                <div
                    class="flex items-center space-x-2 rtl:space-x-reverse rounded-b border-t border-gray-200 p-6 dark:border-gray-600">
                    <button type="button" @click="newDescription()"
                        class="rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Start
                    </button>
                    <button type="button" @click="closeModal()"
                        class="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>