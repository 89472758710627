import { defineStore } from 'pinia';
import type { UserData, UserState } from '@/types/user.interfaces';

export const useUserStore = defineStore('user', {
    state: (): UserState<UserData> => ({
        user:
            localStorage.getItem('userData') != null
                ? JSON.parse(String(localStorage.getItem('userData')))
                : null,
    }),
    actions: {
        setUser(userData: UserData) {
            this.user = userData;
            localStorage.setItem('userData', JSON.stringify(userData));
        },
        clearUser() {
            this.user = null;
            localStorage.removeItem('userData');
        },
    },
    getters: {
        userEmail: (state: { user: { userEmail: string } | null }) =>
            state.user != null ? state.user.userEmail : null,
        userName: (state: { user: { userRealName: string } | null }) =>
            state.user != null ? state.user.userRealName : null,
        userId: (state: { user: { userId: string } | null }) =>
            state.user != null ? state.user.userId : null,
    },
});
