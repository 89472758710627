<script setup lang="ts">
import { onMounted, ref, watch, type Ref, computed, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import IconReset from '@/components/icons/IconReset.vue';
import IconCircleExclamation from '../icons/IconCircleExclamation.vue';
import IconTrashBin from '@/components/icons/IconTrashBin.vue';
import AutosuggestInput from '@/components/AutosuggestInput.vue';
import MultiselectDropdown from '@/components/MultiselectDropdown.vue';
import type { DropdownItem } from '@/types/multiselectDropdown.interface';
import { usePackageStore } from '@/stores/package';
import { getHotels } from '@/services/getHotels';
import { activeProvidersService } from '@/services/getActiveProviders';
import type { HotelProviderInterface } from '@/types/builder/hotelProvider.interface';

const store = usePackageStore();
const searchActive: Ref<boolean> = ref(false);
const resetPressed: Ref<boolean> = ref(false);
const activeProviders: Ref<Array<HotelProviderInterface> | []> = ref([]);
const showLocationWarning: Ref<boolean> = ref(false);
const loader: Ref<boolean> = ref(false);
const childComponentRef = ref();
const route = useRoute();

const hotelStarsDropdownOptions: Ref<Array<DropdownItem>> = ref([
    {
        itemId: 'star1',
        itemLabel: 'One Star',
        checked: true,
        itemDescription: 'One Star Hotels',
    },
    {
        itemId: 'star2',
        itemLabel: 'Two Stars',
        checked: true,
        itemDescription: 'Two Stars Hotels',
    },
    {
        itemId: 'star3',
        itemLabel: 'Three Stars',
        checked: true,
        itemDescription: 'Three Stars Hotels',
    },
    {
        itemId: 'star4',
        itemLabel: 'Four Stars',
        checked: true,
        itemDescription: 'Four Stars Hotels',
    },
    {
        itemId: 'star5',
        itemLabel: 'Five Stars',
        checked: true,
        itemDescription: 'Five Stars Hotels',
    },
]);
const trafficsDropdownOptions: Ref<Array<DropdownItem>> = ref([]);

    

activeProvidersService().then((res) => {
    if (res != undefined && res != false) {
        activeProviders.value = res;
        Array.from(activeProviders.value.find(provider => provider.providerId === 53)?.tourOperators || []).forEach((provider) => {
            // console.log(provider);
            trafficsDropdownOptions.value.push({
                itemId: provider,
                itemLabel: provider,
                checked: true,
                itemDescription: provider,
            })
        })
    }
});

const searchHotel = (
    locationId: number,
    locationName: string,
    transportId: string = store.package.transport.transportId,
    transportType: string = store.package.transport.transportType
) => {
    if (Object.keys(store.package.hotels.hotelsWithLocations).includes(String(locationId))) {
        alert('Location already present!');
        childComponentRef.value.clearInput();
        return false;
    }
    loader.value = true;

    getHotels(locationId, store.package.hotels.providers, transportId, transportType).then(
        (res) => {
            loader.value = false;
            if (res != undefined && res != false) {
                searchActive.value = true;
                if (res.errorCode === 1022) {
                    showLocationWarning.value = true;
                } else {
                    showLocationWarning.value = false;
                }

                store.package.hotels.hotelsWithLocations = Object.assign(
                    {
                        [locationId]: {
                            locationName: locationName,
                            hotels: res.hotels,
                        },
                    },
                    store.package.hotels.hotelsWithLocations
                );

                childComponentRef.value.clearInput();
            }
        }
    );
};

const checkAll = (event: Event) => {

    const targetLocationId = (event.target as HTMLInputElement).id.split('-').pop();
    const checkType = (event.target as HTMLInputElement).dataset.type;


    const visibleCheckListByLocationId = Array.from(
        document.querySelectorAll(`[data-hotellocationid-${checkType}='${targetLocationId}']`)
    ).filter(
        (element) =>
            window.getComputedStyle(
                element?.parentElement?.parentElement?.parentElement as HTMLElement
            ).display != 'none'
    );

    visibleCheckListByLocationId.forEach((check) => {
        if ((event.target as HTMLInputElement).checked) {

            if(checkType == 'select'){

                store.package.hotels.selectedHotels.push(parseInt((check as HTMLInputElement).value));

            }else if(checkType == 'recommend'){

                if (store.package.hotels.selectedHotels.includes( parseInt((check as HTMLInputElement).value) )) {
                    store.package.hotels.recommendedHotels.push( parseInt((check as HTMLInputElement).value) );
                }
                
            }

        } else {

            if(checkType == 'select'){

                store.package.hotels.selectedHotels = store.package.hotels.selectedHotels.filter(
                    (hotelId: number) => hotelId !== parseInt((check as HTMLInputElement).value)
                );
            }else if(checkType == 'recommend'){

                store.package.hotels.recommendedHotels = store.package.hotels.recommendedHotels.filter(
                    (hotelId: number) => hotelId != parseInt((check as HTMLInputElement).value)
                );
            }
            
        }
    });
};

const filterResults = () => {
    const allHotels = Array.from(document.getElementsByClassName(`hotelListItem`));
    if (allHotels.length === 0) return false;

    const ratingArray: Array<string> = [];

    Array.from(document.querySelectorAll('*[id^="star"]')).forEach((rating) => {
        if ((rating as HTMLInputElement).checked) {
            ratingArray.push(rating.id.slice(-1));
        }
    });

    Array.from(document.querySelectorAll(`[data-filterstars]`)).forEach((element) => {
            (element as HTMLElement).style.display = 'none';
        });

    ratingArray.forEach((rating) => {

        Array.from(document.querySelectorAll(`[data-filterstars="${rating}"]`)).forEach((element) => {
            (element as HTMLElement).style.display = 'block';
        });
        
    });
    
    const visibleHotels = Array.from(document.getElementsByClassName(`hotelListItem`)).filter(
        (s) => window.getComputedStyle(s).getPropertyValue('display') != 'none'
    );

    visibleHotels.forEach((hotel) => {
        if ((document.getElementById('hotelName') as HTMLSelectElement).value.trim() != '') {
            if (
                (hotel as HTMLElement)
                    .getAttribute('data-filtername')
                    ?.toLocaleLowerCase()
                    ?.includes(
                        (
                            document.getElementById('hotelName') as HTMLSelectElement
                        ).value.toLocaleLowerCase()
                    )
            ) {
                (hotel as HTMLElement).style.display = 'block';
            } else {
                (hotel as HTMLElement).style.display = 'none';
            }
        }
    });
};

const resetFilters = () => {
    Array.from(document.querySelectorAll('*[id^="star"]')).forEach((rating) => {
        (rating as HTMLInputElement).checked = true;
    });

    (document.getElementById('hotelName') as HTMLSelectElement).value = '';
    filterResults();
};

const resetSelectedProvidersAndCheckedHotels = () => {
    store.package.hotels.providers = [];
    store.package.hotels.selectedHotels = [];
    store.package.hotels.hotelsWithLocations = {};
    resetPressed.value = true;
    searchActive.value = false;
    showLocationWarning.value = false;

    childComponentRef.value.clearInput();

    

    Array.from(document.querySelectorAll('[data-provider-check]')).forEach((check) => {

        (check  as HTMLInputElement).checked = false;
    })


    console.log(store.package.hotels.providers);
};

const removeLocation = (id: number) => {
    const checkListByLocationId = () => {
        const els = Array.from(document.querySelectorAll(`[data-hotellocationid-select='${id}']`));
        return [].map.call(els, (el: HTMLInputElement) => parseInt(el.value));
    };

    store.package.hotels.selectedHotels = store.package.hotels.selectedHotels.filter(
        (item: number) => {
            return !checkListByLocationId().includes(item);
        }
    );

    delete store.package.hotels.hotelsWithLocations[id];
};

const selectProvider = (event: Event,id: number) => {

    if( (event.target as HTMLInputElement).checked ){
        store.package.hotels.providers.push(id);
        if(id === 53){
            store.package.hotels.tourOperators = activeProviders.value.find(provider => provider.providerId === 53)?.tourOperators || [];
        }
    }else{
        let index = store.package.hotels.providers.indexOf(id);
        if (index !== -1) {
            store.package.hotels.providers.splice(index, 1);
        }
        if(id === 53){
            store.package.hotels.tourOperators = [];
        }
    }

    console.log(store.package.hotels.providers);
    
}

watch(
  () => store.package.hotels.hotelsWithLocations,
  () => {
    searchActive.value = true;
    store.package.hotels.providers.forEach(provider => {

        (document.querySelector(`[value="${provider}"]`) as HTMLInputElement).checked = true;

    });
  }
)
</script>
<template>
    <div>
        <div class="mb-3">
            <div class="flex justify-between">
                <div class="w-4/6">
                    <div class="text-xl underline underline-offset-2 my-4">
                        Select the providers you want to use for these packages
                    </div>
                    <div class="grid grid-cols-4 gap-4 w-full py-2">
                        <div
                            v-for="(provider, index) in activeProviders"
                            :key="index"
                            class="flex items-center"
                        >
                                <div class="flex items-center mr-4">
                                    <input
                                        :id="provider.providerName"
                                        type="checkbox"
                                        data-provider-check
                                        @change="selectProvider($event, provider.providerId)"
                                        :disabled="searchActive && !resetPressed"
                                        :value="provider.providerId"
                                        class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        :for="provider.providerName"
                                        class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer"
                                        >{{ provider.providerName }}</label
                                    >
                                </div>
                            
                            <div class="flex flex-wrap justify-start" v-if="provider.tourOperators.length > 0 && store.package.hotels.providers.includes(provider.providerId)">
                                <MultiselectDropdown
                                        class="mt-1"
                                        id="traffics"
                                        :buttonText="'Traffics'"
                                        :dropdownId="'traffics'"
                                        :dropdownOptions="trafficsDropdownOptions"
                                    />
                            </div>
                        </div>
                        <button
                            v-show="searchActive"
                            @click="resetSelectedProvidersAndCheckedHotels"
                            type="button"
                            class="py-2 px-3 text-xs font-medium text-center inline-flex items-center focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 rounded-lg mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                        >
                            <IconReset class="mr-2 -ml-1 w-5 h-5 fill-white" />
                            Reset Selected Providers
                        </button>
                    </div>
                </div>
                <div class="w-2/6">
                    <div
                        class="p-4 mb-2 border border-green-300 rounded-lg bg-green-50 dark:bg-green-200"
                        role="alert"
                    >
                        <div class="flex items-center">
                            <IconCircleExclamation class="fill-lime-600" />
                            <h3 class="text-lg font-medium text-green-700 dark:text-green-800">
                                Selected Transport
                            </h3>
                        </div>
                        <div class="mt-2 mb-2 text-sm text-green-700 dark:text-green-800">
                            {{ store.package.transport.transportName }} ( {{store.package.generalInfo.period.join(' to ')}} )
                            <br />
                            ( {{ store.package.generalInfo.minOffset }} days Outbound Offset ,{{
                                store.package.generalInfo.maxOffset
                            }}
                            days Inbound Offset)
                            <div
                                class="flex"
                                v-if="route.params.id != '' && route.params.id != undefined"
                            >
                                <div class="col-span-6 sm:col-span-3 mr-2">
                                    <label for="minOffset" class="block text-gray-700"
                                        >Edit Outbound Offset</label
                                    >
                                    <input
                                        type="number"
                                        min="0"
                                        max="2"
                                        name="minOffset"
                                        id="minOffset"
                                        autocomplete="off"
                                        v-model.number="store.package.generalInfo.minOffset"
                                        class="form-control mt-1"
                                    />
                                    <div class="ml-3 text-sm">
                                        <p class="text-gray-500">
                                            Number of days, the hotel <br />
                                            check-in date will be delayed
                                        </p>
                                    </div>
                                </div>
                                <div class="col-span-6 sm:col-span-3 mr-2">
                                    <label for="maxOffset" class="block text-gray-700"
                                        >Edit Inbound Offset</label
                                    >
                                    <input
                                        type="number"
                                        min="0"
                                        max="2"
                                        name="maxOffset"
                                        id="maxOffset"
                                        autocomplete="off"
                                        v-model.number="store.package.generalInfo.maxOffset"
                                        class="form-control mt-1"
                                    />
                                    <div class="ml-3 text-sm">
                                        <p class="text-gray-500">
                                            Number of days, the hotel <br />
                                            check-out date will be delayed
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-xl underline underline-offset-2 mb-4">
            Select Package Destinations (you can add multiple cities, one by one)
        </div>
        <div v-show="loader">
            <svg
                role="status"
                class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                />
                <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                />
            </svg>
        </div>
        <div class="flex">
            <div class="w-3/4">
                <AutosuggestInput @search-hotels="searchHotel" ref="childComponentRef" />
            </div>
        </div>
        <div
            v-show="showLocationWarning"
            class="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
            role="alert"
        >
            <svg
                aria-hidden="true"
                class="flex-shrink-0 inline w-5 h-5 mr-3"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clip-rule="evenodd"
                ></path>
            </svg>
            <span class="sr-only">Info</span>
            <div>
                The location you have selected is not in the proximity of the destination airport
                for the route you have selected.
            </div>
        </div>
        <hr class="my-6" />
        <div>
            <div class="text-xl underline underline-offset-2 my-4">
                Select hotels to be involved in packages
                <span v-show="store.package.hotels.selectedHotels.length != 0">
                    ( 
                        {{ store.package.hotels.selectedHotels.length }}
                        {{ store.package.hotels.selectedHotels.length === 1 ? 'hotel' : 'hotels' }}
                        selected ,
                        {{ store.package.hotels.recommendedHotels.length }}
                        recommended
                    )
                </span>
            </div>
            <div
                class="flex items-center p-1 bg-slate-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            >
                <div class="text-xl font-semibold mr-4">Filter Hotels</div>
                <div class="mr-4">
                    <MultiselectDropdown
                        class="mt-1"
                        id="rating"
                        :buttonText="'Hotel Rating'"
                        :dropdownId="'hotelStarsMultiselect'"
                        :dropdownOptions="hotelStarsDropdownOptions"
                    />
                </div>
                <div class="mr-4">
                    <input
                        type="text"
                        name="hotelName"
                        id="hotelName"
                        placeholder="Hotel Name"
                        autocomplete="off"
                        class="form-control w-48"
                    />
                </div>
                <div class="pt-2">
                    <button class="btn-success" @click="filterResults">Filter</button>
                    <button class="btn-warning" @click="resetFilters">Reset Filter</button>
                </div>
            </div>
            <div class="grid grid-cols-3 gap-4 py-2 px-4 border-2">
                <div v-for="(location, key) of store.package.hotels.hotelsWithLocations" :key="key">
                    <div class="flex justify-between items-center">
                        <div class="flex items-center font-medium text-blue-500 max-w-260px">
                            <div>
                                <button
                                    type="button"
                                    title="Delete this location"
                                    @click="removeLocation(key as number)"
                                    class="flex items-center py-1 px-2 mx-2 text-xs font-medium text-center text-white bg-red-700 rounded hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                                >
                                    <IconTrashBin />
                                </button>
                            </div>
                            <div class="w-64">
                                {{ store.package.hotels.hotelsWithLocations[key].locationName }}
                                -
                                {{ store.package.hotels.hotelsWithLocations[key].hotels.length }}
                            </div>
                        </div>
                        <div class="flex">
                            <div class="mr-2 text-right">
                                <label
                                    :for="'checkAll-recommend-' + key"
                                    class="ml-2 text-xs font-medium text-gray-900 dark:text-gray-300 cursor-pointer"
                                    >Recommend All</label
                                >
                                <br>
                                <input
                                    type="checkbox"
                                    value=""
                                    @change="checkAll"
                                    :id="'checkAll-recommend-' + key"
                                    data-type="recommend"
                                    class="w-4 h-4 text-purple-600 bg-purple-100 rounded border-purple-300 focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                                />
                            </div>
                            <div class="mr-6 text-right">
                                <label
                                    :for="'checkAll-select-' + key"
                                    class="ml-2 text-xs font-medium text-gray-900 dark:text-gray-300 cursor-pointer"
                                    >All</label
                                >
                                <br>
                                <input
                                    type="checkbox"
                                    value=""
                                    @change="checkAll"
                                    :id="'checkAll-select-' + key"
                                    data-type="select"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                            </div>
                        </div>
                    </div>
                    <ul
                        class="overflow-auto h-96 w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    >
                        <li
                            v-for="(hotel, hotelIndex) of store.package.hotels.hotelsWithLocations[
                                key
                            ].hotels"
                            :data-locationId="hotelIndex"
                            :data-filterstars="hotel.hotelStars"
                            :data-filtername="hotel.hotelName"
                            :key="hotelIndex"
                            class="hotelListItem w-full px-4 py-2 border-b border-gray-200 rounded-t-lg dark:border-gray-600"
                        >
                            <div class="flex justify-between">
                                <div class="ml-2 text-sm">
                                    <label
                                        :for="'hotel_' + hotel.hotelId"
                                        class="font-medium text-gray-900 dark:text-gray-300 cursor-pointer"
                                    >
                                        {{ hotel.hotelName }}
                                    </label>
                                    <p class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                        {{ hotel.hotelStars }} stars
                                    </p>
                                </div>
                                <div class="flex items-center h-5">
                                    <input
                                        :id="'hotel_fav_' + hotel.hotelId"
                                        aria-describedby="helper-checkbox-text"
                                        type="checkbox"
                                        :value="hotel.hotelId"
                                        :disabled="!store.package.hotels.selectedHotels.includes(hotel.hotelId)"
                                        :name="'hotel_fav_' + hotel.hotelId"
                                        :data-hotelLocationId-recommend="key"
                                        v-model="store.package.hotels.recommendedHotels"
                                        class="w-4 h-4 text-purple-600 bg-purple-100 rounded border-purple-300 focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer me-3"
                                    />
                                    <input
                                        :id="'hotel_' + hotel.hotelId"
                                        aria-describedby="helper-checkbox-text"
                                        type="checkbox"
                                        :value="hotel.hotelId"
                                        :name="'hotel_' + hotel.hotelId"
                                        :data-hotelLocationId-select="key"
                                        v-model="store.package.hotels.selectedHotels"
                                        class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                                    />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
