import { defineStore } from 'pinia';
import { useRouter } from 'vue-router';
import type { AuthState } from '@/types/auth.interfaces';

export const useAuthStore = defineStore('auth', {
    state: (): AuthState => ({
        jwt:
            localStorage.getItem('jwt') != null
                ? JSON.parse(String(localStorage.getItem('jwt')))
                : '',
    }),
    actions: {
        async login(jwt: string) {
            this.jwt = jwt;
            localStorage.setItem('jwt', JSON.stringify(jwt));

            const router = useRouter();
            await router.push('/');
        },
        logout() {
            this.jwt = '';
            localStorage.removeItem('jwt');

            const router = useRouter();
            router.push('/logout');
        },
    },
    getters: {
        isAuthenticated: (state: { jwt: string }) => state.jwt,
        getJwt: (state: { jwt: string }) => state.jwt,
    },
});
