import { defineStore } from 'pinia';
import type { UCMHotelInterface } from '@/types/ucm/hotel.interface';

export const useUCMHotelStore = defineStore('ucm-hotel', {
    state: (): UCMHotelInterface =>{
        return {
            hotel: {
                generalInfo: {
                    published: false,
                    name: "",
                    stars: 0,
                    id: 0,
                    internalId: 0,
                    externalId: 0,
                    phone: 0,
                    email: "",
                    website: "",
                    latitude: "",
                    longitude: "",
                },
                descriptions: [],
                parameters: {
                    distances: [],
                    attributes: [],
                },
            }
        }
    },
})