import axiosApiInstance from '@/helpers/api.axios.headers';
import type { HotelProviderInterface } from '@/types/builder/hotelProvider.interface';

export async function activeProvidersService() {
    try {
        const response = await axiosApiInstance.get('/getActiveProviders');
        if (!response.data.status) {
            alert(response.data.message);
            return false;
        }

        if (!response.data.status) {
            alert(response.data.message);
        } else {
            return response.data.providers as Array<HotelProviderInterface>;
        }
    } catch (error) {
        console.error(error);
    }
}
