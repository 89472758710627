<script setup lang="ts">
import { usePackageStore } from '@/stores/package';
import type { Rule } from '@/types/builder/package.interface';
const store = usePackageStore();

const cloneRule = (type: string, rule: Rule) => {
    if (type === 'hotel') {
        store.package.commissions.hotelCalculationLogic.forEach((storeRules) => {
            storeRules.rule.forEach((storeRule) => {
                Object.assign(storeRule, rule);
            });
        });
    } else if (type === 'transport') {
        store.package.commissions.transportationCalculationLogic.forEach((storeRules) => {

            if(storeRules.rule.length === 0){
                storeRules.rule.push(
                    {
                        markup: 0,
                                markupType: 'flat',
                                calculationLogic: 'perPax',
                                commission: 0,
                                commissionType: 'flat',
                    }
                )
            }
            storeRules.rule.forEach((storeRule: Rule) => {
                Object.assign(storeRule, rule);
            });
        });
    }
};
</script>
<template>
    <div>
        <div>
            <div class="text-xl underline underline-offset-2 mt-4">Hotel Calculation Logic</div>
            <div class="text-xs font-normal text-gray-500 dark:text-gray-300">
                <span class="text-red-600">*</span> Add atleast one Rule for this package
            </div>
            <div
                class="my-2 border p-4"
                v-for="(rules, index) in store.package.commissions.hotelCalculationLogic"
                :key="index"
            >
                <div class="mb-2 text-sm text-gray-900 dark:text-gray-300">
                    {{ index + 1 }}. Commission Rules for Departure:
                    <b>{{ rules.departureDate }}</b>
                </div>
                <div class="mt-4">
                    <div
                        v-for="(rule, indexRule) in rules.rule"
                        :key="indexRule"
                        class="flex justify-between"
                    >
                        <div class="flex mt-4">
                            <div class="mr-4">
                                <label class="block text-gray-700">Markup</label>
                                <input
                                    type="text"
                                    autocomplete="off"
                                    placeholder="Please enter a numeric value"
                                    class="mt-1 form-control"
                                    v-model.number="rule.markup"
                                />
                            </div>
                            <div class="mr-4">
                                <label class="block text-gray-700">Markup Type</label>
                                <select v-model="rule.markupType" class="form-select w-48">
                                    <option selected value="flat">Flat</option>
                                    <option value="percent">Percent</option>
                                </select>
                            </div>
                            <div class="mr-4">
                                <label class="block text-gray-700">Calculation Logic</label>
                                <select class="form-select w-40" v-model="rule.calculationLogic">
                                    <option selected value="perPax">per PAX</option>
                                    <option value="perService">per Service</option>
                                    <option value="perNight">per Night</option>
                                    <option value="perPaxPerNight">per PAX / Night</option>
                                </select>
                            </div>
                            <div class="mr-4">
                                <label class="block text-gray-700">Commission</label>
                                <input
                                    type="text"
                                    autocomplete="off"
                                    placeholder="Please enter a numeric value"
                                    class="mt-1 form-control"
                                    v-model.number="rule.commission"
                                />
                            </div>
                            <div class="mr-4">
                                <label class="block text-gray-700">Commission Type</label>
                                <select v-model="rule.commissionType" class="form-select w-40">
                                    <option selected value="flat">Flat</option>
                                    <option value="percent">Percent</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <div class="flex mt-11">
                                <button
                                    class="py-2 px-3 text-xs focus:outline-none text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    title="Reset Rule"
                                    @click="
                                        store.package.commissions.hotelCalculationLogic[index].rule[
                                            indexRule
                                        ] = {
                                            markup: 0,
                                            markupType: 'flat',
                                            calculationLogic: 'perPax',
                                            commission: 0,
                                            commissionType: 'flat',
                                        }
                                    "
                                >
                                    <svg
                                        class="w-5 h-5 fill-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                    >
                                        <path
                                            d="M0 224c0 17.7 14.3 32 32 32s32-14.3 32-32c0-53 43-96 96-96H320v32c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-9.2-9.2-22.9-11.9-34.9-6.9S320 19.1 320 32V64H160C71.6 64 0 135.6 0 224zm512 64c0-17.7-14.3-32-32-32s-32 14.3-32 32c0 53-43 96-96 96H192V352c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V448H352c88.4 0 160-71.6 160-160z"
                                        />
                                    </svg>
                                </button>
                                <button
                                    class="py-2 px-3 text-xs focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg mr-2 mb-2 dark:focus:ring-yellow-900"
                                    @click="
                                        cloneRule('hotel', {
                                            markup: rule.markup,
                                            markupType: rule.markupType,
                                            calculationLogic: rule.calculationLogic,
                                            commission: rule.commission,
                                            commissionType: rule.commissionType,
                                        })
                                    "
                                >
                                    Clone this Rule For All Departures
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="text-xl underline underline-offset-2 mt-4">
                Transportation Calculation Logic
            </div>
            <div class="text-xs font-normal text-gray-500 dark:text-gray-300">
                * Optional transport Rules
            </div>
            <div
                class="my-2 border p-4"
                v-for="(rules, index) in store.package.commissions.transportationCalculationLogic"
                :key="index"
            >
                <div class="mb-2 text-sm text-gray-900 dark:text-gray-300">
                    {{ index + 1 }}. Commission Rules for Departure:
                    <b>{{ rules.departureDate }}</b>
                </div>
                <div class="mt-4">
                    <button
                        v-if="rules.rule.length === 0"
                        class="btn-primary"
                        @click="
                            rules.rule.push({
                                markup: 0,
                                markupType: 'flat',
                                calculationLogic: 'perPax',
                                commission: 0,
                                commissionType: 'flat',
                            })
                        "
                    >
                        Add new Rule
                    </button>
                    <div
                        v-for="(rule, indexRule) in rules.rule"
                        :key="indexRule"
                        class="flex justify-between"
                    >
                        <div class="flex mt-4">
                            <div class="mr-4">
                                <label class="block text-gray-700">Markup</label>
                                <input
                                    type="text"
                                    autocomplete="off"
                                    placeholder="Please enter a numeric value"
                                    class="mt-1 form-control"
                                    v-model.number="rule.markup"
                                />
                            </div>
                            <div class="mr-4">
                                <label class="block text-gray-700">Markup Type</label>
                                <select v-model="rule.markupType" class="form-select w-48">
                                    <option selected value="flat">Flat</option>
                                    <option value="percent">Percent</option>
                                </select>
                            </div>
                            <div class="mr-4">
                                <label class="block text-gray-700">Calculation Logic</label>
                                <select class="form-select w-40" v-model="rule.calculationLogic">
                                    <option selected value="perPax">per PAX</option>
                                    <option value="perService">per Service</option>
                                    <option value="perNight">per Night</option>
                                    <option value="perPaxPerNight">per PAX / Night</option>
                                </select>
                            </div>
                            <div class="mr-4">
                                <label class="block text-gray-700">Commission</label>
                                <input
                                    type="text"
                                    autocomplete="off"
                                    placeholder="Please enter a numeric value"
                                    class="mt-1 form-control"
                                    v-model.number="rule.commission"
                                />
                            </div>
                            <div class="mr-4">
                                <label class="block text-gray-700">Commission Type</label>
                                <select v-model="rule.commissionType" class="form-select w-40">
                                    <option selected value="flat">Flat</option>
                                    <option value="percent">Percent</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <div class="flex mt-11">
                                <button
                                    class="py-2 px-3 text-xs focus:outline-none text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    title="Reset Rule"
                                    @click="
                                        store.package.commissions.transportationCalculationLogic[
                                            index
                                        ].rule[indexRule] = {
                                            markup: 0,
                                            markupType: 'flat',
                                            calculationLogic: 'perPax',
                                            commission: 0,
                                            commissionType: 'flat',
                                        }
                                    "
                                >
                                    <svg
                                        class="w-5 h-5 fill-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                    >
                                        <path
                                            d="M0 224c0 17.7 14.3 32 32 32s32-14.3 32-32c0-53 43-96 96-96H320v32c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-9.2-9.2-22.9-11.9-34.9-6.9S320 19.1 320 32V64H160C71.6 64 0 135.6 0 224zm512 64c0-17.7-14.3-32-32-32s-32 14.3-32 32c0 53-43 96-96 96H192V352c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V448H352c88.4 0 160-71.6 160-160z"
                                        />
                                    </svg>
                                </button>
                                <button
                                    class="py-2 px-3 text-xs focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg mr-2 mb-2 dark:focus:ring-yellow-900"
                                    @click="
                                        cloneRule('transport', {
                                            markup: rule.markup,
                                            markupType: rule.markupType,
                                            calculationLogic: rule.calculationLogic,
                                            commission: rule.commission,
                                            commissionType: rule.commissionType,
                                        })
                                    "
                                >
                                    Clone this Rule For All Departures
                                </button>
                                <button
                                    class="py-2 px-3 text-xs focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                                    @click="rules.rule = []"
                                >
                                    Delete this Rule
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
