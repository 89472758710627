<script setup lang="ts">
import { useUserStore } from '@/stores/user';
import { useAuthStore } from '@/stores/auth';

const authStore = useAuthStore();
const userStore = useUserStore();
authStore.logout();
userStore.clearUser();

document.querySelector('.sideBar')?.remove();
document.querySelector('.mainHeader')?.remove();

const mainContent: HTMLDivElement | null = document.querySelector('.mainContent');
if (mainContent != null) {
    mainContent.style.width = '100%';
}
</script>

<template>
    <div class="bg-green-500 text-white font-extrabold text-xl h-screen text-center pt-20">
        You have been logged out! <br />
        Please go to
        <a class="underline" href="http://travel.localhost.localdomain/">Traviola</a>
        and relog!
    </div>
</template>
