<script setup lang="ts">
import { usePackageStore } from '@/stores/package';
import type { DropdownItem } from '@/types/multiselectDropdown.interface';
import { onMounted, ref, type Ref } from 'vue';

const props = defineProps({
    buttonText: String,
    dropdownOptions: Array<DropdownItem>,
    dropdownId: String,
});

const store = usePackageStore();
const dropdownOptionsToRender: Ref = ref([]);

onMounted(() => {
    dropdownOptionsToRender.value = props.dropdownOptions;
    // set the dropdown menu element
    const targetEl = document.getElementById(`${props.dropdownId}List`);

    // set the element that trigger the dropdown menu on click
    const triggerEl = document.getElementById(`${props.dropdownId}Button`);

    // options with default values
    const options = {
        placement: 'bottom',
        // onHide: () => {
        //     console.log('dropdown has been hidden');
        // },
        // onShow: () => {
        //     console.log('dropdown has been shown');
        // }
    };

    // @ts-ignore: Unreachable code error
    new Dropdown(targetEl, triggerEl, options);
});

const updateList = (itemId: string | number, event: Event) => {
    if(props.dropdownId === 'traffics'){
        console.log(itemId, (event.target as HTMLInputElement).value, props.dropdownId);
        if( (event.target as HTMLInputElement).checked ){
            store.package.hotels.tourOperators.push(String(itemId));
        }else{
            let index = store.package.hotels.tourOperators.indexOf(String(itemId));
            if (index !== -1) {
                store.package.hotels.tourOperators.splice(index, 1);
            }
        }
    }
    
    
}
</script>
<template>
    <div>
        <button
            type="button"
            :id="dropdownId + 'Button'"
            data-dropdown-toggle="dropdownHelper"
            class="inline-flex items-center text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2  dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
        >
            {{ buttonText }}
            <svg
                class="ml-2 w-4 h-4"
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                ></path>
            </svg>
        </button>

        <!-- Dropdown menu -->
        <div
            :id="dropdownId + 'List'"
            class="hidden z-10 w-60 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600 overflow-y-auto h-96"
        >
            <ul
                class="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownHelperButton"
            >
                <li v-for="(item, index) in dropdownOptionsToRender" :key="index">
                    <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                        <div class="flex items-center h-5">
                            <input
                                :id="item.itemId"
                                aria-describedby="helper-checkbox-text-1"
                                type="checkbox"
                                v-model="item.checked"
                                @change="updateList(item.itemId, $event)"
                                value=""
                                class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            />
                        </div>
                        <div class="ml-2 text-sm">
                            <label
                                :for="item.itemId"
                                class="font-medium text-gray-900 dark:text-gray-300"
                            >
                                <div>{{ item.itemLabel }}</div>
                                <p
                                    id="helper-checkbox-text-1"
                                    class="text-xs font-normal text-gray-500 dark:text-gray-300"
                                >
                                    {{ item.itemDescription }}
                                </p>
                            </label>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
