<script setup lang="ts">
import Datepicker from '@vuepic/vue-datepicker';
import { toRaw } from 'vue';
import { usePackageStore } from '@/stores/package';

const store = usePackageStore();
const format = (date: Array<Date>) => {
    date = toRaw(date);

    const day = date[0].getDate();
    const month = date[0].getMonth() + 1;
    const year = date[0].getFullYear();

    if (date[1] != null) {
        const day1 = date[1].getDate();
        const month1 = date[1].getMonth() + 1;
        const year1 = date[1].getFullYear();

        return `${day}/${month}/${year} - ${day1}/${month1}/${year1}`;
    }
};

const handleDate = (modelData: Array<Date>) => {
    modelData = toRaw(modelData);

    store.package.transport.transportType = '';
    store.package.transport.transportName = '';

    if (modelData === null) {
        store.package.generalInfo.period = [];
        return false;
    }

    (store.package.generalInfo.period as any) = [
        modelData[0].toISOString().split('T')[0],
        modelData[1].toISOString().split('T')[0],
    ];
};
</script>
<template>
    <div>
        <div class="text-xl underline underline-offset-2 mb-4">Packages Name Building Rule</div>
        <div class="flex items-center my-2">
            <div class="mr-4 border-r-2 pr-4">
                <div class="flex items-start">
                    <div class="flex items-center h-5">
                        <input
                            id="hotelNameInPackageRuleName"
                            name="hotelNameInPackageRuleName"
                            type="checkbox"
                            v-model="store.package.generalInfo.hotelNameInPackageRuleName"
                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                        />
                    </div>
                    <div class="ml-3 text-sm">
                        <label for="hotelNameInPackageRuleName" class="cursor-pointer"
                            >Hotel Name</label
                        >
                        <p class="text-gray-500">Include Hotel Name in Package Name</p>
                    </div>
                </div>
            </div>
            <div class="mr-4 border-r-2 pr-4">
                <div class="flex items-start">
                    <div class="flex items-center h-5">
                        <input
                            id="nightsInPackageRuleName"
                            name="nightsInPackageRuleName"
                            type="checkbox"
                            v-model="store.package.generalInfo.nightsInPackageRuleName"
                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                        />
                    </div>
                    <div class="ml-3 text-sm">
                        <label for="nightsInPackageRuleName" class="cursor-pointer">Nights</label>
                        <p class="text-gray-500">Include Number of Nights in Package Name</p>
                    </div>
                </div>
            </div>
            <div class="mr-4 border-r-2 pr-4">
                <div class="flex items-start">
                    <div class="flex items-center h-5">
                        <input
                            id="transportTypeInPackageRuleName"
                            name="transportTypeInPackageRuleName"
                            type="checkbox"
                            v-model="store.package.generalInfo.transportTypeInPackageRuleName"
                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                        />
                    </div>
                    <div class="ml-3 text-sm">
                        <label for="transportTypeInPackageRuleName" class="cursor-pointer"
                            >Transport Type</label
                        >
                        <p class="text-gray-500">Include Transport Type in Package Name</p>
                    </div>
                </div>
            </div>
            <div>
                <div class="flex items-start">
                    <div class="flex items-center h-5">
                        <input
                            id="hotelPromotionInPackageRuleName"
                            name="hotelPromotionInPackageRuleName"
                            type="checkbox"
                            v-model="store.package.generalInfo.hotelPromotionInPackageRuleName"
                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                        />
                    </div>
                    <div class="ml-3 text-sm">
                        <label for="hotelPromotionInPackageRuleName" class="cursor-pointer"
                            >Hotel Promotion</label
                        >
                        <p class="text-gray-500">Include Hotel Promotion Package Rule Name</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="text-xl underline underline-offset-2 my-4">Packages Status After Creation</div>
        <div class="flex">
            <div class="mr-4 border-r-2 pr-4">
                <div class="flex items-start">
                    <div class="flex items-center h-5">
                        <input
                            id="packageStatusActive"
                            type="radio"
                            name="packageStatus"
                            v-model="store.package.generalInfo.packageStatus"
                            value="active"
                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                        />
                    </div>
                    <div class="ml-3 text-sm">
                        <label for="packageStatusActive" class="cursor-pointer">Active</label>
                        <p class="text-gray-500">Set Package Status Available</p>
                    </div>
                </div>
            </div>
            <div class="mr-4 border-r-2 pr-4">
                <div class="flex items-start">
                    <div class="flex items-center h-5">
                        <input
                            checked
                            id="packageStatusDisabled"
                            type="radio"
                            name="packageStatus"
                            v-model="store.package.generalInfo.packageStatus"
                            value="disabled"
                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                        />
                    </div>
                    <div class="ml-3 text-sm">
                        <label class="cursor-pointer">Disabled</label>
                        <p class="text-gray-500">Set Package Status Disabled</p>
                    </div>
                </div>
            </div>
            <div>
                <div class="flex items-start">
                    <div class="flex items-center h-5">
                        <input
                            type="checkbox"
                            id="stopBuy"
                            name="stopBuy"
                            v-model="store.package.generalInfo.stopBuy"
                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                        />
                    </div>
                    <div class="ml-3 text-sm">
                        <label for="stopBuy" class="cursor-pointer">Testing Mode</label>
                        <p class="text-gray-500">
                            Set Package Status to Stop Buy, <br />Packages will be visible in search
                            results but not bookable
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="text-xl underline underline-offset-2 my-4">Packages Departues Interval</div>
            <div class="flex">
                <div>
                    <Datepicker
                        class="mt-1"
                        range
                        v-model="store.package.generalInfo.period"
                        multi-calendars
                        :enableTimePicker="false"
                        id="datesInterval"
                        :format="format"
                        :previewFormat="format"
                        @update:modelValue="handleDate"
                    />
                    <div class="ml-3 text-sm">
                        <p class="text-gray-500">Set Package Interval Range</p>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="text-xl underline underline-offset-2 my-4">
                Packages Rotations and Offsets settings
            </div>
            <div class="flex justify-between">
                <div class="flex">
                    <div class="col-span-6 sm:col-span-3 mr-2">
                        <label for="minOffset" class="block text-gray-700">Outbound Offset</label>
                        <input
                            type="number"
                            min="0"
                            max="2"
                            name="minOffset"
                            id="minOffset"
                            autocomplete="off"
                            v-model.number="store.package.generalInfo.minOffset"
                            class="form-control mt-1"
                        />
                        <div class="ml-3 text-sm">
                            <p class="text-gray-500">
                                Number of days, the hotel <br />
                                check-in date will be delayed
                            </p>
                        </div>
                    </div>
                    <div class="col-span-6 sm:col-span-3 mr-2">
                        <label for="maxOffset" class="block text-gray-700">Inbound Offset</label>
                        <input
                            type="number"
                            min="0"
                            max="2"
                            name="maxOffset"
                            id="maxOffset"
                            autocomplete="off"
                            v-model.number="store.package.generalInfo.maxOffset"
                            class="form-control mt-1"
                        />
                        <div class="ml-3 text-sm">
                            <p class="text-gray-500">
                                Number of days, the hotel <br />
                                check-out date will be delayed
                            </p>
                        </div>
                    </div>
                    <div class="col-span-6 sm:col-span-3 mr-2">
                        <label for="minRotation" class="block text-gray-700">
                            Minimum Packages Duration</label
                        >
                        <input
                            type="number"
                            min="0"
                            name="minRotation"
                            id="minRotation"
                            autocomplete="off"
                            v-model.number="store.package.generalInfo.minRotation"
                            class="form-control mt-1"
                        />
                        <div class="ml-3 text-sm">
                            <p class="text-gray-500">
                                Minimum rotation in number <br />
                                of days
                            </p>
                        </div>
                    </div>
                    <div class="col-span-6 sm:col-span-3 mr-2">
                        <label for="maxRotation" class="block text-gray-700">
                            Maximum Packages Duration</label
                        >
                        <input
                            type="number"
                            min="0"
                            name="maxRotation"
                            id="maxRotation"
                            autocomplete="off"
                            v-model.number="store.package.generalInfo.maxRotation"
                            class="form-control mt-1"
                        />
                        <div class="ml-3 text-sm">
                            <p class="text-gray-500">
                                Maximum rotation in number <br />
                                of days
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
